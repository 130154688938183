// Customizable Area Start
import React from "react";
import { createStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Button,
  withStyles,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { ChoiceLetters } from "./enums";
import { Choice, NormalizedFile } from "./types";
import {
  ArrowBackRounded,
  ArrowForwardRounded,
  CalendarTodayRounded,
  ChatBubbleOutlineRounded,
  DoneRounded,
  InsertLinkOutlined,
  KeyboardArrowDown,
  KeyboardArrowRight,
  MailOutlineRounded,
  OndemandVideoRounded,
} from "@material-ui/icons";

type Props = {
  classes: any;
  choices: Choice[];
  files: NormalizedFile[];
  showChoiceMenu: (choiceIndex: number) => void;
  changeChoiceName: (choiceName: string, choiceIndex: number) => void;
  setChoiceType: (choiceType: string, choiceIndex: number) => void;
  closeChoiceMenu: () => void;
};

const styles = (theme: Theme) =>
  createStyles({
    choicesList: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "28px",
    },

    choiceItem: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      gap: "24px",
      width: "100%",
      [theme.breakpoints.down(780)]: {
        flexDirection: "column",
        alignItems: "start"
      },
    },

    choiseIndi:{
      display: "flex",
      alignItems: "center",
      gap: "24px"
    },

    choiceLetter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "37px",
      height: "37px",
      borderRadius: "50%",
      backgroundColor: "#FFC629",
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: "32px",
    },

    choiceNameInput: {
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "32px",
      flexGrow: 1,
    },

    choiceArrowContainer: {
      flexGrow: 1,
    },

    choiceMenuContainer: {
      position: "relative",
    },

    choiceMenuList: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: 0,
      left: 0,
      padding: "10px 10px",
      width: "192px",
      zIndex: 1,
      borderRadius: "8px",
      border: "1px solid #E2E8F0",
      backgroundColor: "#FFF",
      boxShadow:
        "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
      color: "#0F172A",
      fontSize: "14px",
      lineHeight: "22px",
      [theme.breakpoints.down(650)]: {
        left: "110px"
      },
    },

    menuHeader: {
      display: "flex",
      flexWrap: "nowrap",
      fontSize: "14px",
      gap: "8px",
      borderBottom: "1px solid #E2E8F0",
    },

    menuHeaderText: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      grow: 1,
      fontSize: "14px",
      fontWeight: 700,
    },

    menuItemsContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "3px",
      paddingTop: "3px",
    },

    menuItemHorizontal: {
      display: "flex",
      flexWrap: "nowrap",
      height: "36px",
      padding: "7px 5px 7px 16px",
      alignSelf: "stretch",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "8px",
      borderRadius: "8px",
      cursor: "pointer",

      "& > svg": {
        width: "18px",
      },

      "& > p": {
        fontSize: "14px",
      },

      "&:hover": {
        backgroundColor: "#FFC629",
      },
    },

    menuItemVertical: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
    },

    menuItemText: {
      display: "flex",
      flexWrap: "nowrap",
      flexGrow: 1,
      alignItems: "center",
      justifyContent: "flex-start",
    },

    menuTextInput: {
      height: "36px",

      "& > *": {
        height: "36px",
      },
    },

    videosList: {
      display: "flex",
      flexDirection: "column",
      padding: "3px",
      gap: "5px",
    },

    radioButton: {
      "& svg": {
        color: "#000",
      },
    },

    addButton: {
      display: "flex",
      width: "111px",
      height: "40px",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "17px",
      color: "#FFF",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      textTransform: "capitalize",

      "& > *:first-child": {
        display: "flex",
        gap: "6px",
      },
    },
  });

export const CreateEveryreelChoicesList = ({
  classes,
  choices,
  files,
  changeChoiceName,
  showChoiceMenu,
  setChoiceType,
  closeChoiceMenu,
}: Props) => {
  return (
    <Box id="choicesList" className={classes.choicesList}>
      {choices.map((choice, i) => (
        <Box key={String(i)} className={classes.choiceItem}>
          <Box className={classes.choiseIndi}>
            <Typography
              style={{
                color: "#6F6F70",
                fontSize: 20,
                lineHeight: "30px",
                fontWeight: 700,
              }}
            >
              If
            </Typography>
            <Box className={classes.choiceLetter}>{ChoiceLetters[i]}</Box>
          </Box>
          <TextField
            id={`choice-${i}`}
            className={classes.choiceNameInput}
            value={choice.name}
            onChange={(event) => changeChoiceName(event.target.value, i)}
            inputProps={{ maxLength: 60 }}
          />
          <Box className={classes.choiseIndi}>
            <Box className={classes.choiceArrowContainer}>
              <ArrowForwardRounded />
            </Box>
            <Box
              className={classes.choiceMenuContainer}
              onClick={(e) => e.stopPropagation()}
            >
              <Button
                id={`showChoiceMenuButton-${i}`}
                className={classes.addButton}
                onClick={() => showChoiceMenu(i)}
                style={{ backgroundColor: "#000" }}
              >
                {choice.buttonType || "Select"}
                <KeyboardArrowDown />
              </Button>
              {choice.showMenu && (
                <Box className={classes.choiceMenuList}>
                  <Box
                    id={`choiceMenuHeader-${i}`}
                    className={classes.menuHeader}
                    onClick={() => setChoiceType("", i)}
                  >
                    <ArrowBackRounded />
                    <Typography className={classes.menuHeaderText}>
                      {(() => {
                        if (choice.buttonType) {
                          if (choice.buttonType === "video") {
                            return "Video Parts";
                          } else {
                            return "Where to?";
                          }
                        } else {
                          return "Link to";
                        }
                      })()}
                    </Typography>
                  </Box>
                  {choice.buttonType !== "video" && (
                    <Box className={classes.menuItemsContainer}>
                      <Box
                        id={`choiceVideoSelect-${i}`}
                        className={classes.menuItemHorizontal}
                        onClick={() => setChoiceType("video", i)}
                      >
                        <OndemandVideoRounded />
                        <Typography className={classes.menuItemText}>
                          Video Parts
                        </Typography>
                        <KeyboardArrowRight />
                      </Box>
                      <Box
                        id={`choiceContactSelect-${i}`}
                        className={classes.menuItemHorizontal}
                        onClick={() => setChoiceType("contact", i)}
                      >
                        <MailOutlineRounded />
                        <Typography className={classes.menuItemText}>
                          Contact form
                        </Typography>
                      </Box>
                      <Box
                        id={`choiceLinkSelect-${i}`}
                        className={classes.menuItemVertical}
                        onClick={() => setChoiceType("link", i)}
                      >
                        <Box className={classes.menuItemHorizontal}>
                          <InsertLinkOutlined />
                          <Typography className={classes.menuItemText}>
                            Link
                          </Typography>
                          <KeyboardArrowRight />
                        </Box>
                        {choice.buttonType === "link" && (
                          <TextField
                            id={`choiceLinkInput-${i}`}
                            className={classes.menuTextInput}
                            placeholder="Paste URL"
                            variant="outlined"
                          />
                        )}
                      </Box>
                      <Box
                        id={`choiceChatSelect-${i}`}
                        className={classes.menuItemHorizontal}
                        onClick={() => setChoiceType("chat", i)}
                      >
                        <ChatBubbleOutlineRounded />
                        <Typography className={classes.menuItemText}>
                          Chat
                        </Typography>
                      </Box>
                      <Box
                        id={`choiceCalendarSelect-${i}`}
                        className={classes.menuItemVertical}
                        onClick={() => setChoiceType("calendar", i)}
                      >
                        <Box className={classes.menuItemHorizontal}>
                          <CalendarTodayRounded />
                          <Typography className={classes.menuItemText}>
                            Calendar
                          </Typography>
                          <KeyboardArrowRight />
                        </Box>
                        {choice.buttonType === "calendar" && (
                          <TextField
                            id={`choiceCalendarInput-${i}`}
                            className={classes.menuTextInput}
                            placeholder="Paste Link"
                            variant="outlined"
                          />
                        )}
                      </Box>
                      <Box
                        id={`choiceEndSelect-${i}`}
                        className={classes.menuItemHorizontal}
                        onClick={() => setChoiceType("end", i)}
                      >
                        <DoneRounded />
                        <Typography className={classes.menuItemText}>
                          End
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {choice.buttonType === "video" && (
                    <RadioGroup
                      id={`videoSelectionList-${i}`}
                      className={classes.videosList}
                      defaultValue={files[0].meta.id}
                      name="radio-buttons-group"
                    >
                      {files.map((file, j) => (
                        <FormControlLabel
                          id={`videoSelectionItem-${j}`}
                          key={String(j)}
                          value={file.meta.id}
                          control={<Radio className={classes.radioButton} />}
                          label={file.title}
                          onClick={closeChoiceMenu}
                        />
                      ))}
                    </RadioGroup>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default withStyles(styles)(CreateEveryreelChoicesList);
// Customizable Area End
