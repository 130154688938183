// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getToken } from "../../../components/src/AuthService";
import { getMediafileType } from "../../utilities/src/GetMediafileType";
import { File } from "./types";
export const configJSON = require("./config");

interface ITikTokMedia {
  id: string
  title: string
  video_description: string
  duration: number
  cover_image_url: string
  share_url: string
  embed_link: string
}

export interface Props {
  navigation: any;
  handleGoBack: () => void;
  setFiles: (files: File[]) => void;
}

interface S {
  isLoading: boolean;
  apiError: string;
  tikTokMedia: ITikTokMedia[];
  selectedMediaIds: string[];
}

interface SS {
  navigation: any;
}

export default class TikTokLibraryController extends BlockComponent<
  Props,
  S,
  SS
> {
  getTikTokMediaApiCallId: string = "";
  tiktokAuthUrlApiId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      isLoading: false,
      apiError: "",
      tikTokMedia: [],
      selectedMediaIds: [],
    };
  }

  async componentDidMount() {
    const channel = new BroadcastChannel('authChannel');
    channel.addEventListener('message', (event) => {
      if (event.data.type === 'authComplete') {
        this.getTikTokMedia();
      }
    });
    this.getTikTokMedia();
  }

  initiateTikTokLogin =  async() => {
    const headerData = {
      "Content-Type": configJSON.validationApiContentType,
      token: getToken(),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.tiktokAuthUrlApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.socialAuthUrlEndPoint + "tiktok"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  toggleSelectionMedia = (selectedMediaId: string) => {
    let currentSelectedMediaIds = [...this.state.selectedMediaIds];
    const isAlreadySelected = currentSelectedMediaIds.includes(selectedMediaId);
    if (isAlreadySelected) {
      currentSelectedMediaIds = [...currentSelectedMediaIds].filter(
        (mediaId) => mediaId !== selectedMediaId
      );
    } else {
      currentSelectedMediaIds.push(selectedMediaId);
    }
    this.setState({ selectedMediaIds: currentSelectedMediaIds });
  };

  handleAcceptFiles = () => {
    const { tikTokMedia, selectedMediaIds } = this.state;
    const selectedMediaList = [...tikTokMedia].filter((media) =>
      selectedMediaIds.includes(media.id)
    );
    const uploadFiles = selectedMediaList.map((file) => {
      const { cover_image_url, id } = file;

      return {
        filePreview: cover_image_url,
        preview: cover_image_url,
        name: `TikTok_${id}`,
        type: getMediafileType(cover_image_url) || "",
      };
    });
    this.props.setFiles(uploadFiles);
  };

  getTikTokMedia = () => {
    const headerData = {
      "Content-Type": configJSON.validationApiContentType,
      token: getToken(),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTikTokMediaApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tikTokMediaApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getTikTokMediaApiCallId) {
      this.handleGetMediaAPIResponse(responseJson);
    }
    if(apiRequestCallId === this.tiktokAuthUrlApiId){
      if(responseJson.uri){
        const updatedUrl = responseJson.uri.replace(/redirect_uri=[^&]*/, `redirect_uri=${window.location.origin}/tiktok_auth`);
        window.open(updatedUrl, "_blank");
      }
    }
  }

  handleGetMediaAPIResponse = (responseJson: any) => {
    if((responseJson.error && !responseJson?.data) || responseJson?.error?.message.length >= 0){
      setTimeout(() => {
        this.initiateTikTokLogin();
      }, 3000);
      this.setState({
        apiError: configJSON.tikTokAccountNotConnectedMessage,
        isLoading: false,
      });
    } else if (responseJson.errors) {
      let errorMessage = responseJson.errors;
      if (errorMessage === "User doesn't have a token") {
        errorMessage = configJSON.tikTokAccountNotConnectedMessage;
        setTimeout(() => {
          this.initiateTikTokLogin();
        }, 3000);
      }
      this.setState({
        apiError: errorMessage || "Something went wrong",
        isLoading: false,
      });
    } else {
      this.setState({
        apiError: "",
        tikTokMedia: responseJson.data.videos,
        isLoading: false,
      });
    }
  };
}
// Customizable Area End
