// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { NewPostCreationSteps } from "./enums";

export const configJSON = require("./config");

export type Props = {
  classes: any;
  allowComments: () => void;
  isCommentsAllowed: boolean;
  closeModal: () => void;
  handleSelectPlatforms: (name: string) => void;
};

interface S {
  selectedPlatform: string[];
}

interface SS {
  navigation: any;
  stepType: NewPostCreationSteps;
}

export default class NewPostSettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state={
      selectedPlatform: []
    }
  }

  handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const clickedPlatform = event.target.value as string;

    const newValues = typeof clickedPlatform === "string" ? clickedPlatform.split(",") : clickedPlatform;
    this.props.handleSelectPlatforms(newValues.join(", "));
    this.setState({
      selectedPlatform: newValues,
    });
  };

}
// Customizable Area End
