import React, { useState } from 'react'
import { AppBar, Box, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { backArrow } from './assets';

interface Props {
    backButton: (name?: string) => void;
    dropdownButton?: () => void;
    icon?: any;
    name: string;
    children?: React.ReactNode;
}

function NavigationMenuMobile({backButton, dropdownButton, icon, name}: Props){
  const classes = useStyles();
  const [flag, setFlag] = useState(false);

    return (
        <AppBar className={classes.appbar}>
          <Box className={classes.appbarBox} data-test-id="backbutton">
            <img src={backArrow} alt="" className={classes.icon} onClick={() => {backButton(name)}}/>
            <img src={icon} alt="" className={classes.icon}/>
            <Typography className={classes.name}>{name}</Typography>
            {
              window.location.href.includes("/messages") 
              && dropdownButton 
              && <img src={backArrow} alt="" 
              className={classes.dropdownIcon} 
              style={flag ? {transform: "rotate(-90deg)"}: {}} 
              onClick={() => {dropdownButton(); setFlag(!flag)}}/>
            }
          </Box>
        </AppBar>
    );
}

export default NavigationMenuMobile;


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      appbar: {
        backgroundColor: theme.palette.background.paper,
        zIndex: theme.zIndex.drawer + 1,
        overflowX: "auto",
      },
      
      appbarBox: {
        display: "flex",
        gap: '12px',
        background: "#FFC629",
        padding: "12px",
        alignItems: "center",
        minHeight: "56px"
      },
      icon: {
        width: "24px",
        height: "24px"
      },
      name: {
        fontSize: "16px",
        fontWeight: "bold",
        color: "#0F172A"
      },
      dropdownIcon:{
        width: "24px",
        height: "24px",
        alignSelf: "flex-end",
        marginLeft: "auto",
        transform: "rotate(90deg)"
      }
    })
);