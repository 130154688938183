// Customizable Area Start
import React from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import {NotificationIcon, HomeIcon,imageVector,addIcon,UserIcon} from "./assets"
import MobileNavEndUserController from "./MobileNavEndUserController.web";
const StyledBottomNavigation = styled(BottomNavigation)({
  backgroundColor: "#fff",
  position: "fixed",
  bottom: 0,
  width: "100vw",
  height: "auto",
  gap: "5px",
  justifyContent: "space-evenly",
  boxShadow: "0px -3px 5px -1px rgba(0, 0, 0, 0.1)",
  zIndex: 5,
  "@media (min-width: 960px)": {
    display: "none",
  },
  "@media (min-width: 320px)": {
    position: 'fixed',
  },
});

interface StyledBottomNavigationActionProps {
  active: boolean;
}

const StyledBottomNavigationAction = styled(BottomNavigationAction)(
  ({ active }: StyledBottomNavigationActionProps) => ({
    padding: "0px !important",
    minWidth: "auto",
    "& .MuiSvgIcon-root": {
      fontSize: "22px",
      color: "#000",
    },
    "&.Mui-selected": {
      color: "#000",
    },
    "& .MuiBottomNavigationAction-label": {
      fontSize: "10px",
      fontWeight: 400,
      lineHeight: "20px",
      fontFamily: "Inter, 'sans-serif'",
    },
    "& .MuiBottomNavigationAction-wrapper": {
      height: "58px",
      width: "58px",
      borderRadius: "50%",
      backgroundColor: active ? "#FFC629" : "transparent",
    },
  })
);

const StyledMenuIcon = styled("img")({
  width: "18px",
  height: "18px"
});

const MENUS = [
  {
    value: 0,
    label: "Home",
    icon: <StyledMenuIcon src={HomeIcon} alt="" />,
  },
  {
    value: 1,
    label: "Trending",
    icon: <StyledMenuIcon src={imageVector} alt="" />,
  },
  {
    value: 2,
    label: "Create",
    icon: <StyledMenuIcon src={addIcon} alt="" />,
  },
  {
    value: 3,
    label: "Notifications",
    icon: <StyledMenuIcon src={NotificationIcon} alt="" />,
  },
  {
    value: 4,
    label: "Profile",
    icon: <StyledMenuIcon src={UserIcon} alt="" />,
  },
];

class MobileNavEndUser extends MobileNavEndUserController {
  render() {
    const { currentTab } = this.state;

    return (
      <StyledBottomNavigation
        showLabels
        data-test-id="nav-menu-wrapper"
        value={currentTab}
        onChange={this.handleChange}
      >
        {MENUS.map((menu) => {
          const { icon, label, value } = menu;
          return (
            <StyledBottomNavigationAction
              showLabel
              key = {value}
              label={label}
              icon={icon}
              data-test-id={`nav-menu-${label}`}
              active={currentTab === value}
            />
          );
        })}
      </StyledBottomNavigation>
    );
  }
}

export default MobileNavEndUser;
// Customizable Area End
