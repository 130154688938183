// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";

import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  isPopoverVisible: any;
  classes?: any;
  closePopover: any;
  folders: any;
  selectedMessage: any;
  callBackAfterAPI:any;
  handleReply: any;
  handleApiResponse:any
  handleClosePopover: any
}

interface S {
  assignPopover: any;
  selectedFolder: any;
  selectedMessageId:any
}
interface SS {
  id: any;
}
export default class MessageOpetionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  markAsReadApiCallID: any = "";
  pinConversationApiCallID: any = "";
  assignConversionApiCallID: any = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      assignPopover: null,
      selectedFolder: 0,
      selectedMessageId:this.props.selectedMessage
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    const messageType = getName(MessageEnum.RestAPIResponceMessage);

    if (messageType === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (
        apiRequestCallId === this.markAsReadApiCallID ||
        apiRequestCallId === this.pinConversationApiCallID ||
        apiRequestCallId === this.assignConversionApiCallID
      ) {
        this.props.callBackAfterAPI()
        this.props.handleApiResponse(responseJson);
      }
    }
  }



  assignPopoverHandle = (e: any) => {
    e.stopPropagation();
    this.setState({ assignPopover: e.currentTarget });
  };

  handleFolderSelect = (e: any) => {
    e.stopPropagation();
    this.setState({ selectedFolder: e.target.value });
    this.assignConversation(e.target.value)
  };

  closeAssignPopover = (e: any) => {
    e.stopPropagation();
    this.setState({ assignPopover: null });
  };

  pinConversation = (e: any) => {
    e.stopPropagation();
    const payload = "/" + this.props.selectedMessage.id + "/pin_conversation";
    const headerData = {
      "Content-Type": configJSON.commentsContentType,
      token: localStorage.getItem("authToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.pinConversationApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createConversationEndPoint + payload
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  marksAsRead = (e: any) => {
    e.stopPropagation();
    const payload = "/" + this.props.selectedMessage.id + "/mark_as_read";
    const headerData = {
      "Content-Type": configJSON.commentsContentType,
      token: localStorage.getItem("authToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markAsReadApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createConversationEndPoint + payload
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  assignConversation = (folder:any) => {
    let id = this.props.folders.find((value:any) => value.attributes?.name === folder)?.attributes.id
    const payload = {
      chat_folder_id:id,
      contact_id:this.state.selectedMessageId.id
    }
    const headerData = {
      "Content-Type": configJSON.commentsContentType,
      token: localStorage.getItem("authToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.assignConversionApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.assignConversationEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
}
// Customizable Area End
