// Customizable Area Start
import React, {useRef, useEffect, useState} from "react";
import Container from "@material-ui/core/Container";
import { styled } from "@material-ui/core/styles";
import { Box, Grid, Theme, createStyles, makeStyles } from "@material-ui/core";
import CreateNewPost from "./CreateNewPost.web";
import Post from "./Post.web";
import { DashboardAccount, PostType } from "./interfaces";
import CustomLoader from "../../../components/src/CustomLoader.web";

const PostWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%"
});

interface Props {
  pageNo: number
  postLoader: boolean
  totalPages: number
  posts: PostType[];
  showAlert: boolean;
  showSuccess: string;
  userAccount?: DashboardAccount
  isSortingComments: boolean
  banUserApiCall: (values: any) => void;
  hidePostApicall: (values: any) => void;
  errorAlertMessage: boolean;
  handlePageNoChange: (pageNo: number) => void
  postCommentApiCall: (values: any, postId?: string) => void;
  deleteCommentApiCall: (id: number, postId: string) => void;
  archiveCommentApiCall: (values: any) => void;
  revertArchiveCommentApiCall: (id: number) => void;
  getPostCommentsApiCall: (postId: string) => void;
  handleSortingCommentsChange: (isSorting: boolean) => void
  handleLikePostAPICall: (data: any, likeType: string) => void;
  handleUndoLikeAPICall: (likeableId: string, likeType: string, postId?: string) => void;
  achiveId: 0;
  scrollToPost: number;
  handlePostProfileClick: (account_id: number) => void
  handleCreatePostRedirection: () => void
  handleVote: (optionId: number, postId: string) => void
  handleDeleteVote: (optionId: number, postId: string) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      gap: theme.spacing(3),
      [theme.breakpoints.up("md")]: {
        margin: theme.spacing(3, 0),
      },
    },
    allPosts: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "auto",
      "@media (min-width: 650px)": {
        alignItems: "normal",
        gap: "24px",
      },
      "@media (max-width: 650px)": {
        maxWidth: Math.min(window.innerWidth, 480),
      }
    },
    contantFeedContainer: {
      padding: "0 24px 0 0",
      [theme.breakpoints.down(1150)]: {
        padding: "0 24px"
      },
      [theme.breakpoints.down(650)]: {
        padding: "0px"
      }
    },
    createPostContainer: {
      [theme.breakpoints.down(650)]:{
        paddingLeft: "12px",
        paddingRight: "12px"
      }
    }
  })
);

function ContentFeed({
  posts,
  pageNo,
  achiveId,
  showAlert,
  postLoader,
  totalPages,
  userAccount,
  showSuccess,
  isSortingComments,
  errorAlertMessage,
  hidePostApicall,
  banUserApiCall,
  handlePageNoChange,
  postCommentApiCall,
  deleteCommentApiCall,
  getPostCommentsApiCall,
  handleLikePostAPICall,
  handleUndoLikeAPICall,
  archiveCommentApiCall,
  handleCreatePostRedirection,
  handleSortingCommentsChange,
  revertArchiveCommentApiCall,
  scrollToPost,
  handlePostProfileClick,
  handleVote,
  handleDeleteVote
}: Props) {
  const classes = useStyles();  
  const thresholdRef = useRef<HTMLDivElement | null>(null)
  const listContainerRef:any = useRef(null);
  const [highlightedPostId, setHighlightedPostId] = useState(null);
  const isFirstPageLoading = pageNo === 1 && postLoader

 const scrollToRow = (id: any) => {
  const index = posts.findIndex((item: any) => parseInt(item.id) === id);
  if (index !== -1 && listContainerRef.current && listContainerRef.current.children[index]) {
    const rowElement = listContainerRef.current.children[index];
    rowElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    setHighlightedPostId(id);
  }
};

  useEffect(() => {
    if(posts.length && scrollToPost > 0){
      scrollToRow(scrollToPost)
    }
  }, [posts]);

  useEffect(() => {
    const hasMore = pageNo < totalPages
    const observer = new IntersectionObserver(
        (entries) => {
            if (entries[0].isIntersecting && !postLoader && hasMore) {
              handlePageNoChange(pageNo + 1)
            }
        },
        {
          threshold: 0.1,
          rootMargin: '300px'
        }
    );

    if (thresholdRef.current) {
        observer.observe(thresholdRef.current);
    }

    return () => {
        if (thresholdRef.current) {
          observer.unobserve(thresholdRef.current);
        }
    };
}, [pageNo, totalPages, postLoader])

  useEffect(() => {
    const intervalId = setInterval(()=> {
      handlePageNoChange(1)
    },300000);
  
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Container className={classes.contantFeedContainer}>
      <Grid container direction="column" className={classes.root}>
        <Grid item className={classes.createPostContainer}>
          <CreateNewPost userProfile={userAccount} handleCreatePostRedirection={handleCreatePostRedirection} />
        </Grid>

        <CustomLoader wrapperHeight="20vh" isLoading={isFirstPageLoading}>
          <PostWrapper ref={listContainerRef} className={classes.allPosts}>
            {posts.length > 0 &&
              posts.map((post) => (
                <Box key={post.id} style={{width: "100%"}}>
                  <Post
                    postInfo={post}
                    achiveId={achiveId}
                    showAlert={showAlert}
                    showSuccess={showSuccess}
                    userAccount={userAccount}
                    errorAlertMessage={errorAlertMessage}
                    isSortingComments={isSortingComments}
                    highlighted={highlightedPostId == post.id}
                    banUserApiCall={banUserApiCall}
                    hidePostApicall={hidePostApicall}
                    postCommentApiCall={postCommentApiCall}
                    deleteCommentApiCall={deleteCommentApiCall}
                    archiveCommentApiCall={archiveCommentApiCall}
                    handleLikePostAPICall={handleLikePostAPICall}
                    handleUndoLikeAPICall={handleUndoLikeAPICall}
                    getPostCommentsApiCall={getPostCommentsApiCall}
                    revertArchiveCommentApiCall={revertArchiveCommentApiCall}
                    handlePostProfileClick={handlePostProfileClick}
                    handleSortingCommentsChange={handleSortingCommentsChange}
                    handleVote={handleVote}
                    handleDeleteVote={handleDeleteVote}
                  />
                </Box>
              ))}
            {pageNo < totalPages && (
              <div ref={thresholdRef}>
                <CustomLoader loaderSize={24} isLoading={postLoader} />
              </div>
            )}
          </PostWrapper>
        </CustomLoader>
      </Grid>
    </Container>
  );
}

export default ContentFeed;
// Customizable Area End
