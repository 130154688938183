// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getToken } from "../../../components/src/AuthService";
export const configJSON = require("./config");

export interface Props {
  id: string;
  navigation: any;
}

interface S {
  isLoading: boolean;
  alertType: "error" | "success" | undefined;
  alertMessage: string;
}

interface SS {
  navigation: any;
}

export default class InstagramAuthRedirectController extends BlockComponent<
  Props,
  S,
  SS
> {
  instagramCodeSendApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      isLoading: false,
      alertMessage: "",
      alertType: undefined,
    };
  }

  async componentDidMount() {
    this.handleInstagramAuthRedirection();
  }

  handleErrorAlert = (error: string) => {
    this.setState({ alertMessage: error, alertType: "error" });
    setTimeout(() => {
    }, 3000);
  };

  handleInstagramAuthRedirection = () => {
    this.setState({ isLoading: true });
    const params = new URLSearchParams(window.location.search);
    const instagramAuthCode = params.get(configJSON.INSTAGRAM_AUTH_CODE_PARAM);
    const instagramAuthError = params.get(
      configJSON.INSTAGRAM_AUTH_ERROR_PARAM
    );
    if (instagramAuthCode) {
      this.sendInstagramAuthCodeApi(instagramAuthCode);
    } else if (instagramAuthError) {
      this.handleErrorAlert(instagramAuthError);
    }
  };

  sendInstagramAuthCodeApi = (instagramAuthCode: string) => {
    const headerData = {
      "Content-Type": configJSON.validationApiContentType,
      token: getToken(),
    };
    const redirectUri = encodeURIComponent(`${window.location.origin}/${configJSON.instagramAuthRoute}`);
    const endPoint = `${configJSON.instagramCodeApiEndPoint}?code=${instagramAuthCode}&url=${redirectUri}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.instagramCodeSendApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId === this.instagramCodeSendApiCallId) {
      this.handleInstagramCodeSendApiResponse(message);
    }
  }

  handleInstagramCodeSendApiResponse = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const channel = new BroadcastChannel('authChannel');
    if (!responseJson.error) {
      this.setState({
        alertMessage: configJSON.instagramAccountConnectedMessage,
        alertType: "success",
      });
      channel.postMessage({
        type: 'authComplete',
        message: "Connected",
        token: "Connected",
      });
      setTimeout(() => {
        window.close();
      }, 2000);
    } else {
      channel.postMessage({
        type: 'authComplete',
        message: responseJson.error,
        token: "Not Connected",
      });
      setTimeout(() => {
        window.close();
      }, 2000);
      this.handleErrorAlert("Something went wrong");
    }
  };
}

// Customizable Area End
