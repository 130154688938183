import React from "react";
import {
  OutlinedInput,
  MenuItem,
  FormControl,
  Select,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { BlackDropdownIcon, WhiteDropdownIcon } from "../assets";

const useStyles = makeStyles({
  formControl: {
    width: 300,
  },
  select: {
    height: 48,
    width: 300,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #aaa",
    },
    "&:not(.Mui-focused)": {
      boxShadow: "inset 0 1px 8px rgba(0, 0, 0, 0.15)",
    },
    "& .MuiSelect-select": {
      color: "black",
      fontSize: "16px",
      fontWeight: 500,
      fontFamily: "Inter, sans-serif",
    },
  },
  menuPaper: {
    borderRadius: "5px"
  },
  menuItem: {
    "&.Mui-selected": {
      backgroundColor: "black !important",
      color: "white !important",
    },
    "&.Mui-selected:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.8) !important",
    },
  },
  menuList: {
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
  },
});

const platforms = ["youtube", "linkedin", "tiktok", "x", "instagram", "facebook"];

interface Props {
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  selectedPlatforms: string[];
}


const MultiSelectPlatform: React.FC<Props> = ({ onChange, selectedPlatforms }) => {
  const classes = useStyles();
  
  const CustomIcon: React.FC = () => (
    <img src={BlackDropdownIcon} alt="Dropdown Icon" style={{ width: 20, height: 20, marginRight: "12px" }} />
  );


  return (
    <FormControl className={classes.formControl}>
      <Select
        multiple
        value={selectedPlatforms}
        onChange={onChange}
        input={<OutlinedInput />}
        renderValue={(selected: any) => {
          if (selected.length === 0) {
            return <span style={{ color: '#000' }}>Select an option</span>;
          }
          return selected.join(', ');
        }}
        displayEmpty
        IconComponent={CustomIcon}
        MenuProps={{
          classes: { paper: classes.menuPaper, list: classes.menuList },
          disablePortal: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        className={classes.select}
      >
        {platforms.map((platform) => (
          <MenuItem key={platform} value={platform} className={classes.menuItem}>
            <span>
              <img src={WhiteDropdownIcon} alt="" style={{width: "20px", height: "20px", marginRight: "18px"}}/>
            </span>
            {platform.charAt(0).toUpperCase() + platform.slice(1)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelectPlatform;
