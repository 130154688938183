import React from 'react';
// Customizable Area Start
import { Theme, createStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ContentFeed from "./ContentFeed.web";
import {Reactions} from "./Reactions.web";
import TopActiveUsers from "./TopActiverUsers.web";
import FollowBackUsers from "./FollowBackUsers.web";
import DashboardController, { Props } from "./DashboardController.web";
import { Box, Hidden } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    spinnerOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999,
    },
    top6FollowersMobils: {
      width: "100%",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
      [theme.breakpoints.up(950)]: {
        display: "none"
      }
    },
    top6FollowersDesktop: {
      [theme.breakpoints.down(950)]: {
        display: "none"
      }
    },
    spinner: {
      border: '4px solid rgba(0, 0, 0, 0.1)',
      borderTop: '4px solid #3498db', // blue color for the spinner
      borderRadius: '50%',
      width: '50px',
      height: '50px',
      animation: '$spin 1s linear infinite',
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
    rightSidebar: {
      position: "sticky",
      top: 64,
      minHeight: "100vh",
      gap: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(3),
      },
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(4.5, 0, 0, 0),
      },
    },
    reactionGrid: {
      marginBottom: -10,
    }
  });

class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, navigation } = this.props
    const {
      isLoader,
      account,
      posts,
      top6,
      followersBack,
      postLoader,
      postPageNo,
      postTotalPages,
      isSortingComments
    } = this.state

    return (
      <>
        {isLoader && <div className={classes.spinnerOverlay}>
          <div className={classes.spinner}></div>
        </div>}
        <Grid container>
          <Box className={classes.top6FollowersMobils}>
            <TopActiveUsers top6={top6} navigation={navigation}/>
          </Box>

          <Grid item lg={9} md={12} sm={12} xs={12}>
            <ContentFeed
              posts={posts}
              pageNo={postPageNo}
              userAccount={account}
              postLoader={postLoader}
              totalPages={postTotalPages}
              achiveId={this.state.achiveId}
              showAlert={this.state.isShowAlert}
              showSuccess={this.state.showSucess}
              isSortingComments={isSortingComments}
              scrollToPost={this.state.scrlltoPost}
              errorAlertMessage={this.state.errorAlertMessage}
              banUserApiCall={this.banUserApiCall}
              hidePostApicall={this.hidePostApiCall}
              postCommentApiCall={this.postCommentApiCall}
              handlePageNoChange={this.handlePostPageNoChange}
              deleteCommentApiCall={this.deleteCommentApiCall}
              archiveCommentApiCall={this.archiveCommentApiCall}
              handleLikePostAPICall={this.handleLikePostAPICall}
              handleUndoLikeAPICall={this.handleUndoLikeAPICall}
              handlePostProfileClick={this.handlePostProfileClick}
              getPostCommentsApiCall={this.getPostCommentsApiCall}
              handleCreatePostRedirection={this.handleCreatePostRedirection}
              handleSortingCommentsChange={this.handleSortingCommentsChange}
              revertArchiveCommentApiCall={this.revertArchiveCommentApiCall}
              handleVote={this.handleVote}
              handleDeleteVote={this.handleDeleteVote}
            />
          </Grid>
          <Grid item container lg={3} md={12} sm={12} direction="column" className={classes.rightSidebar}>
            <Hidden mdDown>
              <Grid item className={classes.reactionGrid}>
                <Reactions />
              </Grid>
            </Hidden>
            <Grid item className={classes.top6FollowersDesktop}>
              <TopActiveUsers top6={top6} navigation={navigation}/>
            </Grid>
            <Grid item>
              <FollowBackUsers followers={followersBack} handleCreateFollower={this.postCreateFollower} />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

export { Dashboard };
export default withStyles(styles)(Dashboard);
// Customizable Area End
