import React from "react";
import { Box, Button, Grid, Typography, Theme, createStyles, withStyles } from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import Rating from "@material-ui/lab/Rating";
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import UserProfileController, { ISelectedPrompt } from "./UserProfileController.web";
import { Swiper, SwiperSlide } from 'swiper/react';
import CustomLoader from "../../../components/src/CustomLoader.web";
import SwiperCore, { Navigation, Pagination } from 'swiper';
import CustomButton from "../../../components/src/CustomButton.web";
import { styled } from "@material-ui/core/styles"
import clsx from "clsx";

SwiperCore.use([Navigation, Pagination]);

const PersonalityPromptsWrapper = styled(Box)({
    "& .swiper-pagination": {
        position: "relative",
        display: "flex",
        alignItems: "center",
        gap: "15px",
        justifyContent: "center",
        marginTop: "10px",
        zIndex: 1,
        "& .swiper-pagination-bullet": {
            height: "10px",
            width: "10px",
            backgroundColor: "#000",
            opacity: "1",
        },
        "& .swiper-pagination-bullet-active": {
            backgroundColor: "#FFC629"
        },
    },
    "& .swiper-button-prev, .swiper-button-next": {
        "& .swiper-button-disabled": {
            opacity: 0.4
        },
        "@media (max-size: 650px)":{
            display: "none"
        }
    },
    "& .swiper-wrapper": {
        height: "auto"
    }
})

const mostActive = [
    {
        name: "Paquita"
    },
    {
        name: "Michelle Jones"
    },
    {
        name: "Jackson Bates"
    },
    {
        name: "Andrew Jackson"
    },
    {
        name: "Anna Smith"
    },
    {
        name: "Jhonatan reed"
    }
]
export class UserProfileBasicBu extends UserProfileController {
    renderFollowingButton() {
        return (
            this.state.userID && this.state.selfProfile === "false" && <Button style={webStyle.contactUsbtn} data-test-id="follow-btn-bu" onClick={()=>this.followBrandApiProfile(this.state.userID, false, this.state.isFollowing)}>{this.state.isFollowing ? "Following" : "Follow"}</Button>
        )
    }

    followerData() {
        return (
            <Box style={webStyle.swiperContainer}>
                <div className={this.props.classes.swiperLeftButton}
                    id="swiper-button-prev">
                    <ArrowBackIosOutlinedIcon style={{ width: "33px", height: "24px", color: "#334155", zIndex: 100 }} />
                </div>
                <Swiper
                    spaceBetween={4}
                    slidesPerView={window.innerWidth < 650 ? 4 : 5}
                    navigation={{
                        prevEl: '#swiper-button-prev',
                        nextEl: '#swiper-button-next',
                    }}
                    pagination={{
                        clickable: true,
                        el: '.swiper-pagination',
                    }}>
                    {
                        this.state.followersData && this.state.followersData?.followers?.data?.map((followers: any, index: any) => (
                            <SwiperSlide key={index} >
                                <Box className={this.props.classes.brandsLiContainer}>
                                    <Box className={this.props.classes.followerImageContainer}>
                                        <img src={followers.attributes.profile_pic ? followers.attributes.profile_pic.url : `https://avatar.iran.liara.run/public/${index+1}`} alt={followers.name} style={{ width: '100%', height: '100%', borderRadius: '50%' }} />
                                    </Box>
                                    <Typography style={webStyle.followersName}>{followers.attributes.full_name ? followers.attributes.full_name : `${followers.attributes.first_name} ${followers.attributes.last_name}`}</Typography>
                                </Box>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
                <div id="swiper-button-next" className={this.props.classes.swiperRightBtn}>
                    <ArrowForwardIosOutlinedIcon style={{ width: "33px", height: "24px", color: "#334155", zIndex: 100 }} />
                </div>
                <div className={this.props.classes.rightFadeBox}></div>
            </Box>
        )
    }
    handleFollowersRender(data : {profile: {data: {attributes : {followers: string}}}} | null) {
        return (data && data.profile.data.attributes.followers ?
        this.followerData()
        :
        <Typography className={this.props.classes.followersHeading}>
            No followers found
        </Typography>)
    }
    render() {
        const { data } = this.state.userDetails;
        const { classes }: any = this.props;
        const personalityPrompts = data?.profile?.data?.attributes?.personality_prompt || []

        return (
            <>
                <div className={classes.root}>
                   <CustomLoader wrapperHeight="70vh" loaderSize={24} isLoading={this.state.circularLoader}>
                    <Box className={classes.followersContainer}>
                        <Typography className={classes.followersHeading}>
                            Followers
                        </Typography>
                        {
                            this.handleFollowersRender(data)
                        }
                    </Box>
                   <Box style={webStyle.upperContainer}>
                   <Box style={{
                        backgroundImage: data && `url(${data.profile.data.attributes.photo})`
                    }}
                    className={classes.profileImage}
                    >
                        <Box style={{ textAlign: "end", padding: "10px 16px 10px 16px" }}>
                            {!this.state.userID && <Link to="/UserProfile">
                                <IconButton
                                    aria-label="edit profile picture"
                                    style={{ width: "31px", height: "31px", backgroundColor: "#0F172A", borderRadius: "8px" }}
                                >
                                    <EditOutlinedIcon style={{ color: "#FFFFFF" }} />
                                </IconButton>
                            </Link>}
                        </Box>

                        <Box className={classes.contentWrapper}>
                            <Box style={{display: "flex", flexDirection: 'column', gap: "3px"}}>
                                <Typography className={classes.cityStateContainer}>
                                    {data?.profile.data.attributes.city}{" "}{data?.profile.data.attributes.country}
                                </Typography>
                                <Typography className={classes.userName}>
                                    {data?.profile.data.attributes.name}
                                </Typography>
                                <Typography
                                    style={{
                                        fontFamily: "Inter",
                                        fontSize: "14px",
                                        lineHeight: "24px",
                                        fontWeight: 400,
                                        color: "#FFFFFF",
                                        wordWrap: 'break-word',
                                        overflowWrap: "break-word", 
                                        whiteSpace: 'normal',
                                    }}>
                                    {data && data.profile.data.attributes.headline}
                                </Typography>
                                {this.renderFollowingButton()}
                            </Box>
                            <Box data-test-id="followWrapper" className={classes.follwingFollowWrapper}>
                                <Box>
                                    <Typography className={classes.followFollowingCount}>
                                        {data && data.profile.data.attributes.followers}
                                    </Typography>
                                    <Typography className={classes.followFollowing}>Followers</Typography>
                                </Box>
                                <Box>
                                    <Typography className={classes.followFollowingCount}>
                                        {data && data.profile.data.attributes.following}
                                    </Typography>
                                    <Typography className={classes.followFollowing}>Following</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <PersonalityPromptsWrapper>
                    {personalityPrompts.length > 0 ?
                        <>
                        <Box className={classes.quoteContainer}>
                            <ArrowBackIosOutlinedIcon
                                data-test-id={`prev-button`}
                                className={clsx(classes.arrowIcon, "swiper-button-prev")}
                                style={{ ...webStyle.quotesButton, ...webStyle.previousIcon }}
                            />
                            <Swiper
                                slidesPerView={1}
                                navigation={{
                                    nextEl: '.swiper-button-next',
                                    prevEl: '.swiper-button-prev',
                                }}
                                pagination={{
                                    clickable: true,
                                    el: '.swiper-pagination',
                                }}
                            >
                                {personalityPrompts.map((quote: ISelectedPrompt) => (
                                    <SwiperSlide style={{ width: "100%" }}  key={quote}>
                                        <Box width="100%">
                                            <Typography className={classes.quoteHeading}>
                                                {quote.question}
                                            </Typography>
                                            <Typography className={classes.quoteDescription}>
                                                {quote.answer}
                                            </Typography>
                                        </Box>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <ArrowForwardIosOutlinedIcon
                                data-test-id={`next-button`}
                                className={clsx(classes.arrowIcon, "swiper-button-next")}
                                style={{ ...webStyle.quotesButton, ...webStyle.nextIcon }}
                            />
                            <Box className="profile-swiper-pagination swiper-pagination" />
                        </Box>
                        </>
                        : 
                        <Box style={ this.state.userID ? {display: "none"} : {padding: "15px 20px"}}>
                            <CustomButton onClick={this.handleProfileEditRedirection}>
                                Add Some Personality
                            </CustomButton>
                        </Box>
                        }
                        </PersonalityPromptsWrapper>
                    </Box>
                    <div style={webStyle.infoContainer}>
                        <Box className={classes.ratingContainer}>
                            <Typography className={classes.ratingRating}>
                                {data && data.profile.data.attributes.average_rating}{".0"}
                            </Typography>
                            <Rating
                                name="read-only"
                                size='large'
                                value={data?.profile.data.attributes.average_rating || 0}
                                readOnly
                                precision={0.1}
                                className={classes.ratingStarIcon}
                            />
                            <Typography className={classes.reviewCount}>
                                {data && data.profile.data.attributes.reviews} Reviews
                            </Typography>
                        </Box>
                        <div className={classes.hrDiv}></div>
                        {!this.state.userID && <Box style={webStyle.top6Container}>
                            <Box className={classes.topSixUserText}>
                                <Typography className={classes.top6heading}>Your Top 6</Typography>
                                <Typography className={classes.activeUserHeading}>Most active users in platform</Typography>
                            </Box>
                            <Grid container spacing={2} justifyContent="center" className={classes.mostActivaGridContainer}>
                                {
                                    mostActive && mostActive.map((active, index) => (
                                        <>
                                            <Grid xs={4} sm={4}>
                                                <Box className={classes.activeUserWrapper}>
                                                    <img src={`https://avatar.iran.liara.run/public/${index+1}`} alt=""
                                                        className={classes.activeUser} />
                                                    <Typography className={classes.activeName}>{active.name}</Typography>
                                                </Box>
                                            </Grid>
                                        </>
                                    ))
                                }

                            </Grid>
                        </Box>}
                        <Box style={webStyle.postContainer}>
                            <Typography style={{
                                fontFamily: "Inter",
                                fontSize: "16px",
                                fontWeight: 700,
                                lineHeight: "22px",
                                color: "#0F172A",
                                paddingBottom: "10px"

                            }}>Posts</Typography>
                            <Grid container spacing={2} justifyContent="center">
                                {data && data.posts.data.map((post: any, index: any) => (
                                    <Grid item key={index} xs={4} style={{flexBasis: "0%"}}>
                                        <Box className={classes.postContentContainer} >
                                            {post.attributes.files && post.attributes.files[0] ? (
                                                this.isVideo(post.attributes.files[0]) && (
                                                    <video className={classes.video} src={post.attributes.files[0].link} />
                                                ) || (
                                                    <img className={classes.image}
                                                     src={post.attributes.files[0].link}
                                                    alt={`https://picsum.photos/id/${index + 10}/200/300`}
                                                    />
                                                )
                                            ) : (
                                                <img
                                                        src={`https://picsum.photos/id/${index + 10}/200/300`}
                                                        alt=""
                                                        height="100%"
                                                        width="100%"
                                                    />
                                            )}
                                            <Box
                                                sx={{
                                                    position: "absolute",
                                                    bottom: 8,
                                                    left: 8,
                                                    color: '#fff',
                                                    padding: '2px 8px',
                                                    borderRadius: 1,
                                                }}
                                            >
                                                <Typography variant="body2" className={classes.viewCount}>
                                                    &#9654; {post.attributes.views_count}
                                                </Typography>
                                            </Box>
                                            <Button className={classes.trendingShow}>Trending</Button>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>

                        </Box>

                    </div>
                    </CustomLoader>
                </div >
            </>
        )
    }
}

const webStyle = {
    followersName: {
        textAlign: "center",
        marginTop: "10px",
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "13px",
        color: "#334155",
    } as React.CSSProperties,

    upperContainer: {
        backgroundColor: "#fff",
        marginBottom: "10px",
        borderRadius: "20px 20px 1px 1px"
    },
    previousIcon: {
        left: "-5px",
        zIndex: 0,
    },
    nextIcon: {
        right: "-5px",
        zIndex: 0
    },
    quotesButton: {
        position: "absolute",
        width: "30px",
        height: "30px",
        cursor: "pointer",
        color: "#334155",
        border: "2px solid #334155",
        borderRadius: "50%",
        padding: "5px",
        top: "50%",
    } as React.CSSProperties,
    contactUsbtn: {
        background: "#FFC629",
        padding: "10px 16px 10px 16px",
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#000000",
        borderRadius: "30px",
        textTransform: "none",
        cursor: "pointer",
        maxWidth: "150px"
    } as React.CSSProperties,
    infoContainer: {
        padding: "14px 20px 31px 20px",
        backgroundColor: "#fff"
    },
    
    top6Container: {
        marginTop: "24px"
    },
    postContainer: {
        marginTop: "65px",
    },
    trendingBtn: {
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        color: "#000000",
        position: "absolute",
        top: "5px",
        left: "108px",
        textTransform: "none",
        borderRadius: "29px",
        backgroundColor: "#FFC629"
    } as React.CSSProperties,
    swiperContainer: {
        position: "relative",
        height: "140px",
        "& .swiper-wrapper": {
            height: "auto"
        }
    } as React.CSSProperties,
    slidesDotsUl: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0px",
        gap:"30px",
        "& li": {
          margin: "0px 15px",
          fontSize: "32px",
        },
      },
      slidesDotsColor: {
        color: "#FFC629 !important"
      },
    swiperSlideContainer: {
        height: "auto"
    }
}

const styles = (theme: Theme) => createStyles({
    profileImage: {
        // backgroundImage: `url(${Woman})`,
        borderRadius: "20px 20px 0px 0px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        width: "100%",
        height: "451px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        position: "relative",
        "&::before": {
            content: '""',
            display: 'block',
            background: "linear-gradient(0deg, rgba(3,2,11,0.8127626050420168) 0%, rgba(8,12,13,0) 52%)",
            position:'absolute',
            top:0,
            left:0,
            right:0,
            bottom:0
          }
    },
    followersContainer: {
        margin: "20px",
        maxHeight: "200px",
        [theme.breakpoints.down(650)]: {
            margin: "20px 0 0px 20px",
        },
    },
    followersHeading: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#000000",
        paddingLeft: "67px",
        marginBottom: "27px",
        [theme.breakpoints.down(650)]: {
            paddingLeft: "0",
            marginBottom: "4px",
        },
    },
    rightFadeBox:{
        position: "absolute",
        top: "-6px",
        right: 0,
        width: "120px",
        height: "120px",
        zIndex: 1,
        background: "linear-gradient(to right, #D9D9D900 40%, #FFFFFF 100%)",
        [theme.breakpoints.up(650)]: {
            display: "none"
        },
    },
    root: {
        backgroundColor: "#F1F5F9",
        maxWidth: "780px",
        margin: "20px auto 77px",
        width: "100%",
        [theme.breakpoints.down(950)]: {
            maxWidth: "700px"
        },
        [theme.breakpoints.down(750)]: {
            maxWidth: "600px"
        },
        [theme.breakpoints.down(650)]: {
            maxWidth: "550px"
        },
        [theme.breakpoints.down(550)]: {
            maxWidth: "100vw"
        }
    },
    ratingContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "24px 0",
        [theme.breakpoints.down(650)]: {
            margin: "0",
            flexWrap: "wrap"
        },
    },
    ratingStarIcon:{
        fontSize: "60px",
        color: "#FFC629",
        gap: "24px",
        [theme.breakpoints.down(650)]: {
            gap: "6px",
            fontSize: "24px"
        },
    },
    reviewCount: {
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#000000",
        [theme.breakpoints.down(650)]: {
            fontSize: "12px"
        },
    },
    ratingRating: {
        fontFamily: "Inter",
        fontSize: "40px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#000000",
        [theme.breakpoints.down(650)]: {
            fontSize: "18px"
        },
    },
    mostActivaGridContainer:{ 
        marginTop: "44px",
         paddingLeft: "42px",
        [theme.breakpoints.down(650)]: {
            marginTop: "12px",
            paddingLeft: "0",
        },
     },
    postContentContainer: {
        width: '196px',
        height: '328px',
        position: "relative",
        [theme.breakpoints.down(650)]: {
            width: '110px',
            height: '184px',
        },
    },
    video: {
        width: "100%",
        height: "100%",
        objectFit: "cover"
    },
    image: {
        width: "100%",
        height: "100%",
        objectFit: "cover"
    },
    trendingShow:{
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        color: "#000000",
        position: "absolute",
        top: "5px",
        left: "108px",
        textTransform: "none",
        borderRadius: "29px",
        backgroundColor: "#FFC629",
        [theme.breakpoints.down(650)]: {
            fontSize: "10px",
            color: "#fff",
            backgroundColor: "#000",
            left: "40%",
            lineHeight: "10px"
        },
    },
    swiperLeftButton:{
        position: "absolute",
        transform: "translateY(-50%)",
        borderRadius: "50%",
        width: "37px",
        height: "37px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        border: "2px solid #334155",
        top: 54,
        left: "-40px",
        [theme.breakpoints.down(650)]: {
            left: "-20px",
            display: "none"
        },
    },
    swiperRightBtn:{
        position: "absolute",
        transform: "translateY(-50%)",
        borderRadius: "50%",
        width: "37px",
        height: "37px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        border: "2px solid #334155",
        top: 54,
        right: "-40px",
        [theme.breakpoints.down(650)]: {
            right: "-20px",
            display: "none"
        },
    },
    followerImageContainer: {
        width: "85px",
        height: "85px",
        border: "2px solid #FFC629",
        borderRadius: "50%",
        [theme.breakpoints.down(650)]: {
            width: "50px",
            height: "50px",
        },
    },
    brandsLiContainer: {
        margin: "15px 25px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.down(650)]: {
            margin: "15px 0"
        },
    },
    cityStateContainer:{
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#FFFFFF",
        marginBottom: "5px",
        background: "rgba(0,0,0,0.5)",
        borderRadius: "24px",
        alignSelf: "flex-start",
        padding: "2px 10px",
        [theme.breakpoints.down(650)]: {
            fontSize: "10px",
        },
    },
    userName:{
        fontFamily: "Inter",
        fontSize: "30px",
        fontWeight: 700,
        color: "#FFFFFF",
        lineHeight: "24px",
        marginBottom: "5px",
        [theme.breakpoints.down(650)]: {
            fontSize: "20px",
        },
    },
    followFollowingCount: {
        fontFamily: "Inter",
        fontSize: "30px",
        fontWeight: 700,
        lineHeight: "40px",
        color: "#FFFFFF",
        textAlign: "center",
        [theme.breakpoints.down(650)]: {
            fontSize: "20px",
        },
    },
    followFollowing: {
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "18px",
        color: "#FFFFFF",
        [theme.breakpoints.down(650)]: {
            fontSize: "12px",
        },
    },
    contentWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
        padding: "30px 40px",
        position: "relative",
        [theme.breakpoints.down(650)]: {
            padding: "30px 20px",
        },
    },
    viewCount:{
        [theme.breakpoints.down(650)]: {
            fontSize: "10px",
        },
    },
    hrDiv:{
        [theme.breakpoints.down(650)]: {
            width: '100%',
            height: "1px",
            backgroundColor: "#E2E8F0",
            marginTop: "12px"
        },
    },
    top6heading: {
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#000000",
        [theme.breakpoints.down(650)]: {
            fontSize: "16px",
        },
    },
    topSixUserText:{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        [theme.breakpoints.down(650)]: {
            flexDirection: "row",
            alignItems: "center",
        },
    },
        
    activeUserHeading: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#000000",
        [theme.breakpoints.down(650)]: {
            fontSize: "10px",
        },
    },
    activeUserWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
    },
    activeUser: {
        width: "100px",
        height: "100px",
        borderRadius: "136px",
        border: "2px solid #FFC629",
        [theme.breakpoints.down(650)]: {
            width: "72px",
            height: "72px",
        },
    },
    activeName: {
        margin: "18px 0",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "13px",
        color: "#334155",
        [theme.breakpoints.down(650)]: {
            fontSize: "12px",
        },
    },
    quoteContainer: {
        marginLeft: "12px",
        marginRight: '12px',
        position: "relative",
        padding: "0px 30px 10px",
        [theme.breakpoints.down(650)]: {
            marginLeft: "0px",
            marginRight: "0px",
            padding: "15px 20px"
        },
    },
    quoteHeading: {
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#FFC629",
        padding: " 14px 10px 10px",
        [theme.breakpoints.down(650)]: {
            fontSize: "12px",
            padding: " 14px 0px 10px",
        },
    },
    quoteDescription: {
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#000000",
        padding: "0px 10px",
        [theme.breakpoints.down(650)]: {
            fontSize: "16px",
            padding: "0px 0px",
        },
    },
    arrowIcon:{
        [theme.breakpoints.down(650)]: {
            display: "none"
        },
    },
    follwingFollowWrapper:{
        display: "flex",
        gap: "44px",
        [theme.breakpoints.down(650)]: {
            gap: "24px",
        },
    }
  });

export default withStyles(styles)(UserProfileBasicBu);