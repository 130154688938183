// Customizable Area Start
import React from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { ContactsIcon, NotificationIcon, ChatNavIcon, SettingsIcon,  HomeIcon } from "./assets"
import MobileNavMenuController from "./MobileNavMenuController.web";

const StyledBottomNavigation = styled(BottomNavigation)({
  backgroundColor: "#fff",
  position: "fixed",
  bottom: 0,
  maxWidth: "100vw",
  width: "100%",
  paddingLeft: "10px",
  paddingRight: "10px",
  height: "auto",
  gap: "5px",
  justifyContent: "space-evenly",
  boxShadow: "0px -3px 5px -1px rgba(0, 0, 0, 0.1)",
  zIndex: 1202,
  "@media (min-width: 1150px)": {
    display: "none",
  },
});

interface StyledBottomNavigationActionProps {
  active: boolean;
}

const StyledBottomNavigationAction = styled(BottomNavigationAction)(
  ({ active }: StyledBottomNavigationActionProps) => ({
    padding: "0px !important",
    minWidth: "auto",
    "& .MuiSvgIcon-root": {
      fontSize: "22px",
      color: "#000",
    },
    "&.Mui-selected": {
      color: "#000",
    },
    "& .MuiBottomNavigationAction-label": {
      fontSize: "10px",
      fontWeight: 400,
      lineHeight: "20px",
      fontFamily: "Inter, 'sans-serif'",
    },
    "& .MuiBottomNavigationAction-wrapper": {
      height: "64px",
      width: "64px",
      borderRadius: "50%",
      backgroundColor: active ? "#FFC629" : "transparent",
    },
  })
);

const StyledMenuIcon = styled("img")({
  width: "18px",
  height: "18px"
});

const MENUS = [
  {
    value: 0,
    label: "Home",
    icon: <StyledMenuIcon src={HomeIcon} alt="" />,
  },
  {
    value: 1,
    label: "Messages",
    icon: <StyledMenuIcon src={ChatNavIcon} alt="" />,
  },
  {
    value: 2,
    label: "Contacts",
    icon: <StyledMenuIcon src={ContactsIcon} alt="" />,
  },
  {
    value: 3,
    label: "Automation",
    icon: <StyledMenuIcon src={SettingsIcon} alt="" />,
  },
  {
    value: 4,
    label: "Notifications",
    icon: <StyledMenuIcon src={NotificationIcon} alt="" />,
  },
];

class MobileNavMenu extends MobileNavMenuController {
  render() {
    const { currentTab } = this.state;

    return (
      <StyledBottomNavigation
        showLabels
        data-test-id="nav-menu-wrapper"
        value={currentTab}
        onChange={this.handleChange}
      >
        {MENUS.map((menu) => {
          const { icon, label, value } = menu;
          return (
            <StyledBottomNavigationAction
              showLabel
              label={label}
              icon={icon}
              data-test-id={`nav-menu-${label}`}
              active={currentTab === value}
            />
          );
        })}
      </StyledBottomNavigation>
    );
  }
}

export default MobileNavMenu;
// Customizable Area End
