// Customizable Area Start
import React from "react";
import { createStyles, Theme } from "@material-ui/core/styles";
import {
  Container,
  Box,
  Typography,
  IconButton,
  InputBase,
  withStyles,
  Checkbox,
  Avatar,
  Button,
  styled
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";

import BulkInviteFriendsController, {
  Props
} from "./BulkInviteFriendsController.web";
import { NormalizedContact } from "./InviteFriendsController.web";
import AddContactModal from "./AddContactModal.web";
import { Clear, Check } from "@material-ui/icons";
import { InviteFriendsStepType } from "./enums";
import clsx from "clsx"
import { backArrow } from "./assets";

const styles = (theme: Theme) =>
  createStyles({
    mainContainer: {
      display: "flex",
      flexWrap: "nowrap",
      justifyContent: "center",
      height: "100%",
      gap: "12px",
      padding: "12px",
      width: "100%",
      "& *": {
        fontFamily: "Inter, 'sans-serif'"
      },
      [theme.breakpoints.down("sm")]: {
        flexWrap: "wrap",
        padding: "10px 5px"
      },
      [theme.breakpoints.down(650)]: {
        padding: "0"
      }
    },

    currentContacts: {
      display: "flex",
      width: "419px",
      minWidth: "320px",
      height: "100%",
      padding: "24px 9px 24px 24px",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: "10px",
      flexGrow: 3,
      flexShrink: 0,
      borderRadius: "8px 0px 0px 8px",
      backgroundColor: "#FFF",
      marginLeft: "12px",
      [theme.breakpoints.down("sm")]: {
        marginRight: "12px",
        padding: "24px",
        borderRadius: "8px 8px 0px 0px",
        minHeight: "100vh"
      },
      [theme.breakpoints.down(650)]: {
        padding: "0 0 80px",
        width: "100%"
      }
    },

    contactsTitle: {
      color: "#000",
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.12px",
      width: "370px",
      marginLeft: "10px",
      alignSelf: "center",
      padding: "24px 20px 10px",
      borderBottom: "1px solid #64748B33",
      [theme.breakpoints.down(650)]: {
        width: "96%",
        marginLeft: "0px"
      },
    },

    closeButtonCurrent: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "flex"
      }
    },

    searchInputContainer: {
      width: "370px",
      height: "44px",
      flexShrink: 0,
      borderRadius: "98px",
      background: "#F7F8FC",
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      },
      [theme.breakpoints.down(650)]:{
        width: "88%"
      }
    },
    createNewContactText:{
      [theme.breakpoints.down("sm")]: {
        display: 'none'
      },
    },

    searchButton: {
      overflow: "auto"
    },

    searchInput: {
      overflow: "auto"
    },

    addContactSpan: {
      cursor: "pointer",
      textDecoration: "underline"
    },

    contactsContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: "10px",
      overflowY: "auto",
      scrollbarWidth: "none",
      msOverflowStyle: "none",
      paddingRight: "9px",
      width: "370px",
      "&::-webkit-scrollbar": {
        width: "5px"
      },

      "&::-webkit-scrollbar-track": {
        visibility: "hidden"
      },

      "&::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        background: "#CBD5E1"
      },
      [theme.breakpoints.down(650)]: {
        width: "340px"
      },
    },

    contactItem: {
      display: "flex",
      padding: "16px",
      gap: "14px",
      borderBottom: "1px solid #E2E8F0",

      "&:nth-child(odd)": {
        backgroundColor: "#F7F8FC"
      }
    },

    contactAvatarBlock: {
      overflow: "auto"
    },

    contactInfoBlock: {
      display: "flex",
      flexGrow: 1,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      color: "#0F172A",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      [theme.breakpoints.down(400)]: {
        width: "200px",
        overflow: "hidden"
      },
    },

    contactName: {
      color: "inherit",
      fontSize: "inherit",
      fontWeight: 700,
      lineHeight: "inherit"
    },

    contactPhone: {
      color: "inherit",
      fontSize: "inherit",
      fontWeight: "inherit",
      lineHeight: "inherit"
    },

    contactEmail: {
      color: "inherit",
      fontSize: "inherit",
      fontWeight: "inherit",
      lineHeight: "inherit",
      textDecorationLine: "underline"
    },

    contactCheckboxBlock: {
      display: "flex",
      alignItems: "flex-start"
    },

    checkBox: {
      padding: 0,

      "&.MuiCheckbox-colorSecondary.Mui-checked": {
        color: "#000"
      }
    },

    addContactContainer: {
      display: "flex",
      flexGrow: 1,
      maxWidth: "1300px",
      height: "100%",
      width: "100%",
      minWidth: "370px",
      padding: "40px 0px",
      overflowY: "auto",
      justifyContent: "center",
      alignItems: "center",
      flexShrink: 0,
      borderRadius: "0px 0px 24px 0px",
      position: "absolute",
      background: "rgba(255, 255, 255, 0.2)",
      backdropFilter: "blur(5px)",
      [theme.breakpoints.down(650)]: {
        minWidth: "340px",
      },

      "& > *": {
        padding: 0,
        flexBasis: "440px",
        [theme.breakpoints.down(650)]: {
          flexBasis: "100%",
        },
      },

      "&::-webkit-scrollbar": {
        width: "5px"
      },

      "&::-webkit-scrollbar-track": {
        visibility: "hidden"
      },

      "&::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        background: "#CBD5E1"
      }
    },

    selectedContactsContainer: {
      display: "flex",
      minWidth: "320px",
      padding: "14px 10px 25px 10px",
      flexDirection: "column",
      flexShrink: 0,
      backgroundColor: "#FFF",
      borderRadius: "0px 0px 24px 0px",
      marginRight: "12px",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      },
    },

    selectedContactsTitle: {
      color: "#000",
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.12px"
    },

    selectedUsersHeadingBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingBottom: "5px",
      borderBottom: "1px solid #64748B33"
    },

    selectedContacts: {
      padding: "14px 9px 25px 10px",
      display: "flex",
      flexGrow: 1,
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: "27px",
      overflowY: "auto",
      msOverflowStyle: "none",
      scrollbarWidth: "none",

      "&::-webkit-scrollbar": {
        width: "5px"
      },

      "&::-webkit-scrollbar-track": {
        visibility: "hidden"
      },

      "&::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        background: "#CBD5E1"
      }
    },

    selectedContactItem: {
      display: "flex",
      flexWrap: "nowrap",
      gap: "14px",
      width: "100%"
    },

    selectedContactAvatarBlock: {
      display: "flex",
      alignItems: "center"
    },

    crossButtonBlock: {
      overflow: "auto"
    },

    removeContactButton: {
      overflow: "auto"
    },

    sendInvitesButtonContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "20px 0 0"
    },
    currentContactsSendButton: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "flex"
      }
    },

    sendInvitesButton: {
      display: "flex",
      alignSelf: "center",
      width: "201px",
      height: "56px",
      padding: "16px 28px 16px 16px",
      alignItems: "center",
      borderRadius: "8px",
      background: "#FFC629",
      color: "#0F172A",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      textTransform: "uppercase"
    }
  });

const YellowTopNavigationBarMobile = styled(Box)(({theme}) => ({
  padding: "0 24px",
  heigth: "44px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  "& p": {
    marginLeft: "24px",
    fontWeight: "bold",
    fontSize: "18px"
  },
  backgroundColor: "#ffc629",
  [theme.breakpoints.up(650)]: {
    display: "none"
  }
}))

class BulkInviteFriends extends BulkInviteFriendsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      contacts,
      classes,
      addContact,
      setStep,
      checkBulkContact,
      submitInvites,
      addContactModal
    } = this.props;

    const { filteredContacts } = this.state;

    return (
      <Container
        id="buklInviteContainer"
        disableGutters
        maxWidth={false}
        className={classes.mainContainer}
        onClick={e => e.stopPropagation()}
      >
        <Box className={classes.currentContacts}>
          <YellowTopNavigationBarMobile>
            <img src={backArrow} alt="" onClick={() => setStep(InviteFriendsStepType.INVITE_FRIEND)} data-test-id="closeBulkInviteModal"/>
            <p>Invite a friend</p>
          </YellowTopNavigationBarMobile>
          <Typography className={classes.contactsTitle}>
            {this.currentContacts}
          </Typography>
          <Box className={classes.searchInputContainer}>
            <IconButton type="button" className={classes.searchButton}>
              <SearchIcon />
            </IconButton>
            <InputBase
              id="searchInput"
              name="searchInput"
              className={classes.searchInput}
              placeholder="Search"
              onChange={this.searchContact}
            />
          </Box>
          <Typography style={{ margin: "5px" }} className={classes.createNewContactText}>
            Don't see contact in the list? Create one{" "}
            <span
              className={classes.addContactSpan}
              data-test-id="addContactSpan"
              onClick={() =>
                addContactModal
                  ? addContactModal()
                  : this.setState({ addContactBulk: true })
              }
            >
              here
            </span>
          </Typography>
          <Container disableGutters className={classes.contactsContainer}>
            {filteredContacts.map((contact: NormalizedContact) => (
              <Container
                key={contact.id}
                disableGutters
                className={classes.contactItem}
              >
                <Box className={classes.contactAvatarBlock}>
                  <Avatar src={""} alt={contact.fullName} />
                </Box>
                <Box className={classes.contactInfoBlock}>
                  <Typography className={classes.contactName}>
                    {contact.fullName}
                  </Typography>
                  <Typography className={classes.contactPhone}>
                    {contact.phone}
                  </Typography>
                  <Typography className={classes.contactEmail}>
                    {contact.email}
                  </Typography>
                </Box>
                <Box className={classes.contactCheckboxBlock}>
                  <Checkbox
                    checked={contact.checked}
                    className={classes.checkBox}
                    onClick={() => checkBulkContact(contact.id)}
                    icon={<Box style={{ borderRadius: "6px", border: "1px solid gray", width: 20, height: 20 }} />}
                    checkedIcon={
                      <Box
                        style={{
                          width: 20,
                          height: 20,
                          borderRadius: "6px",
                          backgroundColor: "#000",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Check style={{ color: "#fff", fontSize: 18, fontWeight: "bold" }} />
                      </Box>
                    }
                  />
                </Box>
              </Container>
            ))}
          </Container>
          <Box className={clsx(classes.sendInvitesButtonContainer, classes.currentContactsSendButton)}>
            <Button
              className={classes.sendInvitesButton}
              onClick={submitInvites}
            >
              {this.continueBulkInvite}
            </Button>
          </Box>
        </Box>
        <Box className={classes.selectedContactsContainer}>
          <Box
            data-test-id="selectedUsersHeadingBox"
            className={classes.selectedUsersHeadingBox}
          >
            <Typography className={classes.selectedContactsTitle}>
              {this.selectedUsers}
            </Typography>
            <IconButton
              id="closeModalButton"
              className={classes.closeButton}
              data-test-id="closeBulkInvite"
              onClick={() => setStep(InviteFriendsStepType.INVITE_FRIEND)}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Container disableGutters className={classes.selectedContacts}>
            {contacts
              .filter((contact: NormalizedContact) => contact.checked)
              .map((contact: NormalizedContact) => (
                <Box
                  key={contact.id}
                  data-test-id="selectedContactItem"
                  className={classes.selectedContactItem}
                >
                  <Box className={classes.selectedContactAvatarBlock}>
                    <Avatar src="" alt={contact.fullName} />
                  </Box>
                  <Box className={classes.contactInfoBlock}>
                    <Typography className={classes.contactName}>
                      {contact.fullName}
                    </Typography>
                  </Box>
                  <Box className={classes.crossButtonBlock}>
                    <IconButton
                      data-test-id="contactCheckbox"
                      className={classes.removeContactButton}
                      onClick={() => checkBulkContact(contact.id)}
                    >
                      <Clear />
                    </IconButton>
                  </Box>
                </Box>
              ))}
          </Container>
          <Box className={classes.sendInvitesButtonContainer}>
            <Button
              className={classes.sendInvitesButton}
              onClick={submitInvites}
            >
              {this.continueBulkInvite}
            </Button>
          </Box>
        </Box>
        {this.state.addContactBulk && (
          <Box className={classes.addContactContainer}>
            {
              <AddContactModal
                currentStep={InviteFriendsStepType.BULK_INVITE}
                addContact={addContact}
                setStep={this.setStepBulkInvite}
              />
            }
          </Box>
        )}
      </Container>
    );
  }
}

export { BulkInviteFriends };
export default withStyles(styles)(BulkInviteFriends);
// Customizable Area End
