// Customizable Area Start
import React from "react";
import {withRouter} from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createStyles, Theme } from "@material-ui/core/styles";
import { Dialog, DialogContent, styled, withStyles } from "@material-ui/core";
import { NewEveryreelSteps } from "./enums";
import CreateEveryreelController from "./CreateEveryreelController.web";
import CreateEveryreelInitial from "./CreateEveryreelInitial.web";
import FileDropzoneModal from "./FileDropzoneModal.web";
import CreateEveryreelConfirm from "./CreateEveryreelConfirm.web";
import CreateEveryreelSettings from "./CreateEveryreelSettings.web";
import EverybrandLibrary from "./EverybrandLibrary.web";

const styles = (theme: Theme) =>
  createStyles({
    reelDialogPaper: {
      borderRadius: "8px 8px 32px 8px",
      "& > .MuiDialogContent-root": {
        padding: "0px",
      },
      "& *": {
        fontFamily: "Inter, 'sans-serif'",
      },
      [theme.breakpoints.down(650)]: {
        "&.MuiDialog-paper": {
          padding: "0px !important",
        },
        "&.MuiDialog-paperFullWidth": {
          width: "calc(100% - 24px) !important",
        },
      },
    },
  });

  const CustomDiloag = styled(Dialog)(({theme}) => ({
    "& .MuiDialog-paperFullWidth":{
      borderRadius: "8px 8px 32px 8px"
    },
    [theme.breakpoints.down(650)]: {
      "& .MuiDialog-paper": {
        margin: "0px",
      },
      "& .MuiDialog-paperFullWidth": {
        width: "calc(100% - 24px)",
      },
    },
    "& .MuiDialogContent-root":{
      padding: "20px 12px 20px"
    }
  }))

export class CreateEveryreel extends CreateEveryreelController {
  render() {
    const { classes } = this.props;
    const { currentStep, files, postType, savedFunnelFiles, uploadedMediaError } = this.state;

    return (
      <>
        <CssBaseline />
        <CustomDiloag
          open
          fullWidth
          maxWidth="md"
          onClose={this.closeModal}
        >
          <DialogContent>
          {currentStep === NewEveryreelSteps.INITIAL_CHOICE && (
            <CreateEveryreelInitial
              setStep={this.setStep}
              closeModal={this.closeModal}
              setPostType={this.setPostType}
            />
          )}
          {currentStep === NewEveryreelSteps.SELECT_FILE && (
            <FileDropzoneModal
              files={files}
              errorMessage={uploadedMediaError}
              setFiles={this.setFiles}
              setStep={this.setStep}
              closeModal={this.closeModal}
              openLibrary={() => this.setStep(NewEveryreelSteps.EVERYREEL_LIBRARY)}
            />
          )}
          {currentStep === NewEveryreelSteps.EVERYREEL_LIBRARY && (
            <EverybrandLibrary goBack={() => {this.setStep(NewEveryreelSteps.EVERYREEL_LIBRARY)}} setFiles={this.setFiles}/>
          )}
          {currentStep === NewEveryreelSteps.FILE_CONFIRM && (
            <CreateEveryreelConfirm
              files={files}
              postType={postType!}
              setStep={this.setStep}
              closeModal={this.closeModal}
              sendFunnelFiles={this.sendFunnelFiles}
            />
          )}
          {currentStep === NewEveryreelSteps.POST_SETTINGS && (
            <CreateEveryreelSettings
              postType={postType!}
              files={files}
              savedFunnelFiles={savedFunnelFiles}
              setStep={this.setStep}
              closeModal={this.closeModal}
              addFiles={this.addFiles}
            />
          )}
        </DialogContent>
        </CustomDiloag>
      </>
    );
  }
}

export default withStyles(styles)(withRouter(CreateEveryreel));
// Customizable Area End
