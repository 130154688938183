// Customizable Area Start
import React from "react";
import InstagramLibraryController from "./InstagramLibraryController.web";
import {
  Box,
  Grid,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import CloseRounded from "@material-ui/icons/CloseRounded";
import CustomLoader from "../../../components/src/CustomLoader.web";
import CustomButton from "../../../components/src/CustomButton.web";
import SocialVideoElement from "../../../components/src/SocialVideoElement.web"
const configJSON = require("../src/config.js");

const CustomDialogTitle = styled(DialogTitle)({
  position: "relative",
  padding: "40px 30px 20px",
});

const CloseIcon = styled(IconButton)({
  position: "absolute",
  top: "10px",
  right: "10px",
});

const MainHeading = styled(Typography)({
  fontSize: "28px",
  fontWeight: 700,
  lineHeight: "32px",
  letterSpacing: "-0.13px",
  "@media (max-width: 650px)":{
    fontSize: "20px"
  }
});

const MediaWrapper = styled(Box)({
  padding: "0px 30px",
});

const MediaContainer = styled(Box)({
  position: "relative",
  width: "100%",
  paddingTop: "100%",
  backgroundColor: "#000",
  overflow: "hidden",
  aspectRatio: 4 / 5,
  borderRadius: "8px",
  cursor: "pointer",
});

const SelectedLayer = styled(Box)({
  position: "absolute",
  height: "100%",
  width: "100%",
  zIndex: 2,
  backgroundColor: "rgba(0,0,0,0.5)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  top: "0px",
  "& span": {
    display: "flex",
    fontSize: "40px",
    color: "#eebe40",
    fontWeight: 700,
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Inter, 'sans-serif'",
  },
});

const MediaElement = styled("img")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

const ErrorMessageWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  height: "20vh",
  alignItems: "center",
  padding: "0px 30px",
  "& > p": {
    fontSize: "14px",
    fontFamily: "Inter, 'sans-serif'",
    color: "#f44336",
  },
});

const ButtonsWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: "10px",
  padding: "20px 30px",
  "& button": {
    maxWidth: "132px",
    height: "44px",
  },
  "@media (max-width: 450px)":{
    flexWrap: "wrap"
  }
});

class InstagramLibrary extends InstagramLibraryController {
  render() {
    const { handleGoBack } = this.props;
    const {
      isLoading,
      selectedMediaIds,
      instagramMedia,
      apiError,
      paginationLoader,
    } = this.state;

    return (
      <>
        <CustomDialogTitle>
          <CloseIcon onClick={handleGoBack}>
            <CloseRounded />
          </CloseIcon>
          <MainHeading>{configJSON.selectInstagramPostTitle}</MainHeading>
        </CustomDialogTitle>
        <DialogContent ref={this.dialogContentRef} data-test-id="dialog-content">
          <CustomLoader
            isLoading={isLoading}
            wrapperHeight="55vh"
            loaderSize={24}
          >
            {apiError && <ErrorMessageWrapper>
              <Typography data-test-id="error-message">{apiError}</Typography>
            </ErrorMessageWrapper>}
            <MediaWrapper>
              <Grid container spacing={2}>
                {instagramMedia.map((media) => {
                  const { id, media_type, media_url } = media;
                  const currentMediaIndex = selectedMediaIds.indexOf(id);

                  return (
                    <Grid item xs={12} sm={6} md={4} lg={4} key={id}>
                      <MediaContainer
                        data-test-id="media-container"
                        onClick={() => this.toggleSelectionMedia(id)}
                      >
                        {media_type === "IMAGE" ? (
                          <MediaElement src={media_url} alt="Instagram Media" />
                        ) : (
                          <SocialVideoElement src={media_url} />
                        )}
                        {currentMediaIndex !== -1 && (
                          <SelectedLayer>
                            <span data-test-id="selected-post-count">
                              {currentMediaIndex + 1}
                            </span>
                          </SelectedLayer>
                        )}
                      </MediaContainer>
                    </Grid>
                  );
                })}
              </Grid>
              {paginationLoader && <Box mt={2}>
                <CustomLoader isLoading={paginationLoader} loaderSize={20} />
              </Box>}
            </MediaWrapper>
          </CustomLoader>
        </DialogContent>
        <DialogActions>
          <ButtonsWrapper>
            <CustomButton btnType="secondary" onClick={handleGoBack}>
              {configJSON.cancelLabel}
            </CustomButton>
            <CustomButton
              data-test-id="accept-button"
              disabled={selectedMediaIds.length === 0}
              onClick={this.handleAcceptFiles}
            >
              {configJSON.acceptLabel}
            </CustomButton>
          </ButtonsWrapper>
        </DialogActions>
      </>
    );
  }
}

export default InstagramLibrary;
// Customizable Area End
