// Customizable Area Start
import React from 'react';
import { Grid, styled } from '@material-ui/core';
import { ChatBubbleLargeIcon, ChatBubbleSmallIcon, HeartIcon, StarIcon,message } from './assets';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import DashboardController from './DashboardController.web';

class Reactions extends DashboardController {

  render() {
    const { reactionCount } = this.state

    return (
      <Paper square elevation={0}>
      <ReactionGrid
        container
        justifyContent="center">
        <Grid item className='reactionData'>
          <img src={HeartIcon} className='iconStyle likeIcon'/>
          <Typography
            data-test-id="likeCount"
            variant="subtitle2"
            align="center"
            className='iconCount'>
            {reactionCount.likes}
          </Typography>
        </Grid>
        <Grid item className='reactionData'>
          <img src={ChatBubbleLargeIcon} className='iconStyle messageIcon'style={{padding: "2px"}}/>
          <Typography
            data-test-id="commentCount"
            variant="subtitle2"
            align="center"
            className='iconCount'>
            {reactionCount.comments}
          </Typography>
        </Grid>
        <Grid item className='reactionData'>
          <img src={StarIcon} className='iconStyle starIcon'/>
          <Typography
            data-test-id="reviewCount"
            variant="subtitle2"
            align="center"
            className='iconCount'>
            {reactionCount.reviews}
          </Typography>
        </Grid>
      </ReactionGrid>
    </Paper>
    );
  }
}
export { Reactions }

const ReactionGrid = styled(Grid)({
  padding: "8px",
  gap: "16px",
  "& .iconStyle": {
    width: "45px",
    height: "40px",
    "@media (max-width: 960px)": {
      width: "25px",
      height: "20px"
    }
  },
  "& .reactionData": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  "& .iconCount": {
    color: '#FF0000',
    fontStyle:"normal",
    fontSize:"14px",
    fontWeight: 600,
    fontFamily:"Inter",
    "@media (max-width: 960px)": {
      fontSize: "8px",
      fontWeight: 600,
      color: "#0F172A"
    }
  },
  "& .likeIcon": {
    width: "20px !important",
    height: "20px !important"
  },
  "& .messageIcon":{
    width: "25px !important",
    height: "25px !important",
  },
  "& .starIcon": {
    width: "25px !important",
    height: "22px !important"
  },
  "@media (max-width: 950px)": {
    backgroundColor: "#f1f5f9"
  }
})

// Customizable Area End
