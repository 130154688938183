// Customizable Area Start
import React from "react";
import { createStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography, withStyles } from "@material-ui/core";
import { NormalizedFile, File } from "./types";
import { AddRounded } from "@material-ui/icons";
import { Swiper, SwiperSlide } from 'swiper/react';

type Props = {
  classes: any;
  videos: string;
  files: NormalizedFile[];
  selectFile: (fileIndex: number) => void;
  addFileButtonRef: React.RefObject<HTMLInputElement>;
  upload: string;
  addFiles: (file: File[]) => void
};

const styles = (theme: Theme) =>
  createStyles({
    postFilesQueue: {
      display: "flex",
      flexDirection: "column",
      paddingTop: "10px",
      gap: "10px",
      flexWrap: "wrap",
      borderTop: "1px solid #AEAEB2"
    },

    filesQueueTitle: {
      fontSize: "24px",
      fontWeight: 700,
      lineHeightStep: "32px",
      letterSpacing: "-0.12px",
    },

    filesContainer: {
      display: "flex",
      flexWrap: "nowrap",
      overflowX: "auto",
      gap: "30px",
    },
    swiper:{
      width: "500px",
      marginLeft: "0px",
      [theme.breakpoints.down(650)]: {
        width: "300px"
      },
    },
    fileItem: {
      position: "relative",
      width: "100px !important",
      height: "100px",
      borderRadius: "50%",
      border: "4px solid #FFC629",
      overflow: "hidden",

      "&:hover": {
        cursor: "pointer",
      },

      "& > *": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
    },

    thumbnailShade: {
      position: "absolute",
      top: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0,0,0,0.4)",
    },

    fileItemTitle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      fontSize: "16px",
      fontWeight: 700,
      color: "#FFF",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textAlign: "center",
    },

    addFileButton: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100px",
      height: "100px",
      borderRadius: "50%",
      backgroundColor: "#FFC629",
      cursor: "pointer",
      gap: "2px",
      "&:hover": {
        backgroundColor: "#eebe40",
      }
    },

    addFileText: {
      fontSize: "16px",
      fontWeight: 700,
    },

    iconContainer: {
      width: "25px",
      height: "25px",
      borderRadius: "9px",
      backgroundColor: "#FFF",
    },
  });

export const CreateEveryreelSettingsFilesQueue = ({
  classes,
  files,
  videos,
  selectFile,
  addFiles,
  addFileButtonRef,
  upload,
}: Props) => {
  return (
    <Box className={classes.postFilesQueue}>
      <Typography className={classes.filesQueueTitle}>{videos}</Typography>
      <Swiper spaceBetween={20} className={classes.swiper}>
        {Boolean(files.length) &&
            files.map((file, i) => (
              <SwiperSlide
                id={`fileItem-${i}`}
                key={String(i)}
                className={classes.fileItem}
                onClick={() => selectFile(i)}
                style={{
                  border: file.selected ? "4px solid #FFC629" : "none",
                }}
              >
                {file.type.includes("image") ? (
                  <img src={file.meta.preview} alt="image preview" />
                ) : (
                  <video>
                    <source src={file.meta.preview} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
                <Box className={classes.thumbnailShade}></Box>
                <Typography className={classes.fileItemTitle}>
                  {file.title}
                </Typography>
              </SwiperSlide>
            ))}
          <SwiperSlide style={{width: "100px"}}>
            <Box
            id="addFileButton"
            className={classes.addFileButton}
            onClick={() => addFileButtonRef.current?.click()}
          >
            <Box className={classes.iconContainer}>
              <AddRounded />
            </Box>
            <Typography className={classes.addFileText}>{upload}</Typography>
            <input
              hidden
              ref={addFileButtonRef}
              type="file"
              data-test-id="media-upload"
              accept="video/mp4"
              onChange={(e) => {
                if(e.currentTarget.files) {
                  const file = e.currentTarget.files[0];
                  const formattedFile = Object.assign(file, {
                    preview: URL.createObjectURL(file),
                  })
                  addFiles([formattedFile])
                }
              }}
            />
          </Box>
          </SwiperSlide>
      </Swiper>
    </Box>
  );
};

export default withStyles(styles)(CreateEveryreelSettingsFilesQueue);
// Customizable Area End
