// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import moment, { Moment } from "moment";
import { AnalyticsPages, EngagementViews } from "./enums";
import { getToken } from "../../../components/src/AuthService";
import {
  ChartData,
  ConnectedPlatform,
  EngagementData,
  EveryreelVideo,
  MostActiveUsers,
  MostEngagedPost,
  TabsData,
} from "./interfaces";
import { PageAnalyticsIcon } from "./assets";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

interface S {
  error: string;
  isLoading: boolean;
  currentPage: AnalyticsPages;
  currentEngagementView: string;
  txtInputValue: string;
  dataRange: {
    startDate: Moment;
    endDate: Moment;
  };
  engagementData: EngagementData[];
  chartData: ChartData;
  tabsData: TabsData;
  everyreelData: EveryreelVideo[];
  trendingPosts: MostEngagedPost[];
  postFeedClicks: { name: string; value: number }[];
  mostActiveUsers: MostActiveUsers[];
  connectedPlatforms: ConnectedPlatform[];
}

interface SS {
  id: any;
}

export default class AnalyticsController extends BlockComponent<Props, S, SS> {
  commonStatsApiId = "";
  getTrendingPostsApiId = "";
  getPostFeedClicksApiId = "";
  getMostActiveUsersApiId = "";
  getConnectedPlatformsApiId = "";
  getPollsApiId = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMobileData),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage)
    ];

    this.state = {
      error: "",
      isLoading: false,
      currentPage: AnalyticsPages.OVERVIEW,
      currentEngagementView: EngagementViews.TRENDING_POSTS,
      dataRange: {
        startDate: moment().subtract(28, "days"),
        endDate: moment(),
      },
      txtInputValue: "",
      engagementData: [],
      chartData: {},
      tabsData: {},
      everyreelData: [],
      trendingPosts: [],
      postFeedClicks: [],
      mostActiveUsers: [],
      connectedPlatforms: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId === this.commonStatsApiId) {
      this.handleCommonStatsAPIResponse(message);
    }

    if (apiRequestCallId === this.getTrendingPostsApiId) {
      this.handleTrendingPostsApiResponse(message);
    }

    if (apiRequestCallId === this.getPostFeedClicksApiId) {
      this.handlePostFeedClicksApiResponse(message);
    }

    if (apiRequestCallId === this.getMostActiveUsersApiId) {
      this.handleMostActiveUsersApiId(message);
    }

    if (apiRequestCallId === this.getConnectedPlatformsApiId) {
      this.handleConnectedPlatformsApiId(message);
    }
  }

  componentDidMount(): Promise<void> {
    if(window.innerWidth < 950){
      const msg = new Message(getName(MessageEnum.NavigationMobileData));
      msg.addData(getName(MessageEnum.NavigationMobileData), {backArrowFunction: this.handleRedirect, name: "Page Analytics", icon: PageAnalyticsIcon});
      this.send(msg);
    }
    return new Promise(() => {      
      this.getCommonStats();
    });
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ): void {
    if (
      prevState.dataRange.endDate.format("YYYY-MM-DD") !==
        this.getFormattedEndDate() ||
      prevState.dataRange.startDate.format("YYYY-MM-DD") !==
        this.getFormattedStartDate() ||
      prevState.currentPage !== this.state.currentPage
    ) {
      this.getCommonStats();
    }

    if (
      prevState.currentPage !== AnalyticsPages.ENGAGEMENT &&
      this.state.currentPage === AnalyticsPages.ENGAGEMENT
    ) {
      this.setState({ currentEngagementView: EngagementViews.TRENDING_POSTS });
    }

    this.checkIfTrendingPosts(prevState);
    this.checkIfFeedClicks(prevState);
    this.checkIfTextConversations(prevState);
    this.checkIfConnectedPlatforms(prevState);
    this.checkIfEverybrandPolls(prevState);
  }

  handleRedirect = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  checkIfTrendingPosts = (prevState: S) => {
    if (
      (prevState.currentPage !== AnalyticsPages.ENGAGEMENT &&
        this.state.currentPage === AnalyticsPages.ENGAGEMENT) ||
      (this.state.currentEngagementView === EngagementViews.TRENDING_POSTS &&
        prevState.currentEngagementView !== this.state.currentEngagementView)
    ) {
      this.getTrendingPosts();
    }
  };

  checkIfFeedClicks = (prevState: S) => {
    if (
      (prevState.currentPage !== AnalyticsPages.ENGAGEMENT &&
        this.state.currentPage === AnalyticsPages.ENGAGEMENT) ||
      (this.state.currentEngagementView === EngagementViews.FEED_CLICKS &&
        prevState.currentEngagementView !== this.state.currentEngagementView)
    ) {
      this.getFeedClicks();
    }
  };

  checkIfTextConversations = (prevState: S) => {
    if (
      (prevState.currentPage !== AnalyticsPages.ENGAGEMENT &&
        this.state.currentPage === AnalyticsPages.ENGAGEMENT) ||
      (this.state.currentEngagementView ===
        EngagementViews.TEXT_CONVERSATIONS &&
        prevState.currentEngagementView !== this.state.currentEngagementView)
    ) {
      this.getMostActiveUsers();
    }
  };

  checkIfConnectedPlatforms = (prevState: S) => {
    if (
      (prevState.currentPage !== AnalyticsPages.ENGAGEMENT &&
        this.state.currentPage === AnalyticsPages.ENGAGEMENT) ||
      (this.state.currentEngagementView ===
        EngagementViews.CONNECTED_PLATFORMS &&
        prevState.currentEngagementView !== this.state.currentEngagementView)
    ) {
      this.getConnectedPlatforms();
    }
  };

  checkIfEverybrandPolls = (prevState: S) => {
    if (
      (prevState.currentPage !== AnalyticsPages.ENGAGEMENT &&
        this.state.currentPage === AnalyticsPages.ENGAGEMENT) ||
      (this.state.currentEngagementView === EngagementViews.EVERYBRAND_POLLS &&
        prevState.currentEngagementView !== this.state.currentEngagementView)
    ) {
      this.getPolls();
    }
  };

  getFormattedStartDate = () => {
    return this.state.dataRange.startDate.format("YYYY-MM-DD");
  };

  getFormattedEndDate = () => {
    return this.state.dataRange.endDate.format("YYYY-MM-DD");
  };

  setCurrentPage = (currentPage: AnalyticsPages) => {
    this.setState({
      currentPage,
    });
  };

  setDataRange = (days: number) => {
    this.setState({
      dataRange: {
        startDate: moment().subtract(days, "days"),
        endDate: moment(),
      },
    });
  };

  setSelectedPlatform = (platformName: string) => {
    this.setState((prevState) => ({
      connectedPlatforms: prevState.connectedPlatforms.map((platform) => ({
        ...platform,
        selected: platform.platform_name === platformName,
      })),
    }));
  };

  changeEngagementCheck = (id: number) => {
    this.setState((prevState) => {
      return {
        engagementData: prevState.engagementData.map((engagement) => {
          if (engagement.id !== id) {
            return {
              ...engagement,
              checked: false,
            };
          } else {
            return {
              ...engagement,
              checked: true,
            };
          }
        }),
      };
    });
  };

  handleTabClick = (tabName: string) => {
    this.setState({ currentEngagementView: tabName.toUpperCase() });
  };

  getChartTitle = (rawTitle: string) => {
    return (
      rawTitle
        .toLowerCase()
        .split("_")
        .join(" ") || ""
    );
  };

  makeApiCall = async (endpoint: string) => {    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.dashboarContentType,
        token: getToken(),
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };

  getCommonStats = async () => {
    this.commonStatsApiId = await this.makeApiCall(
      this.getCommonStatsEnpoint()
    );
  };

  getCommonStatsEnpoint = () => {
    const stateStartDate = this.state.dataRange.startDate;
    const stateEndDate = this.state.dataRange.endDate;

    const requestStartDate = stateStartDate
      .clone()
      .subtract(stateEndDate.diff(stateStartDate, "days"), "days")
      .format("YYYY-MM-DD");
    const requestEndDate = stateEndDate.format("YYYY-MM-DD");

    let endpoint = "";

    switch (this.state.currentPage) {
      case AnalyticsPages.OVERVIEW:
        endpoint = configJSON.overviewAnalyticsEnpoint;
        break;

      case AnalyticsPages.ENGAGEMENT:
        endpoint = configJSON.engagementAnalyticsEnpoint;
        break;

      case AnalyticsPages.REVIEWS:
        endpoint = configJSON.reviewAnalyticsEndpoint;
        break;

      case AnalyticsPages.WAITLIST:
        endpoint = configJSON.waitlistAnalyticsEnpoint;
        break;

      case AnalyticsPages.EVERYREEL:
        endpoint = configJSON.everyreelAnalyticsEndpoint;
        break;

      case AnalyticsPages.LEADS:
        endpoint = configJSON.leadsAnalyticsEnpoint;
        break;
    }

    return (
      endpoint + `?start_date=${requestStartDate}&end_date=${requestEndDate}`
    );
  };

  getTrendingPosts = async () => {
    const endpoint = `${
      configJSON.engagementTrendingPostsEndpoint
    }?start_date=${this.getFormattedStartDate()}&end_date=${this.getFormattedEndDate()}`;

    this.getTrendingPostsApiId = await this.makeApiCall(endpoint);
  };

  getFeedClicks = async () => {
    const endpoint = `${
      configJSON.engagementPostFeedClicksEndpoint
    }?start_date=${this.getFormattedStartDate()}&end_date=${this.getFormattedEndDate()}`;

    this.getPostFeedClicksApiId = await this.makeApiCall(endpoint);
  };

  getMostActiveUsers = async () => {
    const endpint = `${
      configJSON.engagementTextConversationsEndpoint
    }?start_date=${this.getFormattedStartDate()}&end_date=${this.getFormattedEndDate()}`;

    this.getMostActiveUsersApiId = await this.makeApiCall(endpint);
  };

  getConnectedPlatforms = async () => {
    const endpoint = `${
      configJSON.engagementConnectedPlatformsEndpoint
    }?start_date=${this.getFormattedStartDate()}&end_date=${this.getFormattedEndDate()}`;

    this.getConnectedPlatformsApiId = await this.makeApiCall(endpoint);
  };

  getPolls = async () => {
    const endpoint = `${
      configJSON.engagementEverybrandPollsEndpoint
    }?start_date=${this.getFormattedStartDate()}&end_date=${this.getFormattedEndDate()}`;

    this.getPollsApiId = await this.makeApiCall(endpoint);
  };

  formatChartDate = (date: string) => {
    return moment(date).format("MMM D");
  };

  normalizeEngagementData = (chartData: any = {}) => {
    let normalizedChartData: {
      [key: string]: { name: string; value: number }[];
    } = {};
    let normalizedEngagementData: Array<{
      id: number;
      type: string;
      currentCount: number;
      pastCount: number;
      checked: boolean;
    }> = [];
    const startDate = this.state.dataRange.startDate;
    const engagementDataNames = Object.keys(chartData);

    let currentCount = 0;
    let pastCount = 0;

    engagementDataNames.forEach((dataName, i) => {
      currentCount = 0;
      pastCount = 0;
      normalizedChartData[dataName] = [];

      const engagementDataDates = Object.keys(chartData[dataName]);

      engagementDataDates.forEach((date) => {
        if (moment(date).diff(startDate, "days") >= 0) {
          normalizedChartData[dataName].push({
            name: this.formatChartDate(date),
            value: chartData[dataName][date],
          });

          currentCount += chartData[dataName][date];
        } else {
          pastCount += chartData[dataName][date];
        }
      });

      normalizedEngagementData.push({
        id: i,
        type: dataName,
        currentCount,
        pastCount,
        checked: i === 0,
      });
    });

    return { normalizedEngagementData, normalizedChartData };
  };

  handleCommonStatsAPIResponse = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (!responseJson.error || !responseJson.errors) {
      const {
        normalizedEngagementData,
        normalizedChartData,
      } = this.normalizeEngagementData(responseJson.chart_data);      

      this.setState({
        chartData: normalizedChartData,
        engagementData: normalizedEngagementData,
        tabsData: responseJson.tabs_data || {},
        everyreelData: responseJson?.everyreel_data?.results || [],
      });
    }
  };

  handleTrendingPostsApiResponse = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (!responseJson.error || !responseJson.errors) {
      this.setState({ trendingPosts: responseJson.most_engaged_posts });
    }
  };

  handlePostFeedClicksApiResponse = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (!(responseJson.error || responseJson.errors) && responseJson.data) {
      const normalizedFeedClicks = Object.keys(responseJson.data).map(
        (key) => ({
          name: this.formatChartDate(key),
          value: responseJson.data[key],
        })
      );

      this.setState({ postFeedClicks: normalizedFeedClicks });
    }
  };

  handleMostActiveUsersApiId = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      !(responseJson.error || responseJson.errors) &&
      responseJson.text_conversations
    ) {
      this.setState({
        mostActiveUsers: responseJson.text_conversations,
      });
    }
  };

  handleConnectedPlatformsApiId = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      !(responseJson.error || responseJson.errors) &&
      responseJson.connected_platforms
    ) {
      this.setState({
        connectedPlatforms: responseJson.connected_platforms.map(
          (platform: ConnectedPlatform, i: number) => ({
            ...platform,
            selected: i === 0,
          })
        ),
      });
    }
  };

  showAnalyticsTabs = (): boolean => {
    const { currentPage } = this.state;

    return (
      currentPage === AnalyticsPages.OVERVIEW ||
      currentPage === AnalyticsPages.EVERYREEL ||
      currentPage === AnalyticsPages.ENGAGEMENT ||
      currentPage === AnalyticsPages.REVIEWS
    );
  };

  showReelsStats = (): boolean => {
    const { currentPage } = this.state;

    return (
      currentPage === AnalyticsPages.OVERVIEW ||
      currentPage === AnalyticsPages.EVERYREEL
    );
  };

  showTrendingPosts = (): boolean => {
    return (
      this.state.currentPage === AnalyticsPages.ENGAGEMENT &&
      this.state.currentEngagementView === EngagementViews.TRENDING_POSTS
    );
  };

  showTextConversations = (): boolean => {
    return (
      this.state.currentPage === AnalyticsPages.ENGAGEMENT &&
      this.state.currentEngagementView === EngagementViews.TEXT_CONVERSATIONS
    );
  };

  showFeedClicks = (): boolean => {
    return (
      this.state.currentPage === AnalyticsPages.ENGAGEMENT &&
      this.state.currentEngagementView === EngagementViews.FEED_CLICKS
    );
  };

  showEverybrandPolls = (): boolean => {
    return (
      this.state.currentPage === AnalyticsPages.ENGAGEMENT &&
      this.state.currentEngagementView === EngagementViews.EVERYBRAND_POLLS
    );
  };

  showConnectedPlatforms = (): boolean => {
    return (
      this.state.currentPage === AnalyticsPages.ENGAGEMENT &&
      this.state.currentEngagementView === EngagementViews.CONNECTED_PLATFORMS
    );
  };
}
// Customizable Area End
