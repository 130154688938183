// Customizable Area Start
import React, { ChangeEvent } from "react";
import {
  Box,
  Button,
  Card,
  IconButton,
  TextField,
  Popover,
  ListItemText,
  MenuItem,
  Typography,
  Modal,
  Snackbar,
  Divider,
  CircularProgress,
  Menu,
  FormControl,
  Select,
} from "@material-ui/core";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import SmartphoneOutlinedIcon from '@material-ui/icons/SmartphoneOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MuiAlert from "@material-ui/lab/Alert";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { withStyles, createStyles, Theme, styled } from "@material-ui/core/styles";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import MessagesController from "./MessagesController.web";
import PersonIcon from "@material-ui/icons/Person";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CreateIcon from "@material-ui/icons/Create";
import {
  EverybrandIcon,
  aIcon,
  allMessagesIcon,
  camera,
  chatCircle,
  chatIcon,
  chatWhite,
  closeIcon,
  directPostCircle,
  directPostIcon,
  directWhite,
  document,
  folderIcon,
  group_plus,
  icon_info,
  search_icon,
  sendMessageIcon,
  textCircle,
  textIcon,
  textWhite,
  whiteFolder,
} from "./assets";
import { pinIcon } from "../../notifications/src/assets";
import { conversationType } from "./constants";
import { Formik } from "formik";
import * as yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { handleAndConditions } from "../../../components/src/CommonFunctions";
import { MessageOption } from "./MessageOptions.web";
import moment from "moment";
import LaptopIcon from '@material-ui/icons/Laptop';
import { addPlusIcon } from "../../settings2/src/assets";
import { backArrow, ChatNavIcon } from "../../dashboard/src/assets";
import CustomLoader from "../../../components/src/CustomLoader.web";

const webStyles = {
  phoneInput: {
    width: "100%",
    height: "56px",
    fontWeight: 400,
    fontSize: "12px !importent",
    color: "#64748B",
    // border:"1px solid #FFC629",
    margin: "10px 0",
  },
  phoneInput1: {
    width: "310px",
    height: "56px",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "16px !important",
  },
};

const StyledMenuItemFilter = styled(MenuItem)({
  "&:hover": {
    backgroundColor: "#000",
    color: "#FFF",
  },
  "&.Mui-selected": {
    backgroundColor: "#000 !important",
    color: "#FFF !important",
  },
  "&.Mui-selected:hover": {
    backgroundColor: "#000 !important",
    color: "#FFF !important",
  },
});

const validation_Schema_newcontact = yup.object({
  first_name: yup
    .string()
    .required("First Name is required")
    .matches(/^[a-zA-Z]+$/, "Only alphabets are allowed"),
  last_name: yup
    .string()
    .required("Last Name is required")
    .matches(/^[a-zA-Z]+$/, "Only alphabets are allowed"),
  email: yup.string().email("Invalid email address"),
  phone: yup
    .string()
    .required("Phone number is required")
    .min(10, "Must be at least 10 digits"),
});

export class Messages extends MessagesController {
  getProfilePic(url: any) {
    if (url) {
      return <img src={url} alt="" />;
    } else {
      return <PersonIcon style={{ height: "28px", width: "28px" }} />;
    }
  }
  
  getFirstName(firstName: any) {
    if (firstName) {
      return firstName;
    } else {
      return "Name";
    }
  }

  getFullName(firstName: string | null, lastName: string | null) {
    if (firstName) {
      return lastName ? firstName + " " + lastName : firstName;
    } else {
      return "Name";
    }
  }

  getFormattedNumber(userPhone: string) {
    if(userPhone){
      return (
        userPhone.slice(0, 1) +
        userPhone.slice(1, 4) +
        "-" +
        userPhone.slice(4, 7) +
        "-" +
        userPhone.slice(7)
      );
    }
    return "-"
    
  }

  getLatestText(text: any) {
    if (text) {
      let result = text.toString().substring(0, 15);
      if (text.length > 10) {
        result = result + "...";
        if (text.length > 10) {
          result = result + "...";
        }
        return result;
      } else {
        return "Message";
      }
    }
  }
  getDateForSorting(date: any) {
    if (date) {
      if (date.includes('pinned')) {
        return "Pinned";
      }
      const currentDate = new Date();
      const inputDate = new Date(date);
      const isToday = currentDate.toDateString() === inputDate.toDateString();
      const isYesterday = new Date(currentDate.setDate(currentDate.getDate() - 1)).toDateString() === inputDate.toDateString();
  
      if (isToday) {
        return "Today";
      }
      if (isYesterday) {
        return "Yesterday";
      }
  
      return inputDate.toLocaleDateString();
    } else {
      return "";
    }
  }

  getDate(date: any) {
    if (date) {
      return new Date(date).toLocaleDateString();
    } else {
      return "";
    }
  }
  

  getCurrentTime() {
    let date = new Date();
    let hours = date.getHours();
    let minutes: any = date.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let timeString = hours + ":" + minutes + " " + ampm;
    return timeString;
  }

  getStyle(type: string) {
    if (this.state.chatType === type) {
      return { background: "black", color: "white" };
    }
  }
  
  getStyleForImage(type: string){
    if(this.state.chatType === type){
      return {filter: "invert(100%) brightness(10)"}
    }
  }

  getIcon(type: string) {
    if (this.state.chatType === type) {
      return true;
    } else {
      return false;
    }
  }

  renderContacts(classes: any, contacts: any) {
    return (
      <>
        {contacts.map((contact: any) => {
          return (
            <div
              data-test-id="contactButton"
              className={classes.singleConversation}
              key={contact.id}
              onClick={() => this.hanldeUserContact(contact)}
            >
              <div className={classes.conversationContainer}>
                <div className={classes.conversationImage}>
                  {this.getProfilePic(contact.attributes?.profile_pic_url)}
                </div>
                <div className={classes.conversationDetails}>
                  <div className={classes.nameText}>
                    {this.getFirstName(contact.attributes?.first_name) +
                      " " +
                      this.getFirstName(contact.attributes?.last_name)}
                  </div>
                  <div className={classes.msgTxt}>
                    {this.getLatestText(contact.attributes?.phone_number)}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  }
  getTextCircle(type: string) {
    if (type === "SmsContact") {
      return <img style={{ paddingBottom: "10px" }} src={textCircle} alt="" />;
    }
    if (type === "EmailAccount") {
      return (
        <img style={{ paddingBottom: "10px" }} src={directPostCircle} alt="" />
      );
    }
    if (type === "ChatContact") {
      return <img style={{ paddingBottom: "10px" }} src={chatCircle} alt="" />;
    }
  }
  renderCoversations(classes: any) {
    
    return (
      <>
        {this.state.conversationNewData.map((item: any, index: number) => {
          return <div key={index} data-test-id="converstaionDiv">
            {item.contacts?.length > 0  && <div className={classes.flexSB}>
              <span className={classes.filterText}>
                {this.getDateForSorting(item.date)}
              </span>
              <hr className={classes.dateDivider} />
            </div>}
            {item.contacts?.map((contact: any) => {
              return <div
              data-test-id="converstaionButton"
              className={classes.singleConversation}
              style={this.state.selectedConversation.id === contact.id ? { backgroundColor: "rgba(217,217,217,0.2)", borderRadius: "6px" }: { }}
              key={contact.id}
              onClick={() => {this.getConversation(contact)}}
            >
              <div className={classes.conversationContainer}>
                <div className={classes.conversationImage}>
                  {this.getProfilePic(contact.attributes?.profile_pic_url)}
                </div>
                <div className={classes.conversationDetails}>
                  <div className={classes.nameText}>
                    {this.getFirstName(contact.attributes?.first_name)}
                  </div>
                  <div className={classes.msgTxt}>
                    {this.getLatestText(contact.attributes?.last_message)}
                  </div>
                  <div className={classes.dateText}>
                    {this.getDate(contact.attributes?.last_interaction_date)}
                  </div>
                </div>
              </div>
              <div>
                {contact.attributes.pin_conversation === true && (
                  <img
                    src={pinIcon}
                    alt="priorityIcon"
                    style={{ height: "18px", width: "18px" }}
                  />
                )}
                {this.getTextCircle(contact.attributes.type)}
                <IconButton
                  data-test-id="iconBtnPopover"
                  className={classes.postSettingsBtn}
                  onClick={(e) => this.handleMessageOptions(e, contact)}
                >
                  <MoreVertIcon />
                </IconButton>
                {this.state.messageOptionsPopover && (
                  <MessageOption
                    selectedMessage={this.state.selectedConversationForPopover}
                    folders={this.state.userFolders}
                    closePopover={this.handleMessagePopoverClose}
                    classes={classes}
                    isPopoverVisible={this.state.messageOptionsPopover}
                    callBackAfterAPI={this.getUserChats}
                    handleReply={this.handleMessagePopoverReply}
                    handleApiResponse={this.handleOptionsApiResponse}
                    handleClosePopover={this.handleClosePopover}
                  />
                )}
              </div>
            </div>
            })}
          </div>
          
        })}
      </>
    );
  }

  render() {
    const open = Boolean(this.state.anchorEll);

    const { classes }: any = this.props;

    const automationOpen = Boolean(this.state.automationModal);

    const renderAddContact = () => {
      return <Box
      className={classes.newContectModalBox}
      data-test-id="newContactModalMainBox"
    >
      <Formik
        initialValues={{
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
        }}
        validationSchema={validation_Schema_newcontact}
        onSubmit={(values) => {
          this.addContacts(values);
        }}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          values,
          handleSubmit,
        }) => (
          <>
            <Typography className={classes.addnewconH}>
              Add Contact
            </Typography>
            <Box>
              <Typography className={classes.inputLable}>
                First Name*
              </Typography>
              <TextField
                variant="outlined"
                className={classes.addNewContectInput}
                name="first_name"
                data-test-id="secondStepInput"
                placeholder="Add Your Name"
                value={values.first_name}
                onChange={handleChange("first_name")}
                onBlur={handleBlur("first_name")}
              />
              {handleAndConditions(
                touched.first_name,
                errors.first_name,
                <Typography className={classes.errorMsg}>
                  {errors.first_name}
                </Typography>
              )}
              <Typography className={classes.inputLable}>
                Last Name*
              </Typography>
              <TextField
                variant="outlined"
                className={classes.addNewContectInput}
                name="last_name"
                data-test-id="secondStepInput"
                placeholder="Add Your Name"
                value={values.last_name}
                onChange={handleChange("last_name")}
                onBlur={handleBlur("last_name")}
              />
              {handleAndConditions(
                touched.last_name,
                errors.last_name,
                <Typography className={classes.errorMsg}>
                  {errors.last_name}
                </Typography>
              )}
              <Typography className={classes.inputLable}>
                Email
              </Typography>
              <TextField
                variant="outlined"
                className={classes.addNewContectInput}
                name="email"
                data-test-id="secondStepInput"
                placeholder="Add Your Name"
                value={values.email}
                onChange={handleChange("email")}
                onBlur={handleBlur("email")}
              />
              {handleAndConditions(
                touched.email,
                errors.email,
                <Typography className={classes.errorMsg}>
                  {errors.email}
                </Typography>
              )}
              <Typography className={classes.inputLable}>
                Mobile Number*
              </Typography>
              <Box style={{ padding: "5px 0" }}>
                <PhoneInput
                  data-test-id="phone"
                  country={"us"}
                  value={values.phone}
                  onChange={handleChange("phone")}
                  onBlur={handleBlur("phone")}
                  placeholder="Enter Phone Number"
                  inputStyle={webStyles.phoneInput}
                  specialLabel={""}
                />
              </Box>
              {handleAndConditions(
                touched.phone,
                errors.phone,
                <Typography className={classes.errorMsg}>
                  {errors.phone}
                </Typography>
              )}
              <Button
                onClick={() => handleSubmit()}
                className={classes.addBtn}
                variant="contained"
                data-test-id="addContact"
              >
                ADD CONTACT
              </Button>
              <Button
                onClick={this.closeNewContactModal}
                className={classes.backBtn}
                variant="contained"
              >
                Back
              </Button>
            </Box>
          </>
        )}
      </Formik>
    </Box>
    }

    const renderCameraDocAuto = () => {
      return <Box className={classes.docCameraAutoContainer}>
      <img src={document} alt="" style={{ marginLeft: "39px" }} />
      <label
        htmlFor="fileAttachments"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <img
          src={camera}
          alt=""
          style={{ marginLeft: "11px", cursor: "pointer", width:'24px' }}
        />
      </label>
      <input
        data-test-id="fileAttachments"
        id="fileAttachments"
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={this.handleCameraClick}
      />
      <img
        src={aIcon}
        alt=""
        style={{ marginLeft: "11px", cursor: "pointer" }}
        data-test-id="automationBtn"
        onClick={(e) => this.handleAutomationClick(e)}
      />
    </Box>
    }

    const renderAddNewMessageModal = () => {
      return (
        <Box className={classes.addNewMessageContainer}>
          <Box className={classes.flexSB}>
            <Typography className={classes.fromText}>
              New message from:{" "}
              <br className={classes.breakOnMobile}/>
              <p className={classes.businessNameText}>
                {this.state.userDetails.attributes?.first_name +
                " " +
                this.state.userDetails.attributes?.last_name}
              </p>
            </Typography>{" "}
            <IconButton onClick={this.closeNewMssage} className={classes.crossIcon}>
              <img src={closeIcon} alt="" />
            </IconButton>
          </Box>
          <hr className={classes.messageDivider} />
          <Typography className={classes.infoText1}>
            Send to :
          </Typography>
          <TextField
            onChange={(e) => this.getContacts(e.target.value)}
            className={classes.inputFieldMessages}
            InputProps={{
              classes: { input: classes.inputName },
            }}
            placeholder="First and Last Name"
            fullWidth
            data-test-id="firstNameInput"
            value={this.state.selectedContact?.attributes?.first_name}
          />
          {this.state.showContcatCard ? (
            <Card className={classes.contactsCard}>
              {this.renderContacts(classes, this.state.userContacts)}
            </Card>
          ) : null}
          <TextField
            className={clsx(classes.inputFieldMessages, classes.phoneFieldMessage)}
            InputProps={{
              classes: { input: classes.input },
            }}
            placeholder="Mobile Number"
            fullWidth
            value={this.state.selectedContact?.attributes?.phone_number}
          />
          <Box className={classes.countryPhoneNumber}>
           <PhoneInput
              country={"us"}
              value={this.state.selectedContact?.attributes?.phone_number}
              specialLabel={""}
              data-test-id="phoneInput"
              inputProps={{
                id: "fullPhoneNumber",
                name: "fullPhoneNumber",
                ["data-test-id"]: "phoneInput",
              }}
              inputStyle={webStyles.phoneInput1}
            />

          </Box>
          <div className={classes.newContact} style={Object.keys(this.state.selectedContact).length !== 0 ? {display: 'none'}: {}}>
            {" "}
            <Button
              data-test-id="contactModalBoxName"
              className={classes.flexSB}
              style={{ width: "97%", position: "relative" }}
              onClick={this.openNewContactModal}
            >
              <Box
                data-test-id="contactModalClick"
                className={classes.infoText3}
              >
                <img src={group_plus} alt="" />
                <Typography style={{fontWeight: 600, textTransform: "none"}}>New Contact</Typography>
                <div className={classes.itagBtn}>
                  <img src={icon_info} alt="" />
                </div>
              </Box>
            </Button>
          </div>
          <Button
            id="submit"
            variant="contained"
            type="submit"
            disableElevation
            className={classes.addContactButton}
            data-test-id="addContactButton"
          >Continue
          </Button>
        </Box>
      )
    }

    const renderLeftSide = () => {
      return <Box className={classes.leftBox}>
      <div>
      <Box>
        <Typography
          className={classes.infoText2}
          style={{ ...this.getStyle(conversationType[0])}}
          data-test-id="allMsgBtn"
          onClick={() => this.handleMessageCategory(conversationType[0], allMessagesIcon )}
        >
          <div style={{ display: "flex", marginRight: "50px" }}>
            <img className={classes.imageIcons} style={{...this.getStyleForImage(conversationType[0])}} src={ ChatNavIcon } alt="" />{" "}
            ALL MESSAGES{" "}
          </div>
        </Typography>
        <Typography className={classes.infoText2} data-test-id="textBtn" style={{ ...this.getStyle(conversationType[1])}}
          onClick={() => this.handleMessageCategory(conversationType[1], textCircle)} 
          >
          <div style={{ display: "flex", marginRight: "50px" }}>
            <img className={classes.imageIcons} src={ textIcon } alt="" style={{...this.getStyleForImage(conversationType[1])}} />{" "}
            Text{" "}
          </div>
          <img src={textCircle} alt="" style={{ marginRight: "16px" }} />
        </Typography>
        <Typography
          className={classes.infoText2}
          data-test-id="chatBtn"
          style={{ ...this.getStyle(conversationType[2])}}
          onClick={() => this.handleMessageCategory(conversationType[2], chatCircle)}
        >
          <div style={{ display: "flex", marginRight: "50px",gap:"10px" }}>
            <LaptopIcon />
            Chat{" "}
          </div>
          <img src={chatCircle} alt="" style={{ marginRight: "18px" }} />
        </Typography>
        <Typography
          className={classes.infoText2}
          data-test-id="directPostBtn"
          style={{ ...this.getStyle(conversationType[3])}}
          onClick={() => this.handleMessageCategory(conversationType[3], directPostCircle)}
        >
          <div style={{ display: "flex" }}>
            <img
              className={classes.imageIcons}
              src={directPostIcon}
              alt=""
              style={{...this.getStyleForImage(conversationType[3])}}
            />{" "}
            Direct Post{" "}
          </div>
          <img
            style={{ marginRight: "19px" }}
            src={directPostCircle}
            alt=""
          />
        </Typography>
      </Box>
      <Box className={classes.folderContainer}>
        <Typography
          className={classes.infoText1}
          style={{ marginLeft: "17px", paddingTop: 0, paddingBottom: "10px" }}
        >
          ASSIGNED FOLDER
        </Typography>
        <div className={classes.folerAndFolderCreateButtonContainer}>
          <Box className={classes.scrollFolderBox}>
            {this.state.userFolders.map((folder: any) => {
              return (
                <Typography
                  className={classes.infoTextFolder}
                  key={folder.id}
                >
                  <div style={{ display: "flex" }}>
                    <img
                      className={classes.imageIcons}
                      src={folderIcon}
                      alt=""
                    />{" "}
                    {folder.attributes?.name}
                  </div>
                  <IconButton
                    className={classes.postSettingsBtn}
                    style={{ marginLeft: "4px" }}
                    onClick={(e) => this.handleClickPopOver(e, folder)}
                    data-test-id="threeDotIconfolders"
                    aria-label="post-settings"
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Popover
                    id="simple-popover"
                    open={open}
                    anchorEl={this.state.anchorEll}
                    onClose={this.handleClosePopOver}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "center",
                      horizontal: "right",
                    }}
                    classes={{
                      paper: classes.popOverPaper,
                    }}
                  >
                    <StyledMenuItem
                      className={classes.folderList}
                      data-test-id="editFolder"
                    >
                      <ListItemText
                        style={{ paddingLeft: "40px" }}
                        primary={
                          <span
                            style={{
                              fontFamily: "Inter",
                              textAlign: "center",
                            }}
                          >
                            Edit
                          </span>
                        }
                      />
                    </StyledMenuItem>
                    <StyledMenuItem
                      data-test-id="deleteFolderBtn"
                      className={classes.folderList}
                      onClick={() => {
                        this.handleDeleteFolder();
                      }}
                    >
                      <ListItemText
                        style={{ paddingLeft: "40px" }}
                        primary={
                          <span style={{ fontFamily: "Inter" }}>
                            Delete
                          </span>
                        }
                      />
                    </StyledMenuItem>
                    <StyledMenuItem
                      className={classes.folderList}
                      data-test-id="reassignBtn"
                      onClick={() => {
                        this.openModal();
                      }}
                    >
                      <ListItemText
                        style={{ paddingLeft: "40px" }}
                        primary={
                          <span style={{ fontFamily: "Inter" }}>
                            Re-assign
                          </span>
                        }
                      />
                    </StyledMenuItem>
                  </Popover>
                </Typography>
              );
            })}
          </Box>
          <div className={classes.btnContainer}>
            <Button
              variant="contained"
              data-test-id="createFolderBtn"
              className={classes.createFolderBtn}
              onClick={() => this.createUserFolder()}
            >
              <img
                className={classes.imageIcons}
                style={{ paddingRight: "15px" }}
                src={whiteFolder}
                alt=""
              />{" "}
              <span
                style={{
                  paddingBottom: "5px",
                  paddingTop: "5px",
                  fontSize: "15px",
                }}
              >
                Create Folder
              </span>
            </Button>
          </div>
        </div>
      </Box>
      </div>
      <div className={classes.bottomleft}>
        <Link to="/settings">
          <Button
            startIcon={
              <SettingsOutlinedIcon className={classes.settingsIcon} />
            }
            fullWidth
            className={clsx(
              classes.buttonSettings,
              classes.textCapitalize
            )}
          >
            Settings
          </Button>
        </Link>
        <Typography className={classes.everyBrandLogo} gutterBottom>
          <img src={EverybrandIcon} alt="" />
        </Typography>
      </div>
    </Box>
    }

    const renderCenterSide = () => {
      return <Box className={classes.centerBox}>
      <Card className={classes.messageCard}>
        <div className={classes.flexSB} style={{ alignItems: "center" }}>
          {" "}
          <input
            data-test-id="meassgesSearch"
            placeholder=" Search"
            name="searchMessage"
            className={classes.searchField}
            value={this.state.messageSearchQuery}
            onChange={this.handleSearch}
          />{" "}
          <IconButton
            data-test-id="newMessageBtn"
            className={classes.addNewIconButton}
            style={{padding: 0}}
            onClick={() => {
              this.handleNewMessage();
            }}
          >
            <CreateIcon fontSize="medium" />
          </IconButton>
          {<Box className={classes.addNewChatMobileButton}>
            <img src={addPlusIcon} alt="" className={classes.yellowPlusIcon} 
              onClick={() => { this.handleNewMessage() }}/>
          </Box>}
        </div>
        <div className={classes.chatContainer}>
          <Typography className={classes.headingText}>
            {this.state.chatType}{" "}
            <img src={this.state.chatCircle} alt="" />
          </Typography>
          <div className={classes.filterBox}>
            {" "}
            <span className={classes.filterText}>Filter by:</span>{" "}
            <Box className={classes.filterInputModalBase}>
              <FormControl size="small" style={{width: "100%"}}>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={this.state.selectedFilterName}
                  onChange={(e: any) => {this.setState({selectedFilterName: e.target.value})}}
                  variant="outlined"
                >
                  <MenuItem value={"All Messages"} className={classes.filterMenuItem}>All Messages</MenuItem>
                  <MenuItem value={"Unread"} className={classes.filterMenuItem}>Unread</MenuItem>
                  <MenuItem value={"Read"} className={classes.filterMenuItem}>Read</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
          <div />
          <div
            className={classes.allConsversatitonBox}
            data-test-id="allConsversatitonBox"
          >
          <CustomLoader wrapperHeight="20vh" isLoading={this.state.isLoaderShow}>
              {this.renderCoversations(classes)}
          </CustomLoader>
          </div>
        </div>
      </Card>
    </Box>
    }

    const renderRightSide = () => {
      return <Box className={classes.rightBox}
      data-test-id="messageContainterRightBox"
    >
      <Card className={classes.messageCardRight}>
        <div style={{ height: '90%', overflow: 'scroll'}}>
          {this.state.isNewMessage && renderAddNewMessageModal()}
          {!this.state.isNewMessage && (
            <div>
              {
                this.state.newOrOldUserSelected ? <div className={classes.messageScreenUserOneTime}>
                  <Box className={classes.flexSB}>
                    <Typography className={classes.infoText1}>
                      New message from:{" "}
                      {this.state.userDetails.attributes?.first_name +
                        " " +
                        this.state.userDetails.attributes?.last_name}
                    </Typography>{" "}
                  </Box>
                  <hr className={classes.messageDivider} />
                  <Typography className={classes.infoText1}>
                    Send to :
                  </Typography>
                  <Typography style={{ fontSize: "20px", fontWeight: "bold", marginTop: "20px"}}>
                    {this.state.selectedConversation.attributes.first_name + " " + this.state.selectedConversation.attributes.last_name}
                  </Typography>
                  <hr className={classes.messageDivider} style={{marginTop: '0px', marginBottom: "20px"}}/>
                  <Typography style={{fontSize: "20px", fontWeight: 400}}>{this.state.selectedConversation.attributes.phone_number }</Typography>
                  <hr className={classes.messageDivider} style={{marginTop: '0px'}}/>
                </div> : <div>
                  <div className={classes.imageTop2}>
                    <div className={classes.imageLine}></div>
                    <Divider />
                    <div className={classes.conversationImage}>
                      {this.getProfilePic(
                        this.state.selectedConversation?.attributes
                          ?.profile_pic_url
                      )}
                    </div>

                    <div className={classes.imageLine}></div>
                  </div>
                <div className={classes.imageTop}>
                  <div className={classes.nameBox}>
                    {this.getFirstName(
                      this.state.selectedConversation?.attributes?.first_name
                    )}{" "}
                    <span className={classes.onlineIcon} />
                    {<Box data-test-id="infoModalArrowBtn" onClick={()  => this.handleInfoModalChange()}>{!this.state.openInfoModal ? <ArrowForwardIosRoundedIcon
                      className={classes.profileArrow}
                    /> :
                    <KeyboardArrowDownIcon style={{fontSize: "28px"}} className={classes.profileArrow}/>}</Box>}
                  </div>
                  <div
                    className={classes.dateText}
                    style={{ marginTop: "10px" }}
                  >
                    {this.formatDateTime(this.state.selectedConversation?.attributes?.last_interaction_date)}
                  </div>
                  {
                    this.state.openInfoModal && (
                      <>
                        <Box className={classes.infoContainer}>
                          <Box className={classes.infoSubContainer}>
                            <Box className={classes.infoLogoContainer}>
                            <PersonOutlineIcon />
                            </Box>
                            <Typography>
                              {this.getFullName(
                                this.state.selectedConversation?.attributes?.first_name, this.state.selectedConversation?.attributes?.last_name
                              )}
                            </Typography>
                          </Box>
                          <Box className={classes.infoSubContainer}>
                            <Box className={classes.infoLogoContainer}>
                          <SmartphoneOutlinedIcon />
                          </Box>
                            <Typography>
                              {this.state.selectedConversation?.attributes?.formated_phone_number}
                            </Typography>
                          </Box>
                          <Box className={classes.infoSubContainer}>
                            <Box className={classes.infoLogoContainer}>
                          <EmailOutlinedIcon />
                          </Box>
                            <Typography>
                              {this.state.selectedConversation?.attributes?.email || "-"}
                            </Typography>
                          </Box>
                        </Box>
                        <div style={{width: "100%", marginTop: "30px"}}className={classes.imageLine}></div>
                      </>
                    )
                  }
                </div>
              </div>
              }
              <div>
              {!this.state.isLoading && this.state.chatSelectedContact.length > 0 && this.state.chatSelectedContact.map((chat: any, index: any) => (
                <div key={index}>
                  <div className={classes.chatDetailBox}>
                    {this.state.selectedConversation.attributes.id != chat.account?.data?.id &&
                      <div className={classes.othersChat}>
                        <div className={classes.singleConversation1}>
                          <div className={classes.imageOuter}>
                            <div className={classes.conversationImage1}>
                              {this.getProfilePic(chat.account.data.attributes.profile_pic_url)}
                            </div>
                          </div>
                          <div className={classes.singleConversation1Text}>
                            {chat.body}
                          </div>
                        </div>
                        <div className={classes.delieveredTime}>
                          {chat.account?.data?.attributes?.first_name}{" "}
                          {moment(chat.created_at).format('hh:mm A')}
                        </div>
                      </div>
                    }
                    {this.state.selectedConversation.attributes.id == chat.account?.data?.id  && 
                      <div className={classes.myChat}>
                        <div className={classes.singleConversation1myChat}>
                          <div className={classes.singleConversation1TextmyChat}>
                            {chat.body}
                          </div>
                          <div className={classes.imageOuter}>
                            <div className={classes.conversationImage1}>
                              {this.getProfilePic(chat.account.data.attributes.profile_pic_url)}
                            </div>
                          </div>
                        </div>
                        <div className={classes.delieveredTime}>
                          You {moment(chat.created_at).format('hh:mm A')}
                        </div>
                      </div>
                    }
                  </div>
                </div>
              ))}
              {this.state.isLoading && 
                <div style={{display: 'flex',justifyContent: 'center',alignItems: 'center',height: '400px',}}>
                  <CircularProgress />
                </div>
              }
              </div>
            </div>
          )}
        </div>
        <div className={classes.bottomRight} style={this.state.isDocCameraAutoVisible ? {bottom: "56px"}: {bottom: "36px"}}>
          <hr className={classes.messageDivider2} />
          <div className={classes.sendMessage}>
            <PersonIcon className={classes.meUserIcon} />{" "}
            <img src={backArrow} 
              className={classes.showMoreFeature} 
              alt="" 
              onClick={() => this.setState({isDocCameraAutoVisible: !this.state.isDocCameraAutoVisible})}
              style={this.state.isDocCameraAutoVisible ? {transform: "rotate(270deg)"} : {transform: "rotate(180deg)"}}
              />
            <input
              placeholder="Type your message"
              className={classes.messageField}
              data-test-id="newMsgInput"
              value={this.state.newMessage}
              onChange={(e: any) =>
                this.setState({ newMessage: e.target.value })
              }
            />
            <Box className={classes.docCameraAutoContainerDesktop}>
              {renderCameraDocAuto()}
            </Box>
            <button
              data-test-id="sendNewMsgBtn"
              className={classes.sendButton}
              onClick={() => this.sendNewMessage()}
            >
              Send
            </button>
            <img src={sendMessageIcon} alt="" className={classes.sendMessageIcon} onClick={() => this.sendNewMessage()}/>
          </div>
          <Box className={classes.docCameraAutoContainerMobile} style={this.state.isDocCameraAutoVisible ? {display: "flex"}: {display: "none"}}>
            {renderCameraDocAuto()}
          </Box>
        </div>
      </Card>
    </Box>
    }

    const renderForMobileView = () => {
      return <Box className={classes.messageContainerMobile}>
        {this.state.isLeftSideVisibleForMobileview && renderLeftSide()}
        {this.state.isCenterSideVisibleForMobileView && renderCenterSide()}
        {this.state.isRightSideVisibleForMobileView && renderRightSide()}
        {this.state.addNewMessageMobile && renderAddNewMessageModal()}
        {this.state.createNewContactMobile && renderAddContact()}
      </Box>
    }

    return (
      <>
        <Snackbar
          open={this.state.showAlert}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={this.state.errorAlertMessage ? "error" : "success"}
          >
            {this.state.showSuccess}
          </MuiAlert>
        </Snackbar>
        <Modal open={this.state.teammatesModal}
          onClose={this.closeModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          data-test-id="webUpdateModal"
        >
          <div className={classes.teammadeModalBox}>
            <div>
              <div className={classes.flexSB}>
                <Typography className={classes.modalHeading}>
                  Teammates
                </Typography>
                <IconButton
                  onClick={this.closeModal}
                  className={classes.crossIconTbn}
                >
                  <img src={closeIcon} alt="" />
                </IconButton>
              </div>
              <hr className={classes.messageDivider} />
              <input
                placeholder=" Search"
                style={{ fontFamily: "Inter" }}
                className={classes.searchFieldTeammate}
              />
              <div className={classes.teammateBox}>
                {this.state.teammates.map((teammate: any) => {
                  return (
                    <div
                      data-test-id="teammateBtn"
                      className={classes.singleTemmate}
                      key={teammate.id}
                      onClick={() => {
                        this.assignFolderToTeamate(teammate);
                      }}
                    >
                      <div className={classes.teamMateImage}>
                        {this.getProfilePic(teammate.attributes?.profile_photo)}
                      </div>
                      <div className={classes.text}>
                        {this.getFirstName(teammate.attributes?.name)}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={classes.modalBtn}>
              <button className={classes.cancelBtn}> Cancel</button>
              <span style={{ marginLeft: "20px" }}>
                <button className={classes.acceptBtn}> Accept</button>
              </span>
            </div>
          </div>
        </Modal>
        <div className={classes.messageContainter}
          data-test-id="messageContainter"
        >
          {renderLeftSide()}
          {renderCenterSide()}
          {renderRightSide()}
        </div>
        {renderForMobileView()}
        <Popover open={automationOpen}
          id="simple-popover"
          anchorEl={this.state.automationModal}
          onClose={this.handleAutomationPopOver}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          classes={{
            paper: classes.popOverPaper,
          }}
        >
          <Box className={classes.automationModal} data-test-id="automationBox">
            <Box className={classes.automationSearchBox}>
              <input
                data-test-id="automationSearchInput"
                placeholder="Search"
                name="automationSearchI"
                style={{ fontFamily: "Inter" }}
                className={classes.searchFieldAutomation}
                value={this.state.automationSearchQuery}
                onChange={(e: any) => this.handleAutomationSearch(e)}
              />
            </Box>

            <Box className={classes.automationBottomBox}>
              {this.state.autoamtionFilterData.length ? (
                this.state.autoamtionFilterData.map((item: any) => (
                  <Box
                    data-test-id="automationClick"
                    onClick={() => this.handleAutomationItemClick(item)}
                    key={item.name}
                    className={classes.automationListBox}
                  >
                    <Typography className={classes.automationLtext}>
                      {item.name}
                    </Typography>
                    <Typography className={classes.automationRtext}>
                      {item.type}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography align="center" className={classes.automationLtext}>
                  No Message Found!
                </Typography>
              )}
            </Box>
          </Box>
        </Popover>
        <Modal open={this.state.newContactModal}
          onClose={this.closeNewContactModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          data-test-id="newContactModal"
          className={classes.addContactModalStyle}
        >
          {renderAddContact()}
        </Modal>
      </>
    );
  }
}

export const commonStyles: any = {
  text: {
    fontSize: "16px",
    fontFamily: "Inter",
    fontWeight: 600,
  },
};

const styles = (theme: Theme) =>
  createStyles({
    addContactModalStyle:{
      [theme.breakpoints.down(950)]: {
        display: "none",
      },
    },
    outerContainer:{
      position: "relative"
    },
    scrollFolderBox: {
      height: "200px",
      overflowY: "auto",
      [theme.breakpoints.down(950)]: {
        height: "unset"
      },
    },
    itagBtn: {
      position: "absolute",
      top: "6px",
      bottom: "0",
      right: "8px",
      [theme.breakpoints.down(950)]: {
        position: "unset",
        marginRight: "12px"
      },
    },
    crossIconTbn: {
      position: "absolute",
      top: "15px",
      right: "25px",
    },
    addBtn: {
      width: "100%",
      backgroundColor: "#FFC629",
      color: "#000",
      fontSize: "16px",
      fontWeight: 600,
      padding: "10px 0",
      margin: "10px 0",
      "&:hover": {
        backgroundColor: "#FFC629",
        color: "#000",
      },
    },
    backBtn: {
      width: "100%",
      backgroundColor: "#000",
      color: "#ffffff",
      fontSize: "16px",
      fontWeight: 600,
      padding: "10px 0",
      margin: "10px 0",
      "&:hover": {
        backgroundColor: "#000",
        color: "#ffffff",
      },
      [theme.breakpoints.down(950)]: {
        display: "none"
      },
    },
    inputLable: {
      fontSize: "16px",
      lineHeight: "22px",
      color: "#334155",
      paddingTop: "5px",
    },
    addnewconH: {
      fontSize: "24px",
      fontWeight: 600,
      lineHeight: "32px",
    },
    errorMsg: {
      color: "red",
      fontSize: "12px",
      paddingLeft: "2px",
    },
    addNewContectInput: {
      width: "100%",
      margin: "5px 0",
      [theme.breakpoints.down(950)]: {
        "& .MuiOutlinedInput-root":{
          borderRadius: "8px"
        }
      },
    },
    newContectModalBox: {
      position: "relative",
      backgroundColor: "#fff",
      padding: "40px",
      width: "30%",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "0px 0px 24px 0",
      minWidth: "380px",
      [theme.breakpoints.down(950)]: {
        width: "100%",
        top: "0",
        left: "0",
        transform: "none",
        padding: "56px 24px",
        borderRadius: "0",
        overflowY: "scroll",
        minWidth: "340px",
        height: "100vh",
        paddingBottom: "120px"
      },
    },
    automationListBox: {
      padding: "5px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      "&:hover": {
        backgroundColor: "rgba(217, 217, 217, 0.2)",
        cursor: "pointer",
      },
    },
    automationLtext: {
      color: "#64748B",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "36px",
    },
    automationRtext: {
      color: "#64748B",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "36px",
    },
    automationBottomBox: {
      padding: "20px",
    },
    automationModal: {
      width: "450px",
      padding: "10px",
      borderRadius: "12px 12px 0px 12px",
    },
    cancelBtn: {
      background: "#000000",
      height: "44px",
      width: "87px",
      color: "white",
      borderRadius: "8px",
      borderStyle: "none",
      fontFamily: "Inter",
      fontWeight: 700
    },
    acceptBtn: {
      background: "#FFC629",
      height: "44px",
      width: "87px",
      borderRadius: "8px",
      borderStyle: "none",
      fontFamily: "Inter",
      fontWeight: 700
    },
    modalBtn: {
      textAlign: "right",
      marginTop: "20px",
    },
    text: {
      ...commonStyles.text,
    },
    singleTemmate: {
      cursor: "pointer",
      width: "20%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: "15px",
    },
    infoText1: {
      fontSize: "16px",
      fontFamily: "Inter",
      fontWeight: "bold",
      paddingTop: "15px",
      cursor: "pointer",
    },
    fromText:{
      fontSize: "16px",
      fontFamily: "Inter",
      fontWeight: "bold",
      paddingTop: "15px",
      cursor: "pointer",
      display: 'ruby',
      [theme.breakpoints.down(950)]: {
        fontSize: '24px',
        display: 'block'
      },
    },
    breakOnMobile:{
      [theme.breakpoints.up(950)]: {
        display:'none'
      },
    },
    businessNameText:{
      [theme.breakpoints.down(950)]: {
        fontSize: "20px",
        margin: "24px 0 0"
      },
    },
    allMessagesText: {
      padding: "5px 15px",
      marginTop: "15px",
      fontSize: "16px",
      fontFamily: "Inter, 'sans-serif'",
      fontWeight: "bold",
      cursor: "pointer",
      [theme.breakpoints.down(950)]: {
        marginTop: "0px",
        height: "50px"
      },
    },
    modalHeading: {
      fontSize: "24px",
      fontFamily: "Inter",
      fontWeight: 700,
    },
    infoText2: {
      ...commonStyles.text,
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      marginTop: "20px",
      gap: "5px",
      padding: "5px 5px 5px 15px",
      position: "relative", // Ensure positioning for pseudo-element
      "& > div": {
        flex: 1
      },
      "&:hover": {
        color: "#FFF",
        backgroundColor: "#000",
        "& svg": {
          color: "#fff !important",
        },
        "& img": {
          filter: "brightness(100)"
        }
      },
      [theme.breakpoints.down(950)]: {
        height: "50px",
        marginTop: "0px",
        "&::after": {
        content: '""',
        display: "block",
        width: "100%",
        height: "1px",
        backgroundColor: "#8F6C0E",
        position: "absolute",
        bottom: "-1px",
        left: 0
      },
      },
    },
    
    infoTextFolder: {
      ...commonStyles.text,
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      cursor: "pointer",
      gap: "5px",
      padding: "5px 5px 5px 15px",
      position: "relative",
      "& > div": {
        flex: 1
      },
      [theme.breakpoints.down(950)]: {
        "&::after": {
          content: '""',
          display: "block",
          width: "100%",
          height: "1px",
          backgroundColor: "#8F6C0E",
          position: "absolute",
          bottom: "0px",
          left: 0
        }
      },
    },
    
    infoText3: {
      display: "flex",
      justifyContent: "space-between",
      fontSize: "14px",
      fontFamily: "Inter",
      fontWeight: 700,
      cursor: "pointer",
      [theme.breakpoints.down(950)]: {
        justifyContent: "space-between",
        width: "100%"
      },
    },
    messageContainter: {
      position: "relative",
      display: "flex",
      height: "90vh",
      [theme.breakpoints.down(950)]: {
        display: "none"
      },
    },
    messageContainerMobile: {
      [theme.breakpoints.up(950)]: {
        display: "none"
      },
    },
    leftBox: {
      overflow: "auto",
      flex: "0.15",
      backgroundColor: "#FFC629",
      paddingTop: "20px",
      paddingLeft: "10px",
      paddingRight: "10px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      [theme.breakpoints.down(650)]: {
        padding: "0px",
        height: "calc(100vh - 121px)"
      },
    },
    addNewIconButton:{
      [theme.breakpoints.down(950)]: {
        display: 'none'
      },
    },
    addNewChatMobileButton: {
      position: "fixed",
      right: "8px",
      bottom: "66px",
      width: "58px",
      height: "58px",
      backgroundColor: "#FFC629",
      borderRadius: "100%",
      display: 'flex',
      alignItems: "center",
      justifyContent: 'center',
      "& img":{
        width: "38px",
        heigth: "38px"
      },
      [theme.breakpoints.up(950)]: {
        display: 'none'
      },
    },
    yellowPlusIcon:{

    },
    centerBox: {
      overflow: "auto",
      flex: "0.25",
    },
    rightBox: {
      overflow: "auto",
      flex: "0.59",
    },
    folderContainer: {
      paddingTop: "40px",
      // height: "250px",
      // overflowY: "scroll",
    },
    folerAndFolderCreateButtonContainer:{
      [theme.breakpoints.down(950)]: {
        display: "flex",
        flexDirection: "column-reverse",
      },
    },
    createFolderBtn: {
      ...commonStyles.text,
      textTransform: "none",
      backgroundColor: "black",
      color: "white",
      borderRadius: "11px",
      marginRight: "20px",
      width: "80%",
      "&:hover": {
        width: "80%",
        "&:hover": {
          backgroundColor: "black",
          color: "white",
        },
      },
    },
    btnContainer: {
      textAlign: "center",
      paddingTop: "15px",
      [theme.breakpoints.down(950)]: {
        marginBottom: "12px"
      },
    },
    settingsIcon: {
      color: "rgba(0, 0, 0, 0.70)",
      width: "24px",
      height: "24px",
      transform: "rotate(180deg)",
    },
    textCapitalize: {
      textTransform: "capitalize",
    },
    buttonSettings: {
      fontSize: 12,
    },
    bottomleft: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      [theme.breakpoints.down(950)]: {
        display: "none"
      },
    },
    everyBrandLogo: {
      marginTop: "15px",
    },
    imageIcons: {
      paddingRight: "10px",
    },
    messageCard: {
      height: "100%",
      marginLeft: "15px",
      marginTop: "15px",
      padding: "30px",
      overflow: "auto",
      boxShadow: "none",
      [theme.breakpoints.down(650)]: {
        margin: "12px"
      },
    },
    messageCardRight: {
      height: "100%",
      marginLeft: "15px",
      marginTop: "15px",
      padding: "30px",
      overflow: "auto",
      boxShadow: "none",
      [theme.breakpoints.down(650)]: {
        padding: "0px",
        margin: "0px",
        marginTop: "12px"
      },
    },
    searchField: {
      width: "85%",
      backgroundColor: "#D9D9D933",
      height: "44px",
      borderRadius: "98px",
      borderStyle: "none",
      paddingLeft: "40px",
      backgroundImage: `url(${search_icon})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "10px center",
      backgroundSize: "20px",
      [theme.breakpoints.down(650)]: {
        width: "100%",
        backgroundColor: "#ebebebb3",
      },
    },
    searchFieldTeammate: {
      fontFamily: "Inter",
      width: "100%",
      backgroundColor: "#F7F8FC",
      height: "40px",
      borderRadius: "98px",
      borderStyle: "none",
      paddingLeft: "40px",
      marginTop: "20px",
      backgroundImage: `url(${search_icon})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "10px center",
      backgroundSize: "20px",
      "&::placeholder": {
        fontFamily: "Inter",
      },
    },
    automationSearchBox: {
      display: "flex",
      flexDirection: "row",
    },
    searchFieldAutomation: {
      fontFamily: "Inter",
      width: "100%",
      backgroundColor: "rgba(217, 217, 217, 0.2)",
      height: "40px",
      borderRadius: "98px",
      borderStyle: "none",
      paddingLeft: "40px",
      // marginTop: "20px",
      backgroundImage: `url(${search_icon})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "10px center",
      backgroundSize: "20px",
      "&::placeholder": {
        fontFamily: "Inter",
      },
    },
    messageField: {
      width: "74%",
      backgroundColor: "#E2E8F0",
      height: "40px",
      borderRadius: "98px",
      borderStyle: "none",
      paddingLeft: "20px",
      outline: "none",
    },
    headingText: {
      fontSize: "24px",
      fontFamily: "Inter",
      fontWeight: 700,
      paddingTop: "20px",
    },
    chatContainer: {
    },
    filterBox: {
      paddingTop: "20px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    filterText: {
      fontSize: "14px",
      fontFamily: "Inter",
      fontWeight: 500,
      color: "#64748B",
    },
    filterInputModalBase:{
      maxWidth: "180px",
      width: "100%",
      cursor: "pointer",
      "& .MuiOutlinedInput-root":{
        borderRadius: "8px"
      },
      "& .MuiSelect-outlined.MuiSelect-outlined":{
        padding: "4px 32px 4px 8px"
      },
      "& .PrivateNotchedOutline-root-187":{
        top: "-10px"
      }
    },
    filterMenuItem:{
      "&.MuiMenu-paper":{
        borderRadius: "12px",
        width: "180px"
      },
      "&:hover": {
      backgroundColor: "#000",
      color: "#FFF",
      },
      "&.Mui-selected": {
        backgroundColor: "#000 !important",
        color: "#FFF !important",
      },
    },
    filterSelect: {
      backgroundColor: "white",
      width: "70%",
      borderRadius: "8px",
      border: "1px solid #CBD5E1",
      padding: "4px"
    },
    addNewMessageContainer:{
      [theme.breakpoints.down(950)]: {
        margin: "48px 24px"
      },
    },
    crossIcon:{
      [theme.breakpoints.down(950)]: {
        display: "none"
      },
    },
    flexSB: {
      display: "flex",
      justifyContent: "space-between",
    },
    messageScreenUserOneTime:{
      padding: "12px"
    },
    messageDivider: {
      marginTop: "20px",
      height: "1px",
      border: "none",
      backgroundColor: "#DADADA",
    },
    dateDivider: {
      height: "1px",
      border: "none",
      backgroundColor: "#DADADA",
      marginLeft: "50px",
      width: "60%",
    },
    messageDivider2: {
      // marginTop: "30vh",
      height: "1px",
      border: "none",
      backgroundColor: "#DADADA",
    },
    inputFieldMessages: {
      paddingTop: "20px",
      '& .MuiOutlinedInput-root': {
        borderRadius: '4px',
      },
      [theme.breakpoints.down(950)]: {
        "& .MuiInputBase-root":{
          border: '1px solid #CBD5E1',
          borderRadius: "8px",
          padding: "12px",
          backgroundColor: "#fff"
        },
        '& .MuiOutlinedInput-root': {
          borderRadius: '8px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: '2px solid #ccc',
        },
        '& .MuiInput-underline::after':{
          border: "none"
        },
        '& .MuiInput-underline::before':{
          border: "none"
        }
      },
    },
    phoneFieldMessage:{
      [theme.breakpoints.down(650)]: {
        display: "none"
      },
      
    },
    countryPhoneNumber:{
      paddingTop: "20px",
      [theme.breakpoints.up(650)]: {
        display: "none"
      },
    },
    input: {
      "&::placeholder": {
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: 700,
        color: "#CBD5E1",
        [theme.breakpoints.down(950)]: {
          fontSize: "16px",
          fontWeight: 400,
          color: "#64748B"
        },
      },
    },
    inputName: {
      fontSize: "20px",
      fontWeight: 700,
      "&::placeholder": {
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: 700,
        color: "#CBD5E1",
        [theme.breakpoints.down(950)]: {
          fontSize: "16px",
          fontWeight: 400,
          color: "#64748B"
        },
      },
    },
    newContact: {
      marginTop: "30px",
      backgroundColor: "#FFC629",
      height: "46px",
      display: "flex",
      alignItems: "center",
      paddingLeft: "20px",
      [theme.breakpoints.down(950)]: {
        borderRadius: '8px',
        width: "285px",
        marginLeft: "auto",
        marginRight: "auto",
        height: "56px",
      },
    },
    sendMessage: {
      paddingTop: "10px",
      display: "flex",
      [theme.breakpoints.down(950)]: {
        backgroundColor: "#fff",
        paddingBottom: "18px",
        alignItems: "center",
        gap: "12px"
      },
    },
    meUserIcon:{
      height: "32px",
      width: "32px",
      marginRight: "20px",
      marginTop: "5px",
      [theme.breakpoints.down(950)]: {
        display: "none"
      },
    },
    showMoreFeature: {
      height: "24px",
      width: "24px",
      transform: "rotate(180deg)",
      [theme.breakpoints.up(950)]: {
        display: "none"
      },
    },
    addContactButton: {
      backgroundColor: "#FFC629",
      color: "#0F172A",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "56px",
      width: "360px",
      borderRadius: "8px",
      fontFamily: "Inter",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      textTransform: "none",
      margin: "24px auto",
      marginBottom: '120px',
      [theme.breakpoints.down(650)]: {
        width: "285px"
      },
      "&:hover": {
        backgroundColor: "#FFC629",
      },
      [theme.breakpoints.up(950)]: {
        display: 'none'
      },
    },
    sendButton: {
      marginTop: "3px",
      backgroundColor: "#FFC629",
      fontSize: "16px",
      fontFamily: "Inter",
      fontWeight: 700,
      marginLeft: "11px",
      borderRadius: "8px",
      height: "32px",
      width: "83px",
      borderStyle: "none",
      [theme.breakpoints.down(950)]: {
        display: "none"
      },
    },
    allConsversatitonBox: {
      marginTop: "20px",
    },
    conversationContainer: {
      display: "flex",
      marginTop: "10px",
    },
    conversationImage1: {
      backgroundColor: "#FFC629",
      borderRadius: "50%",
      // padding: "0 7px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      height: "32px",
      width: "32px",
    },

    conversationImage: {
      backgroundColor: "#FFC629",
      borderRadius: "50%",
      padding: "0 7px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      height: "44px",
      width: "44px",
    },
    teamMateImage: {
      backgroundColor: "#FFC629",
      borderRadius: "50%",
      padding: "0 7px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      height: "117px",
      width: "113px",
    },
    conversationDetails: {
      marginLeft: "10px",
    },
    nameText: {
      fontSize: "14px",
      fontFamily: "Inter",
      fontWeight: 700,
    },
    msgTxt: { fontSize: "14px", fontFamily: "Inter", fontWeight: 400 },
    dateText: {
      fontSize: "14px",
      fontFamily: "Inter",
      fontWeight: 400,
      color: "#64748B",
    },
    loading: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '400px',
    },
    singleConversation: {
      width: "100%",
      padding: "5px",
      marginTop: "5px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "rgba(217,217,217,0.2)",
        borderRadius: "6px",
      },
    },
    singleContact: {
      width: "100%",
      padding: "5px",
      marginTop: "5px",
      display: "flex",
      justifyContent: "space-between",
      cursor: "pointer",

      "&:hover": {
        backgroundColor: "rgba(217,217,217,0.2)",
        borderRadius: "6px",
      },
    },
    bottomRight: {
      // position: "absolute",
      // bottom: "-23px",
      width: "100%",
      height: "8%",
      [theme.breakpoints.down(950)]: {
        position: "fixed",
        bottom: "90px",
        backgroundColor: "#fff",
        height:"auto",
        paddingBottom: "16px"
      },
    },
    docCameraAutoContainer:{
      display: "flex",
      alignItems: 'center'
    },
    docCameraAutoContainerDesktop:{
      [theme.breakpoints.down(950)]: {
        display: "none"
      },
    },
    sendMessageIcon:{
      heigth: "28px",
      width: "28px",
      [theme.breakpoints.up(950)]: {
        display: "none",
      },
    },
    docCameraAutoContainerMobile:{
      marginLeft: "38px"
    },
    imageTop: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      flexDirection: "column",
    },
    imageTop2: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      flexDirection: "row",
    },
    imageLine: {
      flexGrow: 1,
      height: "1px",
      backgroundColor: "rgba(100, 116, 139,0.2)",
      margin: "0 10px",
    },
    profileArrow: {
      position: "absolute",
      right: "10px",
      top: 0,
      bottom: 0,
      fontSize: "16px",
      color: "#000",
      margin: "auto 0",
    },
    nameBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: 700,
      background: "#F1F5F9",
      width: "28%",
      height: "32px",
      borderRadius: "34px",
      marginTop: "10px",
      position: "relative",
      [theme.breakpoints.down(950)]: {
        width: "48%"
      },
    },
    onlineIcon: {
      height: "12px",
      width: "12px",
      background: "#30D158",
      borderRadius: "50%",
      marginLeft: "20px",
    },
    myChat: {
      textAlign: "right",
      marginRight: "47px",
      marginTop: "20px",
      [theme.breakpoints.down(950)]: {
        marginRight: "12px"
      },
    },
    othersChat: {
      marginLeft: "47px",
      marginTop: "20px",
      [theme.breakpoints.down(950)]: {
        marginLeft: "12px"
      },
    },
    singleConversation1: {
      display: "flex",
    },
    singleConversation1myChat: {
      display: "flex",
      justifyContent: "flex-end",
    },
    singleConversation1Text: {
      width: "auto",
      padding: "10px 33px",
      borderRadius: "20px 20px 20px 0px",
      background: "#F1F5F9",
      marginLeft: "10px",
    },
    singleConversation1TextmyChat: {
      width: "auto",
      padding: "10px 33px",
      borderRadius: "20px 20px 0px 20px",
      background: "#FFC629",
      marginRight: "10px",
    },
    imageOuter: {
      marginTop: "auto",
    },
    delieveredTime: {
      marginTop: "10px",
      fontFamily: "Inter",
      fontWeight: 400,
      color: "#64748B",
      fontSize: "10px",
    },
    contactsCard: {
      width: "50%",
      position: "absolute",
      zIndex: 1000,
      maxHeight: "400px",
      overflowY: "auto",
    },
    messageButton: {
      textTransform: "none",
    },
    assignFolderBox: {
      margin: "20px 10px",
    },
    assignFolBox2: {
      display: "flex",
      paddingBottom: "10px",
    },
    folderText: {
      paddingLeft: "10px",
      fontSize: "14px",
      lineHeight: "22px",
      fontWeight: 600,
    },
    popOverPaperAssign: {
      padding: "4px 4px 2px 4px",
      borderRadius: "8px",
      border: "1px solid #E2E8F0",
      minWidth: "192px",
      margin: "10px",
      boxShadow:
        "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
    },
    popOverPaper: {
      padding: "4px 4px 2px 4px",
      borderRadius: "8px",
      border: "1px solid #E2E8F0",
      minWidth: "192px",
      background: "#FFF",
      boxShadow:
        "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
    },
    folderList: {
      "&:hover": {
        background: "black",
        color: "white",
        borderRadius: "10px",
      },
    },
    teammadeModalBox: {
      position: "relative",
      backgroundColor: "#fff",
      padding: "50px 80px",
      width: "80%",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "0px 0px 24px 0",
      [theme.breakpoints.down(950)]: {
        width: "96%",
        padding: "12px",
        height: "80%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
      },
    },
    teammateBox: {
      display: "flex",
      flexWrap: "wrap",
      padding:"40px 0"
      // justifyContent:'space-between'
    },
    infoContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      width: "90%",
      paddingTop: "25px",
      [theme.breakpoints.down(950)]: {
        flexDirection: "column",
        alignItems: "start",
        gap: "12px"
      },
    },
    infoSubContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "10px",
    },
    infoLogoContainer: {
      width: "36px",
      height: "36px",
      background: "#000",
      borderRadius: "50%",
      color: "#fff",
      paddingTop: "5px",
      "&svg": {
        fontSize: "16px",
      }
    }
  });

const StyledMenuItem = withStyles((theme) => ({}))(MenuItem);

export default withStyles(styles)(Messages);
// Customizable Area End
