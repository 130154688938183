import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  Avatar,
  Snackbar,
  ListItemText,
  MenuItem,
  Popover,
  ListItemIcon,
  IconButton,
  CircularProgress,
  styled,
  Dialog,
} from "@material-ui/core";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import {
  priorityIcon,
  messageIcon,
  resendIcon,
  pinIcon,
  deleteIcon,
} from "./assets";
import { MoreVertRounded, CheckOutlined } from "@material-ui/icons";
import { IconAvtar } from "../../contactlist/src/assets";
import StarBorderOutlinedIcon from "@material-ui/icons/StarBorderOutlined";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import MuiAlert from "@material-ui/lab/Alert";
import CustomLoader from "../../../components/src/CustomLoader.web";
import clsx from "clsx"
const DEFAULT_CREATED_BY = {
  data: {
    id: "",
    attributes: {
      first_name: "",
      last_name: "",
      profile_pic: null
    }
  }
}
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";
import { nextSliderArrow } from "../../dashboard/src/assets";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderFilterMethods = () => {
    return <>
      {this.state.filters.map((filter: any) => {
        const isChecked = filter.checked;
        const filterName = filter.name;

        return (
          <Button
            id={`button${filterName.replace(" ", "")}`}
            data-test-id="filterName"
            key={filterName}
            className={clsx(this.props.classes.filterButton, {
              [this.props.classes.checkedButton]: isChecked
            })}
            onClick={() => this.checkFilter(filterName)}
          >
            {filterName === "All" && (
              <StarBorderOutlinedIcon data-test-id="allFilter" />
            )}
            {this.renderFilterIcon(filterName, isChecked)}
            <Typography
              className={this.props.classes.filterFonts}
              data-test-id={filterName === "All" ? "allFilter" : ""}
            >
              {filterName}
            </Typography>
          </Button>
        );
      })}</>
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Container
        disableGutters
        maxWidth={false}
        className={this.props.classes.mainNotificationContainer}
      >
        <Snackbar
          open={this.state.isShowAlert}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert elevation={6} variant="filled" severity={"success"}>
            {this.state.showSuccess}
          </MuiAlert>
        </Snackbar>
        <NotificationSelectHeaderMobile onClick={() => {this.setState({isMobileNotificationFilterModalOpen: true})}} data-test-id="notificationTypeSelector">
        <Typography className={this.props.classes.mobileHeaderText}>
              {this.state.selectedFilterName}
            </Typography>
            <img src={nextSliderArrow} className={this.props.classes.openDrawerIcon}/>
        </NotificationSelectHeaderMobile>
        <SelectNotificationTypeDialog open={this.state.isMobileNotificationFilterModalOpen} onClose={() => this.setState({isMobileNotificationFilterModalOpen: false})} data-test-id="notificationModal">
          <Box className={this.props.classes.filtersContainerMobile}>
            {this.renderFilterMethods()}
          </Box>
        </SelectNotificationTypeDialog>
        <Container className={this.props.classes.notificationContent}>
            <div className={this.props.classes.filtersWrapper}>
              <Box className={this.props.classes.filtersContainer} id="filters">
                {this.renderFilterMethods()}
              </Box>
              <button
                data-test-id="markAllRead"
                className={this.props.classes.markAllAsReadWrapper}
                onClick={() => this.markAllAsReadApiCall()}
              >
                <div>
                  <CheckOutlined />
                </div>
                <div>
                  <Typography className={this.props.classes.markReadFonts}>
                    {configJSON.markAllAsReadLabel}
                  </Typography>
                </div>
              </button>
            </div>

          <CustomLoader
            isLoading={this.state.isLoading}
            loaderSize={30}
            wrapperHeight="60vh"
          >
            {this.state.notificationData.map((item: any) => {
              const { attributes: { is_read, pin_top, is_priority, contents, created_by, post_id }} = item
              const createdBy = created_by.data ? created_by : DEFAULT_CREATED_BY
              const { data: { attributes: { first_name, last_name, profile_pic } }} = createdBy

              return (
                <Box
                data-test-id="notification-box"
                key={item.id}
                onClick={() => this.handleNotificationClick(item)}
                className={`${this.props.classes.card} ${
                  this.commonFunctionForCondition(
                    is_read,
                    this.props.classes.cardWithoutBackGround,
                    this.props.classes.cardWithBackGround
                  )
                }`}
                style={{ backgroundColor: is_priority ? 'rgba(255, 198, 41, 1)' : 'inherit' }}>
                <div className={this.props.classes.mainDiv}>
                  <div>
                    <Avatar
                      className={this.props.classes.notificationAvatar}
                      src={profile_pic?.url || IconAvtar}
                      alt="User Profile"
                    />
                  </div>
              
                  <div className={this.props.classes.cardcontent}>
                    <Box className={this.props.classes.cardHeadingBox}>
                      <Box width="100%">
                      <Typography className={this.props.classes.name} component="div">
                        <Box display="flex" alignItems="center">
                          {`${first_name} ${last_name} `} <span style={{width: '7px',height:'7px',borderRadius:'15px',
                            backgroundColor:'black',marginLeft: '5px'}}></span>
                          {!is_read && (
                            <FiberManualRecordIcon
                              className={this.props.classes.bulletSmall}
                            />
                          )}
                        </Box>
                      </Typography>
                      <Typography className={this.props.classes.contactInfoNumber}>
                        {contents.length > 25 ? contents.slice(0, 25) + "..." : contents}
                      </Typography>
                      </Box>
                      <Box>
                        {pin_top && (
                          <img
                            src={pinIcon}
                            alt="pinIcon"
                            style={{ height: '18px', width: '18px' }}
                          />
                        )}
                        {is_priority && (
                          <Box className={this.props.classes.priorityBox}>
                            <img
                              src={priorityIcon}
                              alt="priorityIcon"
                              style={{ height: '22px', width: '22px' }}
                            />
                            <Typography className={this.props.classes.priorityTxt}>
                              {configJSON.priorityLabel}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </div>
                  <div className={this.props.classes.cardactions}>
                    <IconButton
                      size="small"
                      data-test-id="openPopover"
                      onClick={(event) => this.handleClick(event, item)}
                    >
                      <MoreVertRounded />
                    </IconButton>
                    <div>
                      <Typography className={this.props.classes.hourFonts}>
                        {this.timeSince(item.attributes.created_at)}
                      </Typography>
                    </div>
                  </div>
                </div>
              </Box>
              
              );
            })}
          </CustomLoader>
          <Popover
            open={Boolean(this.state.anchorEl)}
            id="simple-popover"
            anchorEl={this.state.anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            classes={{
              paper: this.props.classes.popOverPaper,
            }}
          >
            {this.state.commentType === "BxBlockComments::Comment" && (
                <StyledMenuItem
                  data-test-id="reply"
                  disabled={this.state.isGettingCommentDetails}
                  onClick={this.handleNotificationReplyClick}>
                  <ListItemIcon>
                    <img
                      src={resendIcon}
                      alt="priorityIcon"
                      className={this.props.classes.mediumImg}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Reply" />
                  {this.commonFunctionForCondition(this.state.isGettingCommentDetails, <ListItemIcon>
                    <Box className={this.props.classes.replyLoader}>
                      <CircularProgress size={16} />
                   </Box>
                  </ListItemIcon>, null)}
                </StyledMenuItem>
            )}

            <StyledMenuItem
              data-test-id="MarkAsRead"
              disabled={this.isNotificationAlreadyRead()}
              onClick={() =>
                this.markAsReadByPerticularNotifictionApiCall(
                  this.state.notificationId.id
                )
              }
            >
              <ListItemIcon>
                <img
                  src={messageIcon}
                  alt="priorityIcon"
                  className={this.props.classes.mediumImg}
                />
              </ListItemIcon>
              <ListItemText primary="Mark as read" />
            </StyledMenuItem>
            <StyledMenuItem
              data-test-id="deleteNotification"
              onClick={() =>
                this.deleteNotifications(this.state.notificationId.id)
              }
            >
              <ListItemIcon>
                <img
                  src={deleteIcon}
                  alt="priorityIcon"
                  className={this.props.classes.mediumImg}
                />
              </ListItemIcon>
              <ListItemText style={{ color: "red" }} primary="Delete" />
            </StyledMenuItem>
            <StyledMenuItem
              data-test-id="pinToTop"
              onClick={() => this.pinToTopApiCall(this.state.notificationId.id)}
            >
              <ListItemIcon>
                <img
                  src={pinIcon}
                  alt="priorityIcon"
                  className={this.props.classes.mediumImg}
                />
              </ListItemIcon>
              <ListItemText primary={this.getPinUnpinText()} />
            </StyledMenuItem>
            <StyledMenuItem
              data-test-id="markAsPriority"
              onClick={() => this.markAsPriorityCall(this.state.notificationId.id)}
            >
              <ListItemIcon>
                <img
                  src={priorityIcon}
                  alt="priorityIcon"
                  className={this.props.classes.priorityIcon}
                />
              </ListItemIcon>
              <ListItemText primary={this.getPriorityText()} />
            </StyledMenuItem>
          </Popover>
        </Container>
      </Container>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "#FFC629",
      borderRadius: "8px",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "#0F172A",
      },
    },
  },
}))(MenuItem);

const NotificationSelectHeaderMobile = styled(Box)(({theme}) => ({
  display: "flex",
  alignSelf: "stretch",
  gap: "18px",
  justifyContent: "flex-end",
  padding: "20px",
  background: "#000",
  color: "#FFF",
  cursor: "pointer",
  [theme.breakpoints.up(950)]: {
    display: "none"
  },
}));

const SelectNotificationTypeDialog = styled(Dialog)(({theme}) => ({
  "& .MuiDialog-paper": {
      margin: "0px",
      top: "120px",
      left: "-20px",
      borderRadius: "8px",
      padding: "8px",
    },
    "& .MuiDialog-container": {
      justifyContent: "flex-end",
      alignItems: "start"
    },
}))

const webStyles: any = {
  filterItem: {
    display: "flex",
    height: "32px",
    padding: "6px 8px",
    alignItems: "center",
    gap: "4px",
    borderRadius: "26px",
    textTransform: "capitalize",
  },
};

const styles = (theme: Theme) =>
  createStyles({
    priorityTxt:{
      fontWeight:600,
      fontSize:"14px",
    },
    priorityBackground: {
      backgroundColor: 'rgba(255, 198, 41, 1)', 
    },
    replyLoader: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
      "& svg": {
        color: "#000"
      }
    },
    priorityBox:{
      display:"flex",
      alignItems: "center",
      gap: "10px"
    },
    priorityIcon: {
      height: "30px",
      width: "30px"
    },
    mediumImg: {
      height: "24px",
      width: "24px"
    },
    mainNotificationContainer: {
      flexDirection: "column",
      backgroundColor: "#F1F5F9",
      justifyContent: "flex-start",
      alignItems: "center",
      display: "flex",
      padding: "43px 27px 10px 32px",
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
    },
    popOverPaper: {
      padding: "4px 4px 2px 4px",
      borderRadius: "8px",
      border: "1px solid #E2E8F0",
      minWidth: "192px",
      background: "#FFF",
      boxShadow:
        "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
    },
    notificationContent: {
      display: "flex",
      height: "100%",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
      padding: "40px 24px 20px",
      flexDirection: "column",
      maxWidth: "unset",
      borderRadius: "8px 8px 32px 8px",
      backgroundColor: "#FFF",
      [theme.breakpoints.down("sm")]: {
        borderRadius: "unset",
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    filtersWrapper: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      borderBottom: "1px solid #E2E8F0",
      padding: "16px 8px",
      [theme.breakpoints.down(950)]: {
        justifyContent: "end",
      },
    },
    filtersContainer: {
      display: "flex",
      flexWrap: "nowrap",
      gap: "4px",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    filtersContainerMobile:{
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      width: "100%",
      gap: "8px",
      [theme.breakpoints.down(950)]: {
        justifyContent: "start"
      },
    },
    mobileHeaderText: {
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
    },
    openDrawerIcon:{
      filter: "invert(1) brightness(2)",
      transform: "rotate(90deg)",
      width: "16px",
      height: "16px" 
    },
    markAllAsReadWrapper: {
      display: "flex",
      alignItems: "center",
      gap: "1px",
      border: "none",
      backgroundColor: "transparent",
      padding: "2px 12px",
      borderRadius: "20px",
      "&:hover": {
        backgroundColor: "rgba(0,0,0,0.05)"
      }
    },
    card: {
      padding: "16px 8px",
      borderBottom: "1px solid #E2E8F0",
      width: "100%",
    },
    mainDiv: {
      display: "flex",
      gap: "24px",
    },
    notificationAvatar: {
      color: "#000",
      width: "44px",
      height: "44px",
      backgroundColor: "#FEF3C7",
    },
    cardcontent: {
      width: "90%",
    },
    cardHeadingBox:{
      display:'flex',
      justifyContent:"space-between"
    },
    name: {
      color: "#0F172A",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "22px",
      display: "flex",
      alignItems: "center",
      gap: "3px"
    },
    contactInfoNumber: {
      marginTop: "4px",
      color: "#0F172A",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "22px",
      overflow: "hidden",
    },
    bulletSmall: {
      fontSize: "12px",
      color: "#0F172A",
      backgroundColor: 'red',
      width: '10px',
      height: '10px',
      
    },
    cardactions: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      justifyContent: "center",
      gap: "12px",
      minWidth: "70px"
    },
    hourFonts: {
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "20px",
      color: "#64748B",
      paddingLeft: "4px",
    },
    markReadFonts: {
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "31px",
      color: "#334155",
    },
    cardWithBackGround: {
      background: "#F7F8FC",
      cursor: "pointer",
    },
    cardWithoutBackGround: {
      background: "#FFF",
      cursor: "pointer",
    },
    filterFonts: {
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "20px",
      paddingLeft: "4px",
    },
    filterButton: {
      ...webStyles.filterItem,
      backgroundColor: "#F1F5F9",
      color: "#335155",
      [theme.breakpoints.down(950)]: {
        borderRadius: "8px",
        width: "100%",
        display: "flex",
        alignItems: "start",
        justifyContent: "start"
      }
    },
    checkedButton: {
      backgroundColor: "#000",
      color: "#FFF",
      "&:hover": {
        backgroundColor: "#000",
        color: "#FFF",
      }
    },
    icon: {
      width: "18px",
      height: "18px",
    },
    commentIcon: {
      width: "21.23px",
      height: "18px",
    },
    priorityIconFilter: {
      width: "16px",
      height: "16px",
    },
  });
export const NotificationWeb = withStyles(styles)(Notifications);
// Customizable Area End
