// Customizable Area Start
import React from "react";
import { NavLink, Link } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import { BusineesUserInfo } from "./interfaces";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import {
  UserIcon,
  AddTeamMemberIcon,
  PageAnalyticsIcon,
  EveryReelIcon,
  EverybrandIcon,
  ClockIcon,
  ContactsIcon,
} from "./assets";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import { Modal, styled } from "@material-ui/core";
import { CloseIcon } from "../../settings2/src/assets";
import { AppLayoutModals } from "./enums";

interface Props {
  businessUserInfo: BusineesUserInfo;
  isMobileDrawerOpen: boolean;
  onCloseDrawer: () => void;
  onLogout: () => void;
  logoutModal: () => void;
  openModal: (modal: AppLayoutModals) => void;
  logoutModalState: boolean;
}
const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    //necessary for content to be below app bar
    offset: theme.mixins.toolbar,
    userProfileContainer: {
      backgroundColor: "#0F172A",
      padding: theme.spacing(4, 2.5),
      borderBottomRightRadius: theme.spacing(4),
      position: "relative",
    },
    editProfileBtn: {
      position: "absolute",
      top: 19,
      left: 11,
      color: "#FFF",
      backgroundColor: "#475569",
      borderRadius: theme.spacing(1),
      "&:hover": {
        backgroundColor: "#475569",
      },
    },
    userAvatar: {
      width: theme.spacing(17),
      height: theme.spacing(17),
      border: "4px solid #F0E5FF",
      margin: "0 auto",
      backgroundColor: "#FFC629",
    },
    wrapper: {
      padding: theme.spacing(0, 2),
      paddingBottom: "64px"
    },
    userNameBlock: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      gap: theme.spacing(2),
      color: "#FFF",
      marginTop: theme.spacing(2),
      cursor: "pointer",
    },
    button: {
      justifyContent:"start",
      fontSize: 12,
      padding:"8px 25px"
    },
    buttonHover: {
      "&:hover": {
        justifyContent:"start",
        fontSize: "12px",
        fontStyle: "normal",
        fontFamily: "Inter",
        padding: theme.spacing(1, 3),
        fontWeight: 700,
        borderRadius: theme.spacing(1),
        color: "#FFF",
        backgroundColor: "#000",
        "& svg": {
          color: "#fff !important",
        },
        "& img": {
          filter: "brightness(100)"
        }
      }
    },
    customRipple: { backgroundColor: 'rgb(255, 198, 41)'},
    containedButton: {
      fontSize: "12px",
      fontStyle: "normal",
      fontFamily: "Inter",
      padding: theme.spacing(2, 1),
      fontWeight: 700,
      borderRadius: theme.spacing(1),
      color: "#FFF",
      justifyContent:"center",
      backgroundColor: "#000",
      "&:hover": {
        backgroundColor: "#000",
      },
    },
    divider: {
      height: "2px",
      margin: theme.spacing(2, 0),
      backgroundColor: "#0F172A",
    },
    list: {
      listStyle: "none",
      padding: 0,
      margin: 0,
    },
    listItem: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
      padding: "8px 23px",
      cursor: "pointer",
      "& span": {
        fontSize: "13px",
        fontWeight: 400,
        fontStyle: "normal",
        fontFamily: "Inter",
        color: "#0F172A",
      },
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
    },
    listItemIcon: {},
    fontWeightBold: {
      fontWeight: 700,
    },
    textUppercase: {
      textTransform: "uppercase",
      fontSize: "16px",
      fontStyle: "normal",
      fontFamily: "Inter",
    },
    textCapitalize: {
      textTransform: "capitalize",
    },
    textXs: {
      fontSize: "12px",
      fontStyle: "normal",
      fontFamily: "Inter",
      fontWeight: 700,
    },
    text2x: {
      fontWeight: 700,
      fontSize: "24px",
      fontStyle: "normal",
      fontFamily: "Inter",
    },
    phoneNumber: {
      color: "#FFF",
      fontSize: 18,
    },
    statsContainer: {
      display: "flex",
      justifyContent: "center",
      gap: theme.spacing(1.5),
      margin: theme.spacing(2, 0),
      "& hr": {
        width: "2px",
        backgroundColor: "#0F172A",
        height: "48px",
        marginTop: theme.spacing(1),
      },
    },
    drawer: {
      [theme.breakpoints.up("lg")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.down(400)]: {
        width: "100%"
      },
    },
    "@media (min-width: 1280px)": {
      drawer: {
        "&::-webkit-scrollbar": {
          width: "12px", // Width of the scrollbar
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: theme.palette.primary.main, // Color of the scrollbar thumb
          borderRadius: "6px", // Rounded corners of the scrollbar thumb
        },
      },
    },
    settingsIcon: {
      color: "rgba(0, 0, 0, 0.70)",
      width: "24px",
      height: "24px",
      transform: "rotate(180deg)",
    },
    logoutBtn: {
      color: "#FFFFFF",
      textTransform: "none",
    },
    fallbackAvatar: {
      width: "70%",
      height: "70%",
    },
    EndWaitlistmodalBox: {
      position: "relative",
      backgroundColor: "#fff",
      padding: "85px 106px 31px 72px",
      width: "100%",
      maxWidth: "636px",
      borderRadius: "0px 0px 40px 0",
      top: "50%",
      transform: "translate(-50%, -50%)",
      left: "50%",
      [theme.breakpoints.down(950)]: {
        padding: "24px"
      },
    },
  })
);

const ResetModalTitle = styled(Typography)({
  paddingBottom: "21px",
  marginBottom: "38px",
  display: "flex",
  justifyContent: "center",
  position: "relative",
  textAlign: "center",
  color: "#0F172A",
  fontFamily: "Inter",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "32px" /* 133.333% */,
  letterSpacing: "-0.12px",
});

const DeleteModalFooter = styled(Box)({
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  gap: "20px",
});
const CancelModalButton = styled("button")({
  padding: "16px 0",
  backgroundColor: "#ffca29",
  textTransform: "capitalize",
  color: "black",
  display: "block",
  border: "none",
  borderRadius: "12px",
  width: "100%",
  fontSize: "16px",
  fontWeight: 700,
  cursor: "pointer",
});

const DeleteModalButton = styled("button")({
  backgroundColor: "black",
  borderRadius: "12px",
  padding: "16px 0",
  textTransform: "capitalize",
  width: "100%",
  color: "white",
  border: "none",
  fontSize: "16px",
  fontWeight: 700,
  cursor: "pointer",
});
const CloseImageIcon = styled("img")({
  right: "-71px",
  position: "absolute",
  cursor: "pointer",
  height: "26px",
  bottom: "81px",
  padding: "4px",
  width: "24px",
  borderRadius: "80px",
  justifyContent: "space-between",
  alignItems: "center",
  border: " 1px solid var(--Neutrals-Cool-gray-200, #E2E8F0)",
  background: "#FFF",
  boxShadow:
    " 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
  "@media (max-width: 950px)": {
    right: "-20px"
  },
  "@media (max-width: 480px)": {
    right: "0px"
  },
});

function Sidebar({
  businessUserInfo,
  isMobileDrawerOpen,
  onCloseDrawer,
  onLogout,
  logoutModal,
  openModal,
  logoutModalState,
}: Props) {
  const classes = useStyles();
  const [isQuickMenuOpen, setIsQuickMenuOpen] = React.useState(false);

  const drawer = (
    <div >
      <div className={classes.userProfileContainer}>
        <Link to="/UserProfile">
          <IconButton
            aria-label="edit profile picture"
            className={classes.editProfileBtn}
            size="small"
          >
            <EditOutlinedIcon />
          </IconButton>
        </Link>
        <Avatar
          className={classes.userAvatar}
          src={businessUserInfo.attributes?.profile_pic?.url || ContactsIcon}
        >
          <img
            src={businessUserInfo.attributes?.profile_pic?.url || ContactsIcon}
            className={classes.fallbackAvatar}
          />
        </Avatar>
        <div
          data-test-id="userNameBlock"
          className={classes.userNameBlock}
          onClick={() => setIsQuickMenuOpen((isOpen) => !isOpen)}
        >
          <Typography
            className={clsx(classes.fontWeightBold, classes.textUppercase)}
          >
            {`${businessUserInfo.attributes?.first_name ||
              ""} ${businessUserInfo.attributes?.last_name || ""}`}
          </Typography>
        </div>
        <Collapse in={isQuickMenuOpen} timeout="auto" unmountOnExit></Collapse>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.statsContainer}>
          <div>
            <Typography
              className={clsx(classes.text2x, classes.fontWeightBold)}
              align="center"
              gutterBottom
              data-test-id="followersText"
            >
              {businessUserInfo.attributes?.follows?.followers || 0}
            </Typography>
            <Typography
              className={clsx(classes.textXs, classes.fontWeightBold)}
            >
              Followers
            </Typography>
          </div>
          <Divider orientation="vertical" />
          <div>
            <Typography
              className={clsx(classes.text2x, classes.fontWeightBold)}
              align="center"
              gutterBottom
              data-test-id="followingText"
            >
              {businessUserInfo.attributes?.follows?.followings || 0}
            </Typography>
            <Typography
              className={clsx(classes.textXs, classes.fontWeightBold)}
            >
              Following
            </Typography>
          </div>
        </div>

        <Box justifyContent="center" mb={2}>
          <Button
            variant="contained"
            className={clsx(classes.button, classes.containedButton)}
            fullWidth
            onClick={() => {onCloseDrawer(); openModal(AppLayoutModals.INVITE_FRIENDS)}}
            TouchRippleProps={{
              classes: {
                rippleVisible: classes.customRipple 
              }
            }}
          >
            Invite a friend
          </Button>
        </Box>
        <ul className={classes.list}>
          <Link to="/userprofileBu" style={{ textDecoration: "none" }}>
            <Button
              startIcon={
                <div className={classes.listItemIcon} >
                <img  src={UserIcon} />
              </div>
              }
              fullWidth
              className={clsx(classes.button, classes.textCapitalize, classes.buttonHover)}
            >
              My Profile
            </Button>
          </Link>
          <Link to="/VisualAnalytics" style={{ textDecoration: "none" }}>
            <Button
              startIcon={
                <div className={classes.listItemIcon} >
                <img  src={PageAnalyticsIcon} />
              </div>
              }
              fullWidth
              className={clsx(classes.button, classes.textCapitalize, classes.buttonHover)}
            >
              Page Analytics
            </Button>
          </Link>
          
          <Link to="/WaitList" style={{ textDecoration: "none" }}>
            <Button
              startIcon={
                <div className={classes.listItemIcon} >
                <img  src={ClockIcon} />
              </div>
              }
              fullWidth
              className={clsx(classes.button, classes.textCapitalize, classes.buttonHover)}
            >
              Waitlist
            </Button>
          </Link>
          <Link to="/settings?teammate=true&open=true" style={{ textDecoration: "none" }}>
          <Button
              startIcon={
                <div className={classes.listItemIcon} >
                <img  src={AddTeamMemberIcon} style={{ padding: "0 0 0 5%",width:"21.46px",height:"24px" }}/>
              </div>
              }
              fullWidth
              className={clsx(classes.button, classes.textCapitalize, classes.buttonHover)}
            >
              Add team member
            </Button>
          </Link>
        </ul>
        <Divider className={classes.divider} />

        <Box my={2} style={{ marginRight: "10px" }}>
          <Typography
            gutterBottom
            style={{ marginLeft: "12px" }}
            className={clsx(classes.fontWeightBold, classes.textUppercase)}
          >
            EveryReel
          </Typography>
          <Button
            fullWidth
            style={{ paddingRight: 0 }}
            className={clsx(classes.button, classes.textCapitalize, classes.buttonHover)}
            startIcon={<img src={EveryReelIcon} />}
          >
            EveryReel Library
          </Button>
        </Box>
        <NavLink to={"/CreateEveryreel"}>
          <Button
            variant="contained"
            className={clsx(classes.button, classes.containedButton)}
            fullWidth
            TouchRippleProps={{
              classes: {
                rippleVisible: classes.customRipple 
              }
            }}
          >
            Create New Everyreel
          </Button>
        </NavLink>

        <Divider className={classes.divider} />
        <Box my={2}>
          <Link to="/settings">
            <Button
              startIcon={
                <SettingsOutlinedIcon className={classes.settingsIcon} />
              }
              fullWidth
              className={clsx(classes.button, classes.textCapitalize, classes.buttonHover)}
            >
              Settings
            </Button>
          </Link>
          <Button
            fullWidth
            startIcon={
              <ExitToAppOutlinedIcon className={classes.settingsIcon} />
            }
            className={clsx(classes.button, classes.textCapitalize, classes.buttonHover)}
            onClick={logoutModal}
            data-test-id="logOutBtn"
          >
            Log out
          </Button>
          <Modal
            open={logoutModalState}
            onClose={logoutModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            data-test-id="templateEditModal"
          >
            <Box className={classes.EndWaitlistmodalBox}>
              <div>
                <ResetModalTitle>
                  Are you sure you want to log out?
                  <CloseImageIcon
                    data-test-id="closeEndModal"
                    src={CloseIcon}
                    alt=""
                    onClick={logoutModal}
                  />
                </ResetModalTitle>
              </div>
              <DeleteModalFooter>
                <DeleteModalButton
                  data-test-id="resetButtonCall"
                  type="button"
                  onClick={logoutModal}
                >
                  Cancel
                </DeleteModalButton>
                <CancelModalButton onClick={onLogout}>
                  Confirm
                </CancelModalButton>
              </DeleteModalFooter>
            </Box>
          </Modal>
        </Box>
        <Typography align="center" gutterBottom>
          <img src={EverybrandIcon} />
        </Typography>
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <nav className={classes.drawer}>
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor="left"
          open={isMobileDrawerOpen}
          onClose={onCloseDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
            style: { zIndex: 1201 },
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <div className={classes.offset} />
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
}

export default Sidebar;
// Customizable Area End
