// Customizable Area Start
import React, { CSSProperties } from "react";
import { createStyles, Theme } from "@material-ui/core/styles";
import {
  Container,
  Box,
  Typography,
  IconButton,
  withStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CreateEveryreelInitialController, {
  Props,
} from "./CreateEveryreelInitialController.web";
import { Scooter, Woman } from "./assets";
import { NewEveryreelSteps, PostTypes } from "./enums";

const styles = (theme: Theme) =>
  createStyles({
    modal: {
      position: "relative",
      display: "flex",
      padding: "90px 30px 30px",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      background: "#FFF",
      [theme.breakpoints.down(650)]: {
        padding: "12px",
        "& .MuiDialog-paper":{
          padding: "0px"
        },
        "& .MuiDialog-paperFullWidth":{
          width: 'calc(100% - 24px)'
        }
      },
    },

    closeButton: {
      position: "absolute",
      top: "15px",
      right: "10px",
    },

    contentContainer: {
      display: "flex",
      flexWrap: "nowrap",
      gap: "53px",
    },

    leftContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      [theme.breakpoints.down(800)]: {
        display: "none"
      },
    },

    image: {
      width: "248px",
      borderRadius: "20px",
    },

    rightContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "44px",
      paddingTop: "15px",
    },

    mainTitle: {
      color: "#000",
      fontSize: "32px",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.12px",
      [theme.breakpoints.down(650)]: {
        fontSize: "24px",
        width: "80%"
      },
    },

    button: {
      display: "flex",
      flexDirection: "column",
      padding: "18px 14px 25px",
      textTransform: "none",
      cursor: "pointer",
      borderRadius: "20px",
      gap: "8px",

      "&:hover": {
        backgroundColor: "#FFC629",
      },
    },

    buttonTitle: {
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.12px",
      [theme.breakpoints.down(650)]: {
        fontSize: "20px",
        lineHeight: "24px",
      },
    },

    buttonText: {
      fontSize: "20px",
      fontWeight: 400,
      lineHeight: "30px",
      [theme.breakpoints.down(650)]: {
        fontSize: "16px",
        lineHeight: "20px",
      },
    },
  });

const webStyles: Record<string, CSSProperties> = {};

class CreateEveryreelInitial extends CreateEveryreelInitialController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, setStep, closeModal, setPostType } = this.props;

    return (
      <Container
        id="createReelInitialContainer"
        maxWidth={false}
        className={classes.modal}
        onClick={(e) => e.stopPropagation()}
      >
        <IconButton
          id="closeModalButton"
          className={classes.closeButton}
          onClick={closeModal}
        >
          <CloseIcon />
        </IconButton>
        <Box className={classes.contentContainer}>
          <Box className={classes.leftContainer}>
            <img className={classes.image} src={Scooter} alt="scooter image" />
            <img className={classes.image} style={{height: "300px", objectFit: "cover"}} src={Woman} alt="woman image" />
          </Box>
          <Box className={classes.rightContainer}>
            <Typography className={classes.mainTitle}>
              {this.createNewEveryreel}
            </Typography>
            <Box
              id="createSocialScrollButton"
              className={classes.button}
              onClick={() => {
                setStep(NewEveryreelSteps.SELECT_FILE)
                setPostType(PostTypes.SOCIAL_SCROLL)
              }}
            >
              <Typography className={classes.buttonTitle}>
                {this.socialScroll}
              </Typography>
              <Typography className={classes.buttonText}>
                {this.socialScrollText}
              </Typography>
            </Box>
            <Box
              id="createFunnelButton"
              className={classes.button}
              onClick={() => {
                setStep(NewEveryreelSteps.SELECT_FILE)
                setPostType(PostTypes.INTERACTIVE_FUNNEL)
              }}
            >
              <Typography className={classes.buttonTitle}>
                {this.interactiveFunnel}
              </Typography>
              <Typography className={classes.buttonText}>
                {this.interactiveFunnelText}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    );
  }
}

export { CreateEveryreelInitial };
export default withStyles(styles)(CreateEveryreelInitial);
// Customizable Area End
