// Customizable Area Start
import {
  VideoToFrames,
  VideoToFramesMethod,
} from "../../utilities/src/VideoToFrames";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { NewPostCreationSteps } from "./enums";
import { File } from "./types";

export const configJSON = require("./config");

export type Props = {
  classes: any;
  files: File[];
  cover: string;
  caption: string;
  postCaption: string
  isCommentsAllowed: boolean;
  allowComments: () => void;
  setStep: (step: NewPostCreationSteps) => void;
  closeModal: () => void;
  setCover: (coverURL: string) => void;
  sendPost: () => void;
  handlePostCaptionChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  postBodyMessage:string;
  handleSelectPlatforms: (name: string) => void;
};

interface S {
  frames: string[];
  showSettings: boolean;
  isPostadded: boolean;
  isCaptionFocused: boolean;
}

interface SS {
  navigation: any;
  stepType: NewPostCreationSteps;
}

export default class NewPostCoverController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      frames: [],
      showSettings: false,
      isPostadded: false,
      isCaptionFocused: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const { files } = this.props
    if (files[0]?.type.includes('video')) {
      const isUrlFile = configJSON.URL_POST_NAMES.some((item: string) => files[0].name.includes(item))
      if(isUrlFile) {
        this.setState({ frames: [files[0].filePreview || ""] })
        return
      }
      const frames = await VideoToFrames.getFrames(
        this.props.files[0]?.preview,
        10,
        VideoToFramesMethod.totalFrames
      );

      this.setState({
        frames,
      });
    }
  }

  showSettings = () => {
    this.setState({ showSettings: true });
  };

  closeSettings = () => {
    this.setState({ showSettings: false });
  };
  uploadPost = () => {
    this.setState({ isPostadded: true }) 
  }
}
// Customizable Area End
