// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { BusineesUserInfo, DashboardAPIResponse, WebsitesApiResponse } from "./interfaces";
import { getToken, removeToken } from "../../../components/src/AuthService";
import { RouteComponentProps } from "react-router-dom";
import { AppLayoutModals } from "./enums";

export const configJSON = require("./config.js");

export type Props = RouteComponentProps & {
  children: React.ReactNode;
  classes?: any;
  navigation?: any;
  location?: any
};

export type WebsiteNormalized = {
  id: string;
  name: string;
  url: string;
}
interface S {
  websitesList: WebsiteNormalized[];
  selectedWebsite?: WebsiteNormalized;
  isMobileDrawerOpen: boolean;
  currentUserInfo: BusineesUserInfo;
  openLogoutModal: boolean;
  showModal?: AppLayoutModals;
  showSidebar: boolean,
  mobileNavigationName: string,
  mobileNavigationIcon: any,
  backArrowFunction: any,
  dropdownFunction?: any
}
interface SS {
  id: any;
}

export default class AppLayoutController extends BlockComponent<Props, S, SS> {
  userDetailsApiCallId = "";
  websitesApiCallId = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.ConnectTOSidebar),
      getName(MessageEnum.NavigationMobileData),
      getName(MessageEnum.NavigationMobileBackFunction),
    ];

    this.state = {
      websitesList: [],
      selectedWebsite: undefined,
      isMobileDrawerOpen: false,
      currentUserInfo: {} as BusineesUserInfo,
      openLogoutModal: false,
      showModal: undefined,
      showSidebar:true,
      mobileNavigationName: "",
      mobileNavigationIcon: {},
      backArrowFunction: {},
      dropdownFunction: {}
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getUserDetails();
    this.getWebsites();
    this.handleSidebar();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevState.websitesList.length === 0 && this.state.websitesList.length > 0) {
      this.setState({
        selectedWebsite: this.state.websitesList[0]
      })
    }
  }

  handleWebsiteSelection = (selectedWebsite: WebsiteNormalized) => {
    this.setState({ selectedWebsite });
  };

  handleDrawerToggle = () => {
    this.setState((prevState) => ({
      isMobileDrawerOpen: !prevState.isMobileDrawerOpen,
    }));
  };

  handleSidebar = ()=> {
    if(window.location.pathname === '/messages'){
      this.setState({showSidebar:false})
    }else{
      this.setState({showSidebar:true})
    }
  }

  async receive(_from: string, message: Message) {
    if (getName(MessageEnum.NavigationMobileData) === message.id) {
      const params = message.getData(getName(MessageEnum.NavigationMobileData));
      this.setState({mobileNavigationName: params.name, mobileNavigationIcon: params.icon, backArrowFunction: params.backArrowFunction, dropdownFunction: params.dropdownFunction});
    }

    if (message.id == getName(MessageEnum.ConnectTOSidebar)) {
      let data = message.getData(getName(MessageEnum.ShouldUpdate));
      if (data && data.refresh) {
        this.getUserDetails();
      }
    }
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.userDetailsApiCallId) {
      this.handleUserDetailsAPIResponse(responseJson);
    }

    if (apiRequestCallId === this.websitesApiCallId) {
      this.handleWebsitesApiResponse(responseJson)
    }
  }

  getUserDetails = () => {
    const endpoint = configJSON.dashboardGetUrl;
    const method = configJSON.dashboarApiMethodType;
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      token: getToken(),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getWebsites = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.websitesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.websitesGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.dashboarContentType,
        token: getToken(),
      })
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleUserDetailsAPIResponse = (response: DashboardAPIResponse) => {
    if (response?.account) {
      this.setUserDetails(response);
    } else if (response && response.errors[0]?.token) {
      this.logoutAndRedirectToLogin();
    }
  };

  handleWebsitesApiResponse = (response: WebsitesApiResponse) => {
    this.setState({websitesList: response.data.map(website => ({id: website.id, name: website.attributes.name, url: website.attributes.url}))})
  }

  setUserDetails = (response: DashboardAPIResponse) => {
    const currentUserInfo = response.account?.data || {};

    this.setState({
      currentUserInfo,
      showModal:
        (currentUserInfo?.attributes?.is_first_login &&
          AppLayoutModals.AFTER_SIGNUP_WELCOME) ||
        undefined,
    });
  };

  logoutAndRedirectToLogin = () => {
    removeToken();

    document.dispatchEvent(new CustomEvent("isLoggedIn", { detail: false }));

    this.props.history.push("/Login");
  };

  openLogoutModal = () => {
    this.setState({ openLogoutModal: !this.state.openLogoutModal });
  };

  openModal = (modal: AppLayoutModals) => {
    this.setState({
      showModal: modal,
    });
  };

  closeModal = () => {
    this.setState({
      showModal: undefined,
    });
  };
}
// Customizable Area End
