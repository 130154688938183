// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getToken } from "../../../components/src/AuthService";
import { NewPostCreationSteps } from "./enums";
export const configJSON = require("./config");

interface IPayloadConfig {
  payloadEnum: string;
  payload: {
    postType: NewPostCreationSteps;
  };
}

export interface Props {
  id: string;
  navigation: any;
}

interface S {
  isLoading: boolean;
  alertType: "error" | "success" | undefined;
  alertMessage: string;
}

interface SS {
  navigation: any;
}

export default class TikTokAuthRedirectController extends BlockComponent<
  Props,
  S,
  SS
> {
  tikTokCodeSendApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      isLoading: false,
      alertMessage: "",
      alertType: undefined,
    };
  }

  async componentDidMount() {
    this.handleTikTokAuthRedirection();
  }

  handleErrorAlert = (error: string) => {
    this.setState({ alertMessage: error, alertType: "error" });
    setTimeout(() => {
      window.close();
    }, 3000);
  };

  handleTikTokAuthRedirection =  async() => {
    this.setState({ isLoading: true });
    const params = new URLSearchParams(window.location.search);
    const tikTokAuthCode = params.get(configJSON.INSTAGRAM_AUTH_CODE_PARAM);
    const tikTokAuthError = params.get(
      configJSON.INSTAGRAM_AUTH_ERROR_PARAM
    );
    if (tikTokAuthCode) {
      this.sendTikTokAuthCodeApi(tikTokAuthCode);
    } else if (tikTokAuthError) {
      this.handleErrorAlert(tikTokAuthError);
    }
  };

  sendTikTokAuthCodeApi = (tikTokAuthCode: string) => {
    const headerData = {
      "Content-Type": configJSON.validationApiContentType,
      token: getToken(),
    };

    const endPoint = configJSON.tikTokCodeApiEndPoint;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.tikTokCodeSendApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ type: "tiktok", code: tikTokAuthCode })
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };


  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId === this.tikTokCodeSendApiCallId) {
      this.handleTikTokCodeSendApiResponse(message);
    }
  }


  handleTikTokCodeSendApiResponse = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const channel = new BroadcastChannel('authChannel');
    if (!responseJson.error) {
      this.setState({
        alertMessage: configJSON.tikTokAccountConnectedMessage,
        alertType: "success",
      });
      channel.postMessage({
        type: 'authComplete',
        message: "Connected",
        token: "Connected",
      });
      setTimeout(() => {
        window.close();
      }, 2000);
    } else {
      channel.postMessage({
        type: 'authComplete',
        message: responseJson.error,
        token: "Not Connected",
      });
      setTimeout(() => {
        window.close();
      }, 2000);
      this.handleErrorAlert("Something went wrong");
    }
  };
}

// Customizable Area End
