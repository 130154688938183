// Customizable Area Start
import React, { Component, createRef } from "react";
import { styled } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import PlayArrowRounded from "@material-ui/icons/PlayArrowRounded";

interface MediaComponentProps {
  url: string;
  type?: string;
}

interface MediaComponentState {
  isVideo: boolean;
  isPlaying: boolean;
}

const VideoWrapper = styled("div")({
  position: "relative",
  width: "100%",
  height: "auto",
});

const OverlayButton = styled(IconButton)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  color: "#fff",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
  },
});

const VideoElement = styled("video")({
  width: "100%",
  height: "100%",
  cursor: "pointer",
  objectFit: "cover",
  borderRadius: "8px",
});

const ImageElement = styled("img")({
  width: "100%",
  height: "100%",
  borderRadius: "8px",
});

class MediaComponent extends Component<
  MediaComponentProps,
  MediaComponentState
> {
  private videoRef = createRef<HTMLVideoElement>();

  constructor(props: MediaComponentProps) {
    super(props);
    this.state = {
      isVideo: false,
      isPlaying: false,
    };
  }

  componentDidMount() {
    this.checkMediaType();
    const video = this.videoRef.current;
    if (video) {
      video.addEventListener("pauseVideoExternally", this.handleExternalPause);
    }
  }

  componentDidUpdate(prevProps: Readonly<MediaComponentProps>, prevState: Readonly<MediaComponentState>): void {
    const { url, type } = this.props;
    if (prevProps.url !== url || prevProps.type !== type) {
      this.setState({ isPlaying: false });
      this.checkMediaType();
    }

    if (!prevState.isVideo && this.state.isVideo) {
      const video = this.videoRef.current;
      if (video) {
        video.addEventListener("pauseVideoExternally", this.handleExternalPause);
      }
    }
  }

  componentWillUnmount() {
    this.videoRef.current?.removeEventListener("pauseVideoExternally", this.handleExternalPause);
  }

  handleExternalPause = () => {
    this.setState({ isPlaying: false });
  }

  checkMediaType = () => {
    const { url, type } = this.props;
    const videoExtensions = ['mp4', 'webm', 'ogg'];

    const isVideo = type
      ? !type.includes("image")
      : Boolean(url.split('.').pop()?.toLowerCase() && videoExtensions.includes(url.split('.').pop()!));

    this.setState((prevState) => (prevState.isVideo === isVideo ? null : { isVideo }));
  };

  handleVideoClick = (
    event: React.MouseEvent<HTMLButtonElement | HTMLVideoElement>
  ) => {
    event.stopPropagation();
    const video = this.videoRef.current;

    if (video) {
      if (this.state.isPlaying) {
        video.pause();
      } else {
        video.play();
      }

      this.setState((prevState) => ({
        isPlaying: !prevState.isPlaying,
      }));
    }
  };

  renderVideoSources = (url: string) => {
    const videoFormats = [
      { type: "video/mp4", extension: ".mp4" },
      { type: "video/webm", extension: ".webm" },
      { type: "video/ogg", extension: ".ogg" },
    ];

    return videoFormats.map((format) => {
      const videoUrl = url.replace(/(\.[\w\d]+)$/, format.extension);
      return <source key={format.type} src={videoUrl} type={format.type} />;
    });
  };

  render() {
    const { url } = this.props
    const { isVideo, isPlaying } = this.state;

    return isVideo ? (
      <VideoWrapper>
        {!isPlaying && (
          <OverlayButton
            aria-label={isPlaying ? "Pause video" : "Play video"}
            onClick={this.handleVideoClick}
          >
            <PlayArrowRounded fontSize="large" />
          </OverlayButton>
        )}
        <VideoElement
          loop
          key={url}
          controls={false}
          preload="metadata"
          ref={this.videoRef}
          onClick={this.handleVideoClick}
        >
          {this.renderVideoSources(url)}
          Your browser does not support the video tag.
        </VideoElement>
      </VideoWrapper>
    ) : (
      <ImageElement src={url} alt="Media" />
    );
  }
}

export default MediaComponent;
// Customizable Area End