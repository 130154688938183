import React, { useState } from 'react';
import { Avatar, Box, styled, Typography } from '@material-ui/core';
import { IconAvtar } from "../../contactlist/src/assets";


interface PollData {
	id: number;
	post_id: number;
	question: string;
	created_at: string;
	updated_at: string;
	total_count?: number;
	user_voted_option_id?: number | null
}

interface OptionData {
	id: number;
	post_poll_id: number;
	name: string;
	vote_count: number;
	created_at: string;
	updated_at: string;
}

interface PollProps {
	poll: PollData,
	poll_options: OptionData[],
	handleVote: (optionId: number) => void,
	accountProfile: string | undefined,
	handleDeleteVote: (optionId: number) => void,
}

const styles: { [key: string]: any } = {
	pollWrapper: {
		display: "flex",
	},
	question: {
		marginBottom: '10px',
	},
	optionBox: {
		display: 'flex',
		alignItems: 'center',
		margin: '5px 0 10px',
		position: 'relative',
		height: '40px',
		cursor: 'pointer',
		backgroundColor: '#e8effd',
		borderRadius: '8px',
	},
	filledBox: {
		position: 'absolute',
		backgroundColor: '#FFC629',
		height: '100%',
		borderRadius: '8px 0px 0px 8px',
		transition: 'width 1s ease-in',
	},
	optionText: {
		position: 'relative',
		zIndex: 1,
		paddingLeft: '10px',
		color: '#000',
	},
	percentageText: {
		position: 'relative',
		zIndex: 1,
		marginLeft: 'auto',
		paddingRight: '10px',
		color: '#000',
	},
	voteCount: {
		fontSize: '14px',
		color: '#888',
		marginTop: '10px',
	},
	fallbackAvatar: {
		
	},
	avatar: {
		width: "56px",
		height: "56px",
		backgroundColor: "#FFF",
		border: "2px solid #F0E5FF",
		marginBottom: "8px",
		"@media (max-width: 950px)": {
			width: "30px",
			height: "30px",
			marginRight: "8px",
			"& img": {
			height: "30px",
			width: "30px"
			}
		}
	},
	deleteVote: {
		fontSize: '12px',
		color: '#888',
		cursor: 'pointer',
		marginTop: '10px',
},
voteCountWrapper: {
	display: "flex",
	justifyContent: "space-between"
}
};

const StyledAvatar = styled(Avatar)(({ theme }) => ({
	width: "56px",
	height: "56px",
	backgroundColor: "#FFF",
	border: "2px solid #F0E5FF",
	marginBottom: "8px",
	"& img": {
		width: "56px",
		height: "56px",
		objectFit: "cover",
	},
	[theme.breakpoints.down(950)]: {
	  width: "30px",
	  height: "30px",
	  marginRight: "8px",
	  "& img": {
		height: "30px",
		width: "30px",
	  },
	},
  }));

const PollContainer = styled(Box)(({theme}) => ({
	color: '#000',
	padding: '0 20px 20px',
	borderRadius: '8px',
	minWidth: '350px',
	fontFamily: 'Inter',
	[theme.breakpoints.down(650)]: {
		minWidth: "290px",
		padding: "0 12px 12px"
	},
}))

const Poll = ({ poll, poll_options, accountProfile, handleVote, handleDeleteVote }: PollProps) => {
	const [totalVotes, setTotalVotes] = useState<number>(poll?.total_count || 0);
	const [options, setOptions] = useState<OptionData[]>(poll_options);
	const [userVotedOption, setUserVotedOption] = useState<number | null | undefined>(poll?.user_voted_option_id);

	const calculatePercentage = (count: number): number => {
		return totalVotes > 0 ? parseFloat(((count / totalVotes) * 100).toFixed(0)) : 0;
	};

	const handlePollVote = (optionId: number) => {
		if (userVotedOption !== null) return;
		handleVote(optionId)
		setOptions(prevOptions =>
			prevOptions.map(option =>
					option.id === optionId
							? { ...option, vote_count: option.vote_count + 1 }
							: option
			)
		);
		setTotalVotes(totalVotes + 1);
		setUserVotedOption(optionId);
	};

	const deleteVote = () => {
		if (!userVotedOption) return;
		handleDeleteVote(userVotedOption)
		setOptions(prevOptions =>
				prevOptions.map(option =>
						option.id === userVotedOption
								? { ...option, vote_count: option.vote_count - 1 }
								: option
				)
		);
		setUserVotedOption(null);
		setTotalVotes(totalVotes - 1);
};

	return (
		<Box style={styles.pollWrapper} data-test-id="poll-wrapper">
			<StyledAvatar data-test-id="poll-profile-wrapper">
				<img src={accountProfile || IconAvtar}/>
			</StyledAvatar>
			<PollContainer>
				<Typography variant="subtitle1" component="p" style={styles.question}>
					{poll?.question}
				</Typography>
				{options.map((option, index) => (
					<Box
						key={option.id}
						onClick={() => handlePollVote(option.id)}
						data-test-id={`poll-profile-wrapper-${index+1}`}
						style={styles.optionBox}
					>
						{userVotedOption !== null && 
						<Box
							style={{
								...styles.filledBox,
								width: `${calculatePercentage(option.vote_count)}%`,
								borderStartEndRadius: calculatePercentage(option.vote_count) === 100 ? "8px" : "0px",
								borderEndEndRadius: calculatePercentage(option.vote_count) === 100 ? "8px" : "0px",
							}}
						/>}
						<Typography variant="body2" style={styles.optionText}>
							{option.name}
						</Typography>
						{userVotedOption !== null && <Typography variant="body2" style={styles.percentageText}>
							{calculatePercentage(option.vote_count)}%
						</Typography>}
					</Box>
				))}
				<Box style={styles.voteCountWrapper}>
				<Typography variant="caption" style={styles.voteCount}>
					{totalVotes} votes
				</Typography>
				{userVotedOption !== null && (
					<Typography variant="caption" style={styles.deleteVote} onClick={deleteVote} data-test-id="poll-delete">
							Delete vote
					</Typography>
            )}
					</Box>
			</PollContainer>
		</Box>
	);
};

export default Poll;
